import { Grid, Typography } from '@mui/material';
import { TailSpin, ThreeDots } from 'react-loader-spinner';

const SpinnerMessageBasic = ({ isActive = true, typeSpinner = false, message = "" }) => {
    return (
        <Grid
            container
            spacing={0}
            direction={"column"}
            alignItems={"center"}
            justifyContent={"center"}
        >
            <Grid item sx={{ marginTop: '5px', marginBottom: '5px', background: "white", borderRadius: "9px", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {
                    isActive ?
                        (
                            <>
                                {
                                    typeSpinner ?
                                        (
                                            <ThreeDots
                                                height="80"
                                                width="80"
                                                radius="9"
                                                color="#008AE8"
                                                ariaLabel="loading"
                                            />
                                        )
                                    :
                                        (
                                            <TailSpin
                                                height="80"
                                                width="80"
                                                radius="9"
                                                color="#008AE8"
                                                ariaLabel="loading"
                                            />
                                        )
                                }
                            </>
                        ) 
                    :
                        (
                            <Typography
                                variant="h6" 
                                sx={{ fontSize: 22, fontWeight: 'bold' }} 
                                color="red"
                            >
                                {message}
                            </Typography>
                        )
                }
            </Grid>
        </Grid>
    );
}

export default SpinnerMessageBasic