// React
import React from 'react';

// material-ui
import { Button, Card, CardContent, CardHeader, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Modal, Switch, TextField, InputLabel, Select, Typography, Divider } from "@mui/material";

// otros
import { useTranslation } from "react-i18next";

const formatCurrency = (amount) => `$ ${amount.toLocaleString("en-US")}`;

// styles
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
};

const ModalEdit = ({ open, titulo, btnName, mainEvent, closeEvent, data }) => {

    const { t } = useTranslation();

    const [name, setName] = React.useState(data.name);
    const [type, setType] = React.useState(data.type);
    const [cuponProgramId, setCuponProgramId] = React.useState(data.cuponProgramId);
    const [listCustomerId, setListCustomerId] = React.useState(data.listCustomerId);
    const [valueCoupon, setValueCoupon] = React.useState(data.valueCoupon);
    const [email, setEmail] = React.useState(data.email);
    const [pasesAdd, setPasesAdd] = React.useState(data.pasesAdd);
    const [pasesUpdate, setPasesUpdate] = React.useState(data.pasesUpdate);
    const [cGenerado, setCGenerado] = React.useState(data.cGenerado);
    const [cRedimidos, setCRedimidos] = React.useState(data.cRedimidos);
    const [estatus, setEstatus] = React.useState(data.estatus);
    const [dateOf, setDateOf] = React.useState(data.dateOf);
    const [dateAt, setDateAt] = React.useState(data.dateAt);
    const [dateSend, setdateSend] = React.useState(data.dateSend);
    const [typeCoupon, setTypeCoupon] = React.useState(data.setTypeCoupon);
    const [listCustomerName, setListCustomerName] = React.useState(data.listCustomerName);
    const [designName, setDesignName] = React.useState(data.designName);
    const [passHeader, setPassHeader] = React.useState(data.passHeader);
    const [additional, setAdditional] = React.useState(data.additional);
    const [general, setGeneral] = React.useState(data.general);
    const [dayReminder1, setDayReminder1] = React.useState(data.dayReminder1);
    const [dayReminder2, setDayReminder2] = React.useState(data.dayReminder2);
    const [dateSendReminder1, setDateSendReminder1] = React.useState(data.dateSendReminder1);
    const [dateSendReminder2, setDateSendReminder2] = React.useState(data.dateSendReminder2);
    const [titleReminder1, setTitleReminde1] = React.useState(data.titleReminder1);
    const [titleReminder2, setTitleReminde2] = React.useState(data.titleReminder2);
    const [contentReminder1, setContentReminder1] = React.useState(data.contentReminder1);
    const [contentReminder2, setContentReminder2] = React.useState(data.contentReminder2);

    const handleClose = (e) => {
        closeEvent(open);
        handleKeepIni();
    }

    const handleAdd = (e) => {
        mainEvent({ id: data.id, name, isEnable: estatus });

        if (!data.id) {
            handleKeepIni();
        }
    }

    const handleKeepIni = (e) => {
        setName(data.name);
        setType(data.type);
        setCuponProgramId(data.cuponProgramId);
        setListCustomerId(data.listCustomerId);
        setEmail(data.email);
        setPasesAdd(data.pasesAdd);
        setPasesUpdate(data.pasesUpdate);
        setCGenerado(data.cGenerado);
        setCRedimidos(data.cRedimidos);
        setEstatus(data.estatus);
        setValueCoupon(data.valueCoupon);
        setDateOf(data.dateOf);
        setDateAt(data.dateAt);
        setdateSend(data.dateSend);
        setTypeCoupon(data.typeCoupon);
        setListCustomerName(data.listCustomerName);
        setDesignName(data.designName);
        setPassHeader(data.passHeader);
        setGeneral(data.general);
        setAdditional(data.additional);
        setDayReminder1(data.dayReminder1);
        setDayReminder2(data.dayReminder2);
        setDateSendReminder1(data.dateSendReminder1);
        setDateSendReminder2(data.dateSendReminder2);
        setTitleReminde1(data.titleReminder1);
        setTitleReminde2(data.titleReminder2);
        setContentReminder1(data.contentReminder1);
        setContentReminder2(data.contentReminder2);
    }

    return (
        <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ zIndex: 1201 }}
        >
            <Card sx={style}>
                <CardHeader
                    title={titulo}
                    sx={{ backgroundColor: "#008AE8", color: "white", textAlign: "center" }}
                />
                <CardContent sx={{
                    maxHeight: 'calc(100vh - 200px)',
                    mWidth: 'calc(100vh - 200px)',
                    overflowY: "auto",
                    "&::-webkit-scrollbar": {
                        width: "6px",
                        height: "6px",
                    },
                    "&::-webkit-scrollbar-track": {
                        background: "#f1f1f1",
                    },
                    "&::-webkit-scrollbar-thumb": {
                        background: "#888",
                        borderRadius: "10px",
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                        background: "#555",
                    },
                }}>
                    {/* Tipo de difusion */}
                    <Grid container justifyContent="center" sx={{ marginTop: '20px' }} >
                        <Grid item xs={5} sm={5} md={5} lg={5}>
                            <Divider sx={{ my: 2 }} />
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={2}>
                            <Typography
                                variant="h5"
                                sx={{ fontWeight: 'bold', textAlign: 'center' }}
                            >
                                {t("selecttype_1")}
                            </Typography>
                        </Grid>
                        <Grid item xs={5} sm={5} md={5} lg={5}>
                            <Divider sx={{ my: 2 }} />
                        </Grid>
                    </Grid>
                    {/* Detalle de difusion  */}
                    <Grid container spacing={2} sx={{ marginTop: '5px' }}>
                        {/* Primer fila */}
                        <Grid item xs={12} sm={3} md={3} lg={3}>
                            <TextField
                                label={t("partcupon_textfield_nombre_campana")}
                                size="small"
                                fullWidth
                                value={name}
                                onChange={e => { setName(e.target.value); }}
                                inputProps={{ maxLength: 25 }}
                                required
                            />
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                            <TextField
                                label={t("partcupon_select_tipo_design")}
                                size="small"
                                fullWidth
                                value={designName}
                                required
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={3} lg={3}>
                            <TextField
                                label={t("partcupon_select_enviar_a")}
                                size="small"
                                fullWidth
                                value={listCustomerName}
                                required
                                disabled={true}
                            />
                        </Grid>

                        <Grid item xs={12} sm={3} md={3} lg={3}>
                            <TextField
                                label={t("partcupon_select_programa_cupon")}
                                size="small"
                                fullWidth
                                value={cuponProgramId}
                                required
                                disabled={true}
                            />
                        </Grid>
                        {/* Segunda fila  */}
                        <Grid item xs={12} sm={3} md={3} lg={3}>
                            <TextField
                                label={t("partcupon_select_tipo_cupon")}
                                size="small"
                                fullWidth
                                value={
                                    typeCoupon == 1 ?
                                        ("$")
                                        :
                                        ("%")
                                }
                                required
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                            <TextField
                                label={t("partcupon_textfield_valor")}
                                size="small"
                                fullWidth
                                value={valueCoupon}
                                required
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={3} lg={3}>
                            <TextField
                                type="datetime-local"
                                label={t("partcupon_textfield_vigencia_del")}
                                size="small"
                                fullWidth
                                value={dateOf}
                                required
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={3} lg={3}>
                            <TextField
                                type="datetime-local"
                                label={t("partcupon_textfield_vigencia_al")}
                                size="small"
                                fullWidth
                                value={dateAt}
                                required
                                disabled={true}
                            />
                        </Grid>
                        {/* Tercera fila  */}
                        <Grid item xs={12} sm={3} md={3} lg={3}>
                            <TextField
                                type="datetime-local"
                                label={t("partcupon_textfield_fecha_envio")}
                                size="small"
                                fullWidth
                                value={dateSend}
                                required
                                disabled={true}
                            />
                        </Grid>
                        {/* Información del pase  */}
                        <Grid container justifyContent="center" sx={{ marginTop: '20px' }} >
                            <Grid item xs={5} sm={5} md={5} lg={5}>
                                <Divider sx={{ my: 2 }} />
                            </Grid>
                            <Grid item xs={2} sm={2} md={2} lg={2}>
                                <Typography
                                    variant="h5"
                                    sx={{ fontWeight: 'bold', textAlign: 'center' }}
                                >
                                    {t("infocoupon_label_main")}
                                </Typography>
                            </Grid>
                            <Grid item xs={5} sm={5} md={5} lg={5}>
                                <Divider sx={{ my: 2 }} />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3} lg={3}>
                            <TextField
                                label={t("partcupon_textfield_encabezado_de_pase")}
                                size="small"
                                fullWidth
                                value={passHeader}
                                required
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={3} lg={3}>
                            <TextField
                                label={t("partcupon_textfield_contenido_general")}
                                size="small"
                                fullWidth
                                value={general}
                                required
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={3} lg={3}>
                            <TextField
                                label={t("partcupon_textfield_texto_adicional")}
                                size="small"
                                fullWidth
                                value={additional}
                                required
                                disabled={true}
                            />
                        </Grid>
                        {/* Recordatorio 1 */}
                        <Grid container justifyContent="center" sx={{ marginTop: '20px' }} >
                            <Grid item xs={5} sm={5} md={5} lg={5}>
                                <Divider sx={{ my: 2 }} />
                            </Grid>
                            <Grid item xs={2} sm={2} md={2} lg={2}>
                                <Typography
                                    variant="h5"
                                    sx={{ fontWeight: 'bold', textAlign: 'center' }}
                                >
                                    {t("partcupon_label_recordatorio_1")}
                                </Typography>
                            </Grid>
                            <Grid item xs={5} sm={5} md={5} lg={5}>
                                <Divider sx={{ my: 2 }} />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3} lg={3}>
                            <TextField
                                label={t("partcupon_textfield_x_dias_1")}
                                type="number"
                                size="small"
                                fullWidth
                                value={dayReminder1}
                                required
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={3} lg={3}>
                            <TextField
                                label={t("partcupon_textfield_hora_envio_1")}
                                type="time"
                                size="small"
                                fullWidth
                                value={dateSendReminder1}
                                required
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={3} lg={3}>
                            <TextField
                                label={t("partcupon_textfield_titulo_1")}
                                size="small"
                                fullWidth
                                value={titleReminder1}
                                disabled={true} required
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={3} lg={3}>
                            <TextField
                                label={t("partcupon_textfield_contenido_1")}
                                size="small"
                                fullWidth
                                value={contentReminder1}
                                disabled={true} required
                            />
                        </Grid>
                        {/* Recordatorio 2 */}
                        <Grid container justifyContent="center" sx={{ marginTop: '20px' }} >
                            <Grid item xs={5} sm={5} md={5} lg={5}>
                                <Divider sx={{ my: 2 }} />
                            </Grid>
                            <Grid item xs={2} sm={2} md={2} lg={2}>
                                <Typography
                                    variant="h5"
                                    sx={{ fontWeight: 'bold', textAlign: 'center' }}
                                >
                                    {t("partcupon_label_recordatorio_2")}
                                </Typography>
                            </Grid>
                            <Grid item xs={5} sm={5} md={5} lg={5}>
                                <Divider sx={{ my: 2 }} />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3} lg={3}>
                            <TextField
                                label={t("partcupon_textfield_x_dias_2")}
                                type="number"
                                size="small"
                                fullWidth
                                value={dayReminder2}
                                disabled={true}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={3} lg={3}>
                            <TextField
                                label={t("partcupon_textfield_hora_envio_2")}
                                type="time"
                                size="small"
                                fullWidth
                                value={dateSendReminder2}
                                required
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={3} lg={3}>
                            <TextField
                                label={t("partcupon_textfield_titulo_2")}
                                size="small"
                                fullWidth
                                value={titleReminder2}
                                disabled={true} required
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={3} lg={3}>
                            <TextField
                                label={t("partcupon_textfield_contenido_2")}
                                size="small"
                                fullWidth
                                value={contentReminder2}
                                disabled={true} required
                            />
                        </Grid>
                        {/* Cambio de estatus  */}
                        <Grid item xs={12} sm={6} md={6} lg={6} >
                            <FormControl component="fieldset">
                                <FormLabel component="legend">{t("modalmarca_textfield_estado")}</FormLabel>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Switch checked={estatus} onChange={e => { setEstatus(e.target.checked); }} />}
                                        label={estatus ? t("chipestado_true") : t("chipestado_false")}
                                    />
                                </FormGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                    {/* Acciones en botones  */}
                    <Grid container spacing={2} sx={{ marginTop: '5px' }} justifyContent="flex-end">
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <Button
                                variant="contained"
                                sx={{ backgroundColor: "#52c41a", '&:hover': { bgcolor: '#237804' } }}
                                onClick={handleAdd}
                            >
                                {btnName}
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <Button
                                variant="contained"
                                sx={{ backgroundColor: "#f5222d", '&:hover': { bgcolor: '#a8071a' } }}
                                onClick={handleClose}
                            >
                                {t("modaldifusion_cancel_btn")}
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Modal>
    );
}

export default ModalEdit;