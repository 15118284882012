// react
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

// material-ui
import { Button, Card, CardContent, CardHeader, Grid, TextField, Typography } from '@mui/material';

// assets
import logo from '../../assets/logo_tracpeak.png';

// otros
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useTranslation } from "react-i18next";
import jwt_decode from 'jwt-decode';

// my proyect
import TextPassword from '../../components/input/TextPassword';

// my service
import { getUpdatedUser } from '../../services/user/UsuarioService';

const Registration = () => {

    // styles
    const styleCard = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '400px',
        background: 'rgba(49,111,181,.4)',
        boxSizing: 'border-box',
        boxShadow: '0 15px 25px rgba(49,111,181,.6)',
        borderRadius: '10px',
    };

    const backGroundMain = {
        position: 'absolute',
        opacity: '0.3',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '500px',
        height: 'auto',
        pointerEvents: 'none',
    };
    // fin styles

    const { t } = useTranslation();

    const history = useHistory();

    const MySwal = withReactContent(Swal);
    
    const [email, setEmail] = React.useState("");

    const [firstName, setFirstName] = React.useState("");
    
    const [lastName, setLastName] = React.useState("");

    const [ password, setPassword ] = React.useState("");

    const [isPasswordHidden, setIsPasswordHidden] = React.useState(true);
    
    const [ rePassword, setRePassword ] = React.useState("");

    const [isRePasswordHidden, setIsRePasswordHidden] = React.useState(true);

    const { userId } = useParams();

    const handleNewPass = async (e) => {
        e.preventDefault();

        const token = jwt_decode(userId);

        const user = token["user"];

        user['firstName'] = firstName;
        user['lastName'] = lastName;
        user['password'] = password;

        // hay que agregar una imagen login o algo asi q se ve mal
        const response = await getUpdatedUser(userId,user);

        if(response.code === 100) {
            MySwal.fire({
                title:'<span style="font-weight: normal;">'+t("recover_sw_title_success")+'</span>',
                html: '<span style="font-style: normal;">'+t("recover_sw_html_success")+'</span>',
                icon: 'success',
                showConfirmButton: false,
                customClass: {
                    content: {
                      fontFamily: 'Lato, sans-serif' // Establecer la fuente Lato en el objeto de estilo
                    }
                },
                timer: 2000,
                didClose: () => { history.push('/'); }
            });
        } else {
            MySwal.fire({
                title:'<span style="font-weight: normal;">'+t("recover_sw_title_error")+'</span>',
                html: '<span style="font-style: normal;">'+t("recover_sw_html_error")+'</span>',
                icon: 'error',
                showConfirmButton: false,
                customClass: {
                    content: {
                      fontFamily: 'Lato, sans-serif' // Establecer la fuente Lato en el objeto de estilo
                    }
                },
                timer: 2000,
                didClose: () => { history.push('/'); }
            });
        }
    }

    // revisamos que el token tenga menos de 24h
    React.useEffect(() => {
        const token = jwt_decode(userId);

        const currentTime = new Date();
        const tokenTime = new Date(token['timer']);
    
        const timeDiffInMs = currentTime - tokenTime;
        const timeDiffInHours = Math.floor(timeDiffInMs / (1000 * 60 * 60)); // Convertir a horas
    
        if (timeDiffInHours >= 24) {
            history.push('/');
        }

        const user = token["user"];

        setEmail(user["email"]);
    }, [setEmail, history, userId]);

    return(
        <form onSubmit={ handleNewPass } >
            <img src={logo} style={backGroundMain} alt="logo" />
            <Card sx={ styleCard } >
                <CardHeader
                    title={
                        <Typography variant="h5" component="div" fontWeight="bold">
                            { t("registration_main_title") }
                        </Typography>
                    }
                />
                <CardContent>
                    <Grid container rowSpacing={2.75} columnSpacing={2.75} align={"center"} >
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                type="email"
                                size="small"
                                value={email}
                                onChange={ e => {setEmail(e.target.value);} }
                                label={t("modaladmin_textfield_email")}
                                fullWidth
                                required
                                disabled
                                onInvalid={e => e.target.setCustomValidity(t("modaladmin_textfield_email_required"))}
                                onBlur={e => e.target.setCustomValidity("")}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                size="small"
                                value={firstName}
                                onChange={ e => {setFirstName(e.target.value);} }
                                label={t("modaladmin_textfield_firstname")}
                                fullWidth
                                required
                                onInvalid={e => e.target.setCustomValidity(t("modaladmin_textfield_fistname_required"))}
                                onBlur={e => {
                                    if (/^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ\s]+$/.test(e.target.value)) {
                                        e.target.setCustomValidity("");
                                    } else {
                                        e.target.setCustomValidity(t("modaladmin_textfield_nonumbers_required"));
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                size="small"
                                value={lastName}
                                onChange={ e => {setLastName(e.target.value);} }
                                label={t("modaladmin_textfield_lastname")}
                                fullWidth
                                required
                                onInvalid={e => e.target.setCustomValidity(t("modaladmin_textfield_lastname_required"))}
                                onBlur={e => {
                                    if (/^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ\s]+$/.test(e.target.value)) {
                                        e.target.setCustomValidity("");
                                    } else {
                                        e.target.setCustomValidity(t("modaladmin_textfield_nonumbers_required"));
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} >
                            <TextPassword 
                                isHidden={ isPasswordHidden }
                                setIsHidden={ setIsPasswordHidden }
                                txtPassword={ password }
                                setTxtPassword={ setPassword }
                                labelName={ t("recover_textfield_password") }
                                labelError={ t("recover_textfield_password_required") }
                                onBlur={e => {
                                    if (/^(?=.*[A-Z])(?=.*\d)(?=.*[-!@#$%^&*()_+|~=`{}[\]:";'<>?,./])(?!.*\s).{8,}$/.test( e.target.value )) {
                                      e.target.setCustomValidity("");
                                    } else {
                                      e.target.setCustomValidity( t("recover_textfield_password_required") );
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} >
                            <TextPassword 
                                isHidden={ isRePasswordHidden }
                                setIsHidden={ setIsRePasswordHidden }
                                txtPassword={ rePassword }
                                setTxtPassword={ setRePassword }
                                labelName={ t("recover_textfield_repassword") }
                                labelError={ t("recover_textfield_repassword_required") }
                                onBlur={e => {
                                    if ( e.target.value === password) {
                                        e.target.setCustomValidity("");
                                    } else {
                                        e.target.setCustomValidity( t("recover_textfield_repassword_required") );
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} >
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{ backgroundColor: "#316FB5", '&:hover': { bgcolor: '#4C88C9' } }}
                                fullWidth
                            >
                                { t("recover_btn_recover") }
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </form>
    );
}

export default Registration;