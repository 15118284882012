// react
import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

// my proyect
import Login from '../pages/login/Login';
import Forgot from '../pages/forgot/Forgot';
import Recover from '../pages/forgot/Recover';
import MainLayout from '../components/layout/MainLayout';
import Registration from '../pages/registration/registration';

const Routes = ({ sesionFuncion, sesion}) => {
    
    return(
        <Router>
          <Switch>
            <Route exact path="/" render={() => <Login sesionFuncion={sesionFuncion} type={false} />} />
            <Route exact path="/admin" render={() => <Login sesionFuncion={sesionFuncion} type={true} />} />
            <Route exact path="/forgot-password" render={() => <Forgot />} />
            <Route exact path="/recover/:userId" render={() => <Recover />} />
            <Route exact path="/sign-in/:userId" render={() => <Registration />} />
            <Route exact path="/dashboard"  render={() => <MainLayout sesion={sesion} />} />
            <Redirect to="/" />
          </Switch>
        </Router>
     );

}

export default Routes;