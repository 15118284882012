export const formattedMoney = (number) => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(number);
}

export const formattedNumber = (number) => {
    return `# ${formatoMexico(number)}`;
}

export const priceNumber = (number) => {
    return `$ ${formatoMexico(number)}`;
}

export const formattedDigital = (number) => {
    return ` ${formatoMexico(number)}`;
}

const formatoMexico = (number) => {
    const exp = /(\d)(?=(\d{3})+(?!\d))/g;
    const rep = '$1,';
    return number.toString().replace(exp, rep);
}

export const formattedFecha = (date) => {
    var info = date.split('-');
    //   return info[2] + '/' + info[1] + '/' + info[0];
    return info[2] + '/' + info[1];
}