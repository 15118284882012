// React
import { useHistory } from 'react-router-dom'
import React, { useEffect } from 'react'
import { format, startOfMonth, endOfMonth } from 'date-fns';

// material-ui
import { Box } from '@mui/material';

// my proyect
// components
import Header from '../header/Header';
import HeaderUser from '../header-user/HeaderUser';
import Menu from '../menu/Menu';
import Footer from '../footer/Footer';
// pages
import Dashboard from '../../pages/dashboard/Dashboard';
import Prevencion from '../../pages/prevencion/Prevencion';
import Campana from '../../pages/campanas/Campanas';
import Marcas from '../../pages/marcas/Marcas';
import EmailBrand from '../../pages/email-brand/EmailBrand';
import Usuario from '../../pages/usuarios/Usuario';
import Admin from '../../pages/administrador/Admin';
import Cliente from '../../pages/customer/Customer';

import './MainLayout.css';

const MainLayout = ({ sesion }) => {
    // dates control
    const now = new Date();
    const setIni = format(startOfMonth(now), 'yyyy-MM-dd');
    const setEnd = format(endOfMonth(now), 'yyyy-MM-dd');
    const [iniDate, setIniDate] = React.useState(setIni);
    const [endDate, setEndDate] = React.useState(setEnd);

    // VerificaSesion - no hay seguridad debe mejorar
    const history = useHistory();

    const drawerWidth = 240;

    const verificaSesion = () => {
        // - Si hay sesion permite acceso sio regresa a raiz
        if (!sesion) { history.push('/') }
    };

    // se inicia al entrar a la funcion hija - como un constructor de una clase aqui se llama hooks
    useEffect(() => {
        verificaSesion();
    });
    // fin VerificaSesion -----

    // para appBar boton
    const [open, setOpen] = React.useState({
        open: false
    });
    const handleDrawerToggle = () => {
        setOpen(!open);
    };

    // para menu la navegacion
    const [menu, setMenu] = React.useState({
        menu: 1
    });
    const handleMenu = (menuValue) => {
        setMenu(menuValue);
    };

    // para obtener las fechas
    const getDatesSearch = (ini, end) => {
        setIniDate(ini);
        setEndDate(end);
    }

    return (
        <>
            {
                sesion ?
                    <Box sx={{ display: 'flex', width: '100%', height: "100vh", }}>
                        {
                            JSON.parse(localStorage.getItem('roles')).some(role => role.name === 'USER') ?
                                <HeaderUser
                                    open={open}
                                    handleDrawerToggle={handleDrawerToggle}
                                    giveDates={getDatesSearch}
                                />
                                :
                                <Header drawerWidth={drawerWidth} open={open} handleDrawerToggle={handleDrawerToggle} />
                        }
                        <Menu drawerWidth={drawerWidth} open={open} handleMenu={handleMenu} />
                        <Box component="main" sx={{ width: '1%', flexGrow: 1, p: { xs: 2, sm: 3 }, marginTop: '40px' }}>
                            {
                                JSON.parse(localStorage.getItem('roles')).some(role => role.name === 'ROOT') ?
                                    (
                                        menu === 2 ? <Admin /> : <Admin />
                                    )
                                    : JSON.parse(localStorage.getItem('roles')).some(role => role.name === 'ADMIN') ?
                                        (
                                            menu === 8 ? <EmailBrand /> :
                                                menu === 6 ? <Usuario /> : <Marcas />
                                        )
                                        :
                                        (
                                            menu === 3 ? <Prevencion
                                                iniDate={iniDate}
                                                endDate={endDate}
                                            />
                                                :
                                                menu === 4 ? <Campana /> :
                                                    menu === 7 ? <Cliente /> : <Dashboard />
                                        )
                            }
                        </Box>
                        <Footer drawerWidth={drawerWidth} open={open} />
                    </Box>
                    :
                    <Box sx={{ display: 'flex', width: '100%', background: "#f5f5f5" }}></Box>
            }
        </>
    );
}

export default MainLayout;