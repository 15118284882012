import React from 'react';
import { modalW520 } from '../../../../style/StyleGlobal';
import { Box, Button, Card, CardContent, CardHeader, Grid, IconButton, Modal, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ModalCreate = ({ t, open, setOpen, addEvent }) => {
    const [state,setState] = React.useState({
        email: ""
    });

    const { email } = state

    const handleKeyDown = (event) => {
        if (event.key === "Escape") {
            setOpen(false);
        }
    };

    return(
        <Modal open={open} onKeyDown={handleKeyDown} tabIndex="-1">
            <form onSubmit={ e => { addEvent(e,state) }}>
                <Card sx={modalW520}>
                    <CardHeader
                        title = {
                            <Box display="flex"
                                style={{
                                    background: "#008AE8",
                                    color: "white",
                                    position: "sticky",
                                    top: 0,
                                    zIndex: 0
                                }}
                            >
                                <Box flexGrow={1} />
                                <Typography variant="h5" style={{ fontWeight: "bold" }}>
                                    {t("email_add_btn")}
                                </Typography>
                                <Box flexGrow={1} />
                                <IconButton onClick={e => { setOpen(false) }} style={{ color: "white" }}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        }
                        sx={{ backgroundColor: "#008AE8", color: "white", textAlign: "center" }}
                    />
                    <CardContent>
                        <Grid container spacing={2} sx={{ marginTop: '5px' }}>
                            <Grid item xs={12} sm={4} md={4} lg={4}>
                                <TextField
                                    label={t("fp_textfield_email")}
                                    type="email"
                                    size="small"
                                    fullWidth
                                    value={email}
                                    onChange={(e) => setState({ ...state, email: e.target.value })}
                                    required
                                />
                            </Grid>
                        </Grid>                        
                        <Grid container spacing={2} sx={{ marginTop: '5px' }} justifyContent="flex-end">
                            <Grid item xs={12} sm={2} md={2} lg={2}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        size="small"
                                        sx={{ backgroundColor: "#52c41a", '&:hover': { bgcolor: '#237804' } }}
                                    >
                                        {t("marca_modal_main_btn")}
                                    </Button>
                            </Grid>
                            <Grid item xs={12} sm={2} md={2} lg={2}>
                                    <Button 
                                        variant="contained"
                                        size="small"
                                        sx={{ backgroundColor: "#f5222d", '&:hover': { bgcolor: '#a8071a' } }}
                                        onClick={ e => { setOpen(false) } }
                                    >
                                        {t("modalmarca_cancel_btn")}
                                    </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </form>
        </Modal>
    );

};
export default ModalCreate;