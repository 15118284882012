// React
import React from 'react';

// material-ui
import { Typography, Grid } from '@mui/material';

// otros
import { useTranslation } from "react-i18next";


// my proyect
import Efectivo2 from './efectivo/Efectivo2';
import { gridMain } from '../../style/StyleGlobal';
// import Efectivo from './efectivo/Efectivo';
// import PagosEspeciales from './pagosespeciales/PagosEspeciales';
// import Efectivo2 from './efectivo/Efectivo2';

// const TabPanel = ({ value, index, children }) => (
//   <div hidden={value !== index}>
//     {value === index && <Grid sx={{ p: 3 }}>{children}</Grid>}
//   </div>
// );
import SpinnerMessageBasic from "../../components/spinner/SpinnerMessageBasic";

// utils
import {columnsELMR, totalELMR, columnsECMR, totalECMR} from "./TableUtils";
import {createRowLMR} from "./efectivo/DataEfectivoLMR";
import {createRowCMR} from "./efectivo/DataEfectivoCMR";

// data
import { detalleEfectivo } from "./DataPrevencion";
import TablaBasica from '../../components/table/TablaBasica';
import LineWidget from '../../components/widgets/LineWidget';

const Prevencion = ({iniDate, endDate}) => {

    const { t } = useTranslation();
    const [efectivoData,setEfectivoData] = React.useState(null);

    const getData = (response) => {
        setEfectivoData(response.object);
    }

    React.useEffect(() => {
        detalleEfectivo(getData,iniDate,endDate);
    }, [iniDate,endDate]);

    if(efectivoData === null) {
        return <SpinnerMessageBasic isActive={true} message={t('marca_table_typography')} />;
    }

    // const [pestana, setPestana] = React.useState(0);

    // const handlePestana = (event, newPestana) => {
    //     setPestana(newPestana);
    // };

    return(
        <>
            <Typography 
                variant="h5"
                sx={{ fontWeight: 'bold', marginTop: '20px' }}
            >
                {t("prevencion_main_title")}
            </Typography>
            
            <Grid container rowSpacing={2.5} columnSpacing={2.5} sx={gridMain}>
                <Efectivo2 altura={300}/>
                <Grid item xs={12} sm={12} md={10} lg={10} sx={{ height: 300 }} >
                    <LineWidget />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} >
                    <TablaBasica
                        heigth={500}
                        alignRow={"center"}
                        columns={columnsELMR(t)}
                        rows={createRowLMR(efectivoData.locacion)}
                        footTotal={totalELMR(t,efectivoData.locacion)}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} >
                    <TablaBasica 
                        heigth={500}
                        alignRow={"center"}
                        columns={columnsECMR(t)}
                        rows={createRowCMR(efectivoData.cajeros)}
                        footTotal={totalECMR(t,efectivoData.cajeros)}
                    />
                </Grid>
            </Grid>
        </>
    );

}

export default Prevencion;