// my service
import { getNewDiffusion, getUpdatedDiffusion, getAllDiffusion, getDataCoupons, getNewSimpleDiffusion } from '../../../services/campanas/difusion/DifusionServiceOld';

// add - refact
export const addDiffusion = async ( formData, mainEvent, MySwal, t ) => {
    try {
        const response = await getNewDiffusion(formData);
        mainEvent(response);
        MySwal.fire({
            title: '<span style="font-weight: normal; font-family: Lato, sans-serif;">'+t("modaldifusion_add_success_title")+'</span>',
            html: '<span style="font-style: normal; font-family: Lato, sans-serif;">'+t("modaldifusion_add_success_message")+'</span>',
            icon: 'success',
            confirmButtonColor: '#008ae8'
        });
    } catch(error) {
        console.error(error);
        MySwal.fire({
            title: '<span style="font-weight: normal; font-family: Lato, sans-serif;">'+t("modaldifusion_add_error_title")+'</span>',
            html: '<span style="font-style: normal; font-family: Lato, sans-serif;">'+t("modaldifusion_add_error_message")+'</span>',
            icon: 'error',
            confirmButtonColor: '#008ae8'
        });
    }
}

// add
export const addSimpleDiffusion = async ( difusion, addDifusion, MySwal, t ) => {
    try {
        const response = await getNewSimpleDiffusion(difusion);
        addDifusion(response);
        MySwal.fire({
            title: '<span style="font-weight: normal; font-family: Lato, sans-serif;">'+t("modaldifusion_add_success_title")+'</span>',
            html: '<span style="font-style: normal; font-family: Lato, sans-serif;">'+t("modaldifusion_add_success_message")+'</span>',
            icon: 'success',
            confirmButtonColor: '#008ae8'
        });
    } catch(error) {
        console.error(error);
        MySwal.fire({
            title: '<span style="font-weight: normal; font-family: Lato, sans-serif;">'+t("modaldifusion_add_error_title")+'</span>',
            html: '<span style="font-style: normal; font-family: Lato, sans-serif;">'+t("modaldifusion_add_error_message")+'</span>',
            icon: 'error',
            confirmButtonColor: '#008ae8'
        });
    }
}

// update
export const updateDiffusion = async ( updateDiffusion, setFilas, MySwal, t ) => {
    try {
        const response = await getUpdatedDiffusion(updateDiffusion);
        MySwal.fire({
            title: '<span style="font-weight: normal; font-family: Lato, sans-serif;">'+t("modaldifusion_update_success_title")+'</span>',
            html: '<span style="font-style: normal; font-family: Lato, sans-serif;">'+t("modaldifusion_update_success_message")+'</span>',
            icon: 'success',
            confirmButtonColor: '#008ae8'
        });
        setFilas(response);
    } catch(error) {
        MySwal.fire({
            title: '<span style="font-weight: normal; font-family: Lato, sans-serif;">'+t("modaldifusion_update_error_title")+'</span>',
            html: '<span style="font-style: normal; font-family: Lato, sans-serif;">'+t("modaldifusion_update_error_message")+'</span>',
            icon: 'error',
            confirmButtonColor: '#008ae8'
        });
    }
}

// getAll
export const allDiffusion = async ( setFilas, page, size ) => {
    try {
        const response = await getAllDiffusion(page,size);
        setFilas(response);
    } catch (error) {
        console.error(error);
    }
}

// getDataCoupons
export const getAllCoupons = async (setList, setSpinner) => {
    try {
        const response = await getDataCoupons();
        setList(response);
        setSpinner(false);
    } catch (error) {
        setSpinner(false);
    }
}
