// react
import React from 'react';
import { useHistory } from 'react-router-dom'; // cuidado es deprecated con la v5 funciona hay que actualizar a la v6

// material-ui
import { Grid, IconButton, Typography, styled } from '@mui/material';

// material icon
import LogoutIcon from '@mui/icons-material/Logout';

// my proyect
import LanguageSelect from '../../utils/language/LanguageSelect';

// otros
import { useTranslation } from "react-i18next";

const Footer = ({ drawerWidth, open }) => {

    const { t } = useTranslation();

    const name = localStorage.getItem('name') || "";

    const history = useHistory(); // cuidado es deprecated  

    const handleLogout = () => {
        let beforeClean = localStorage.getItem("i18nextLng");
        localStorage.clear();
        localStorage.setItem("i18nextLng", beforeClean);
        history.push('/login');
    }

    const GridFooter = styled(Grid, {
        shouldForwardProp: (prop) => prop !== 'open',
    })(({ theme, open }) => ({
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    }));

    return (
        <GridFooter
            container
            position="fixed"
            style={{ background: 'white', bottom: 0 }}
            open={open}
        >
            <Grid item xs={8} sm={8} md={8} lg={8} sx={{ pl: 5 }}>
                <Typography>
                    {name}
                    <IconButton
                        sx={{ ml: 1 }}
                        onClick={handleLogout}
                    >
                        <LogoutIcon />
                    </IconButton>
                </Typography>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} sx={{ pt: 0.5 }}>
                {/* <LanguageSelect /> */}
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} sx={{ pt: 1 }}>
                <LanguageSelect />
                {/* <Typography>
                    {t("version")}
                </Typography> */}
            </Grid>
        </GridFooter>
    );
};

export default Footer;
