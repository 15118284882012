import Swal from 'sweetalert2';

export const showMessage = ({title,message,type}) => {
    return(
        Swal.fire({
            title: '<span style="font-weight: normal; font-family: Lato, sans-serif;">'+title+'</span>',
            html: '<span style="font-style: normal; font-family: Lato, sans-serif;">'+message+'</span>',
            icon: type,
            confirmButtonColor: '#008ae8',
            didOpen: () => {
                const swalContainer = document.querySelector('.swal2-container');
                if (swalContainer) {
                    swalContainer.style.zIndex = '1300';
                }
            }
        })
    );
};

export const showMessageSure = ({title,message,type,btnCancel,btnConfirm,event}) => {
    return(
        Swal.fire({
            title: '<span style="font-weight: normal; font-family: Lato, sans-serif;">'+title+'</span>',
            html: '<span style="font-style: normal; font-family: Lato, sans-serif;">'+message+'</span>',
            icon: type,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: btnConfirm,
            cancelButtonText: btnCancel,
            didOpen: () => {
                const swalContainer = document.querySelector('.swal2-container');
                if (swalContainer) {
                    swalContainer.style.zIndex = '1300';
                }
            }
        }).then((result) => {
            if (result.isConfirmed) {
                event();
            }
        })
    );
};