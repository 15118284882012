// url base
const urlBase = window._env_.SERVER_URL;

// login
export async function loginUser(credentials) {
    const response = await fetch(`${urlBase}/api/v1/auth/authenticate`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
    const responseJson = await response.json()
    return responseJson
}

// forgot
export async function forgot(email) {
    const response = await fetch(`${urlBase}/api/v1/auth/forgot`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(email)
    })
    const responseJson = await response.json()
    return responseJson
}