// react
import React from "react";

// material ui
import { Box, Button, Card, CardContent, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Switch, TextField, fabClasses } from "@mui/material";

// my proyect
import { showMessage, showMessageSure } from "../../../../../utils/messages/Message";

const InputEdit = ({t,dataModal,data,event}) => {
    const [nameList, setNameList] = React.useState(data.name);
    const [status, setStatus] = React.useState(data.estado);

    const isOperationValid = (dto) => {
        if (dto.customer.data.estado !== status) return 1;
        if (dto.customer.select || dto.customer.lista.length > 0) return 2;
        if (dto.diffusion.totalLista > dto.diffusion.lista.length && dto.diffusion.lista.length > 0) return 2;
        if (dto.diffusion.select && dto.diffusion.filter) return 2;
        if (dto.diffusion.select && dto.diffusion.listaSelect.length > 0) return 2;
        if (data.name !== nameList) return 3;
    
        return 0;
    };

    const editList = (e) => {
        e.preventDefault();

        const editDto = {
            customer: {
                ...dataModal.dataCustomer,
                select: dataModal.dataCustomer.select,
                filter: dataModal.dataCustomer.vfilter
            },
            diffusion: {
                ...dataModal.dataDiffusion,
                select: dataModal.dataDiffusion.listaSelect.length > 0 ? false : !dataModal.dataDiffusion.select,
                totalLista: dataModal.dataDiffusion.totalLista,
                filter: dataModal.dataDiffusion.vfilter,
                data: {
                    ...dataModal.dataDiffusion.data,
                    name: nameList,
                    estado: status
                }
            }
        };

        if (isOperationValid(editDto) > 0) {
            sendChange(editDto);
        } else {
            if (editDto.diffusion.select || editDto.diffusion.lista.length > 0) {
                showMessage({title: t("menssage_title_oi"), message: t("menssage_conte_vacio"), type: 'error'});
            } else {
                showMessage({title: t("menssage_title_atencion"), message: t("menssage_conte_nocambios"), type: 'warning'});
            }
        }
    };

    const sendChange = (dto) => {        
        if(isOperationValid(dto) === 1 && !status) return changeList(dto,t("desactiva_listacustomer",{nameList:nameList}));
        if(isOperationValid(dto) === 2) return countElements(dto);
        if(isOperationValid(dto) === 3) return changeList(dto,t("change_listname",{oldNameList:data.name, newNameList:nameList}));
        if(isOperationValid(dto) === 1 && status) return changeList(dto,t("activa_listacustomer",{nameList:nameList}));
    }
    
    const countElements = (dto) => {
        const diffusion = dto.diffusion;
        const customer = dto.customer;
        let removerNumber = 0;
        let agregarNumber = 0;
        let total = diffusion.totalLista;
        if (!diffusion.select) {
            removerNumber = diffusion.lista.length;
        } else if (diffusion.listaSelect.length > 0) {
            removerNumber = diffusion.totalLista - diffusion.listaSelect.length;
        } else if (diffusion.select) removerNumber = diffusion.totalLista;
    
        if (customer.select) {
            agregarNumber = customer.totalLista;
        } else {
            agregarNumber = customer.lista.length;
        }
        total = total + agregarNumber;
        total = total - removerNumber;
        const combinedMessage = `${t('edit_ini_customers')} ${t('edit_add_customers',{addCustomer:agregarNumber})} ${t('edit_remove_customers',{lessCustomer:removerNumber})} ${t('edit_totale_customers',{total:total})}`.trim();
        changeList(dto,combinedMessage);
    }
    
    const changeList = (dto, message) => {
        showMessageSure({
            title: t("menssage_title_atencion"),
            message: message,
            type: "warning",
            btnCancel: t("swal_create_difusion_button_cancel"),
            btnConfirm: t("continuar"),
            event: () => event(dto)
        });
    }

    return(
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ backgroundColor: "transparent" }} >
            <Box display="flex" justifyContent="center" alignItems="center" sx={{ backgroundColor: "transparent" }} >
                <Card sx={{margin: "5px"}}>
                    <CardContent>
                        <form onSubmit={editList}>
                            <TextField
                                label={t("modallistacliente_textfield_name")}
                                sx={{ marginRight: "10px" }}
                                size="small"
                                value={nameList}
                                onChange={(e) => setNameList(e.target.value)}
                                required
                            />
                            <FormControl component="fieldset">
                                <FormLabel component="legend" sx={{marginBottom: "-11px"}}>{t("modalcliente_textfield_estado")}</FormLabel>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Switch checked={status} onChange={ e => { setStatus(e.target.checked); }}/>}
                                        label={ status ? t("chipestado_true") : t("chipestado_false") }
                                    />
                                </FormGroup>
                            </FormControl>
                            <Button
                                variant="contained"
                                type="submit"
                                sx={{ backgroundColor: "info.main", margin: "1px" }}
                            >
                                {t("clientes_modal_edit_btn")}
                            </Button>
                        </form>
                    </CardContent>
                </Card>
            </Box>
        </Grid>
    );
}

export default InputEdit;