import { Grid, Typography } from "@mui/material";
import TDashDiffusion from "./TDashDiffusion";
import EventBar from "./EventBar";

const DiffusionCard = ({ t }) => {
    return (
        <>
            <Typography
                variant="h5"
                sx={{ fontWeight: 'bold', marginTop: '20px', marginBottom: '5px' }}
            >
                {t("dashboard_coupons_table")}
            </Typography>
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ display: 'flex', flexDirection: 'column', height: 395 }} marginBottom={3.5}>
                <Grid container rowSpacing={2.5} columnSpacing={2.5} sx={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} sx={{ display: 'flex', height: '100%' }}>
                        <TDashDiffusion t={t} />
                    </Grid>
                    {/* <Grid item xs={12} sm={12} md={4} lg={4} sx={{ display: 'flex', height: '100%' }}>
                    <EventBar t={t} />
                </Grid> */}
                </Grid>
            </Grid>
        </>
    );
};

export default DiffusionCard;
