import React from "react";
import { diffusionListFilterData } from "../../DataDashboard";
import SpinnerMessageBasic from "../../../../components/spinner/SpinnerMessageBasic";
import TableSimple from "../../../../components/table/TableSimple";
import { createRow, getColumns } from "./TableUtils";
import moment from "moment/moment";

const TDashDiffusion = ({ t }) => {
    const [listDiffusion,setListDiffusion] = React.useState([]);
    const [empty,setEmpty] = React.useState(true);
    
    const getListDiffusion = (data) => {
        setListDiffusion(data);
        setEmpty(false);
    }

    React.useEffect(() => {
        const dateInit = new moment().startOf('month').format("YYYY-MM-DD");
        const dateEnd = new moment().endOf("month").format("YYYY-MM-DD");
        diffusionListFilterData(dateInit, dateEnd, getListDiffusion);
    }, []);

    if(empty) {
        return <SpinnerMessageBasic />;
    }

    return(
        <TableSimple
            heigth={"100%"}
            alignRow={"center"}
            columns={getColumns(t)}
            rows={createRow(listDiffusion)}
            message={t("tablero_msj")}
            bColor={"rgba(0, 138, 232,0.3)"}
            lColor={"gray"}
        />
    );

};


export default TDashDiffusion;