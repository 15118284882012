// react
import React, { useEffect } from 'react';

// material-ui
import { Button, Card, CardContent, Grid, InputAdornment, TextField, Typography } from "@mui/material";

// icons
import SearchIcon from '@mui/icons-material/Search';

// otros
import { useTranslation } from "react-i18next";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

// my proyect
import ModalMarca from "./ModalMarca";
import TablaBasica from "../../components/table/TablaBasica";

// service
import { brandFullList, addBrand, updateBrand, packageList } from './DataMarcas';

// table utils
import { getColumns, createRow } from './TableMarcasUtils';

const Marcas = () => {

    useEffect(() => {
        brandFullList(1,100,setFilas,setOriginalFilas);
        packageList(setMyPackageList);
    }, []);

    const MySwal = withReactContent(Swal);
    const { t } = useTranslation();

    // modal
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [myPackageList, setMyPackageList] = React.useState([]);

    const handleAdd = (marca) => {
        handleClose();
        console.log(marca)
        addBrand(
            {
                name:marca.marca,
                chqServer:marca.chq,
                cshqServer:marca.cshq,
                chqApi:marca.api,
                crmApi:marca.crm,
                chqVersion:marca.vsn,
                isEnable:marca.estado,
                packagId:marca.pak
            },
            setFilas,
            filas,
            MySwal,
            t
        )
    }

    // serch
    const [serch, setSerch] = React.useState("");

    const serchList = (serch) => {
        setSerch(serch);

        if(serch.length === 0) {
            setFilas(originalFilas);
        } else {
            const updatedFilas = filas.filter(fila => fila.name.includes(serch));
            setFilas(updatedFilas);
        }
    }

    // tabla
    const [filas, setFilas] = React.useState([]);
    const [originalFilas, setOriginalFilas] = React.useState([]);

    const rowsChanges = (marca) => {
        updateBrand(
            {
                id:marca.idBrand,
                name:marca.marca,
                chqServer:marca.chq,
                cshqServer:marca.cshq,
                chqApi:marca.api,
                crmApi:marca.crm,
                chqVersion:marca.vsn,
                isEnable:marca.estado,
                permanentEventsId:marca.idPermanent,
                packagEventsId:marca.idHistory,
                packagId:marca.idPackag,
                addPermanentEvents:marca.addPermanentEvents
            },
            setFilas,
            filas,
            MySwal,
            t
        );
    }

    const newFilas = createRow(filas, myPackageList, rowsChanges);

    return(
        <>
            <Typography variant="h5" sx={{ fontWeight: 'bold', marginTop: '20px' }} >{t("marca_main_title")}</Typography>

            <ModalMarca 
                open={open}
                titulo={t("marca_modal_title")}
                btnName={t("marca_modal_main_btn")}
                mainEvent={handleAdd}
                closeEvent={handleClose}
                data={
                    {
                        marca: "",
                        chq: "",
                        cshq: "",
                        api: "",
                        crm: "",
                        vsn: "",
                        pak: "",
                        estado:false,
                        packageList:myPackageList
                    }
                }
            />

            <Card>
                <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={2.75} align={"right"} >
                        <Grid item xs={12} sm={12} md={12} lg={12} >
                            <Button 
                                variant="contained"
                                sx={{ backgroundColor: "#008AE8", '&:hover': { bgcolor: '#316FB5' }, mr:'5px' }}
                                onClick={ handleOpen }
                            >
                                {t("marca_add_btn")}
                            </Button>
                            <TextField
                                size="small"
                                value={serch}
                                onChange={ e => {serchList(e.target.value);} }
                                label={t("marca_serch_textfield")}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <SearchIcon />
                                    </InputAdornment>
                                  ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} >
                            <TablaBasica heigth={"100%"} alignRow={"center"} columns={getColumns(t)} rows={newFilas} ></TablaBasica>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    );

};

export default Marcas;