// my proyect
import Notification from './Notification';
import Profile from './profile/Profile';

// material-ui
import { styled } from '@mui/material/styles';
import { AppBar as MuiAppBar, Toolbar, IconButton, Grid } from '@mui/material';

// assets
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

const Header = ({ drawerWidth, open, handleDrawerToggle }) => {

    const AppBar = styled(MuiAppBar, {
      shouldForwardProp: (prop) => prop !== 'open',
    })(({ theme, open }) => ({
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }),
    }));

    return(
        <AppBar position="fixed" style={{ background: '#008AE8' }} open={open}>
            <Toolbar>
                <IconButton
                    aria-label="open drawer"
                    onClick={handleDrawerToggle}
                    edge="start"
                    sx={{ color: 'text.primary', bgcolor: '#f0f0f0', ml: { xs: 0, lg: -2 }, '&:hover': { bgcolor: '#d9d9d9' }, borderRadius: 1 }}
                >
                    {!open ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                </IconButton>
                <Grid sx={{ width: { xs: '100%', md: 1600 } }}></Grid>
                <Notification />
                <Profile />
            </Toolbar>
        </AppBar>
    );
}

export default Header;