// react
import React from 'react';

// my proyect
import Profile from './profile/Profile';

// material-ui
import { styled } from '@mui/material/styles';
import { AppBar as MuiAppBar, Toolbar, IconButton } from '@mui/material';

// assets
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

// otros
import { useTranslation } from "react-i18next";
import DashBoardSearch from './DashboardSearch';

const AppBar = styled(MuiAppBar)(({ theme, open, drawerwidth }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerwidth,
    width: `calc(100% - ${drawerwidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const HeaderUser = ({ open, handleDrawerToggle, giveDates }) => {

  const { t } = useTranslation();

  return(
      <AppBar 
        position="fixed"
        style={{ background: '#008AE8' }}
        open={open}
        drawerwidth={240}
      >
          <Toolbar>
              <IconButton
                  aria-label="open drawer"
                  onClick={handleDrawerToggle}
                  edge="start"
                  sx={{ color: 'text.primary', bgcolor: '#f0f0f0', ml: { xs: 0, lg: -2 }, '&:hover': { bgcolor: '#d9d9d9' }, borderRadius: 1 }}
              >
                  {!open ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              </IconButton>
              {/* <DashBoardSearch
                t={t}
                giveDates={giveDates}
              /> */}
              {/* <Profile /> */}
          </Toolbar>
      </AppBar>
  );
}

export default HeaderUser;