function createData(id, cashier, monto, receiptnum) {
    return { id, cashier, monto, receiptnum };
}

export function createRowCMR(items) {
    let rows = [];
    let id = 0;
    
    items.sort((a, b) => b.monto - a.monto);

    for(let item of items) {
        id++;
        rows.push(
            createData(
                id,
                item.cashier,
                "$"+Number(item.monto).toLocaleString("en-US"),
                item.receiptnum
            )
        );
    }

    return rows;
}

// calcular el total de tickets  
export function totalTicketCMR(rows) {
    return rows.map(({ receiptnum }) => receiptnum).reduce((sum, i) => sum + i, 0);
}

// calcular total de monto reembolsado en efectivo  
export function totalMontoCMR(rows) {
    const total = rows.reduce((acc, row) => {
        const monto = parseFloat(row.monto);
        return acc + monto;
    }, 0);

    return "$" + total.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g,'$1,'); // toFixed redondea el número hacia arriba
}