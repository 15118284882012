
const urlBase = window._env_.SERVER_URL;

// get - diffusion - list
export async function getAllLocation( token, brandId ) {
    const response = await fetch(`${urlBase}/location/${brandId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    })
    const responseJson = await response.json()
    return responseJson
}