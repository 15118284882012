// react
import React from "react";

// material-ui
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";

// my proyect
import SpinnerMessageBasic from "../spinner/SpinnerMessageBasic";


const TableEvent = ({columns, createRow, filas, eventTable, alignRow, traductor, rango = 10, reset, isReset, filter, isFilter}) => {
    // control
    const [spinner, isSpinner] = React.useState(true);
    const [active, isActive] = React.useState(true);

    // eventos de la tabla
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(rango);
    const [highestPage, setHighestPage] = React.useState(0);

    const handleChangePage = (event, newPage) => {
        if (newPage > highestPage) {
            isSpinner(true);
            isActive(true);
            eventTable((newPage+1), rowsPerPage, true);
            setHighestPage(newPage);
        }
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        isSpinner(true);
        isActive(true);
        eventTable(1,event.target.value,false);
        setRowsPerPage(+event.target.value);
        setPage(0); // Resetear la página a 0 cuando cambias el número de filas por página
        setHighestPage(0); // Resetear también la página más alta
    };
    // fin evemtos de la tabla

    React.useEffect(() => {
        if (filas.totalElements === 0) {
            isSpinner(true);
            isActive(false);
        } else {
            isSpinner(false);
            isActive(true);
        }

        if(reset) {
            isSpinner(true);
            setPage(0);
            setHighestPage(0);
            isReset(false);
        }

        if(filter) {
            isSpinner(true);
            setPage(0);
            setHighestPage(0);
            isFilter(false);
        }
        setRowsPerPage(rango);
    }, [filas,reset,filter]);

    return(
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer 
                sx={{
                    maxHeight: "100%",
                    overflowX: "auto",
                    "&::-webkit-scrollbar": {
                        width: "6px",
                        height: "6px",
                    },
                    "&::-webkit-scrollbar-track": {
                        background: "#f1f1f1",
                    },
                    "&::-webkit-scrollbar-thumb": {
                        background: "#888",
                        borderRadius: "10px",
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                        background: "#555",
                    },
                }}
            >
                <Table stickyHeader size="small" >
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                    sx={{ textAlign: "center", backgroundColor: "#008AE8", color: "white" }}
                                    >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            spinner ?
                                (
                                    <TableRow>
                                        <TableCell
                                            style={{ 
                                                verticalAlign: 'middle', 
                                                textAlign: 'center', 
                                                height: '200px' // define this based on your needs 
                                            }}
                                            colSpan={columns.length}
                                        >
                                            <SpinnerMessageBasic 
                                                isActive={active}
                                                typeSpinner={true}
                                                message={traductor("marca_table_typography")}
                                            />
                                        </TableCell>
                                    </TableRow>
                                )
                            :
                                (
                                    createRow.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                        return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                            {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell key={column.id} align={alignRow}>
                                                {column.format && typeof value === 'number'
                                                    ? column.format(value)
                                                    : value}
                                                </TableCell>
                                            );
                                            })}
                                        </TableRow>
                                        );
                                    })
                                )
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                component="div"
                count={filas.totalElements}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={traductor("tablabasica_rowsperpage")}
            />
        </Paper>
    );

}

export default TableEvent;