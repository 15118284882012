// react
import React from "react";

// material ui
import { Grid, Card, CardContent, Box, Typography } from "@mui/material";

import qr from "../../../../assets/passes/qr.png";

const DesignShow = ({ t,colorLabel,colorForeground,colorBackground,logo,image }) => {

    const getColor = (color) => {
        return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;
    }

    const cardHeader = {
        height: "45px",
        marginBottom: "0px",
        borderRadius: 0,
        backgroundImage: `url(${logo})`,
        backgroundSize: "50px 50px",
        backgroundRepeat: "no-repeat",
        backgroundColor: getColor(colorBackground)
    };

    const cardTitle = {
        height: "90px",
        marginBottom: "0px",
        borderRadius: 0,
        backgroundImage: `url(${image})`,
        backgroundColor: getColor(colorBackground)
    };

    const cardContent = {
        height: "45px",
        marginBottom: "0px",
        borderRadius: 0,
        backgroundColor: getColor(colorBackground)
    };

    const cardFooter = {
        height: "160px",
        marginBottom: "0px",
        borderRadius: 0,
        backgroundColor: getColor(colorBackground)
    };

    return(
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{marginTop: "30px"}}>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <Box border={1} borderColor="grey.500" borderRadius={1} width="300px">
                    <Box style={cardHeader} display="flex" flexDirection="column" height="100%" justifyContent="space-between">
                        <Box display="flex" justifyContent="space-between">
                            <Typography variant="body2" align="left" style={{margin: '3px', color: getColor(colorLabel)}}></Typography>
                            <Typography variant="body2" align="right" style={{margin: '3px', color: getColor(colorLabel)}}>DISCOUNT</Typography>
                        </Box>
                        <Box display="flex" justifyContent="space-between">
                            <Typography variant="body2" align="left" style={{margin: '4px', color: getColor(colorForeground)}}></Typography>
                            <Typography variant="body2" align="right" style={{margin: '4px', color: getColor(colorForeground)}}>$100</Typography>
                        </Box>
                    </Box>
                    <Card  style={cardTitle}>
                        <CardContent>
                            <Typography variant="h3" align="left" style={{margin: '4px', color: "white"}}>NEW</Typography>
                            <Typography variant="body2" align="left" style={{margin: '4px', color: "white"}}>Buy one get one free</Typography>
                        </CardContent>
                    </Card>
                    <Box style={cardContent} display="flex" flexDirection="column" height="100%" justifyContent="space-between">
                        <Box display="flex" justifyContent="space-between">
                            <Typography variant="body2" align="left" style={{margin: '4px', color: getColor(colorLabel)}}>EXPIRED</Typography>
                            <Typography variant="body2" align="right" style={{margin: '4px', color: getColor(colorLabel)}}>STORE.NO</Typography>
                        </Box>
                        <Box display="flex" justifyContent="space-between">
                            <Typography variant="body2" align="left" style={{margin: '4px', color: getColor(colorForeground)}}>2023/04/22</Typography>
                            <Typography variant="body2" align="right" style={{margin: '4px', color: getColor(colorForeground)}}>CN21</Typography>
                        </Box>
                    </Box>
                    <Card  style={cardFooter}>
                        <CardContent style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={qr} style={{width:"40%"}}/>
                        </CardContent>
                    </Card>
                </Box>
            </Box>
        </Grid>
    );
}

export default DesignShow;