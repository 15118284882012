// material-ui
import { Card, CardContent, Grid } from '@mui/material';

// my proyect
import SectionTable from './SectionTable';

const CustomerList = ({ t }) => {

    return (
        <Card>
            <CardContent>
                <Grid container rowSpacing={1} columnSpacing={0.75}>
                    <SectionTable t={t} />
                </Grid>
            </CardContent>
        </Card>
    );
};

export default CustomerList;