import {Box, Card, CardContent, Grid, Typography} from "@mui/material";
import CountUp from "react-countup";

export const CardItemDashboard = ({ t, title, value, formattedDigital }) => {

    return (
        <Grid item xs={12} sm={12} md={3} lg={3} sx={{ height: 125, display: 'flex', flexDirection: 'column' }}>
            <Card sx={{ flexGrow: 1 }} color="success" >
                <Grid container direction="row" sx={{ height: "100%" }}>
                    <Grid item xs={12} sm={12} md={2} lg={2} m="auto" paddingLeft={2} >
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <CardContent>
                            <Typography variant="h6">{t(title)}</Typography>
                            <Box display="flex" alignItems="center">
                                {/* <NumberIcon variant="h6" /> */}
                                <Typography variant="h6" style={{ marginLeft: '3px' }}>
                                    <CountUp
                                        end={value}
                                        formattingFn={formattedDigital}
                                    />
                                </Typography>
                            </Box>
                        </CardContent>
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    )
}