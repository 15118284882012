
const urlBase = window._env_.SERVER_URL;

// get - brand
export async function getDetalleEfectivo(iniDate,endDate) {
    const response = await fetch(`${urlBase}/prevention/detalle-efectivo/${localStorage.getItem('brand')}/${iniDate}/${endDate}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    })
    const responseJson = await response.json()
    return responseJson
}

// get - brand
export async function getDetallePagos(iniDate,endDate) {
    const response = await fetch(`${urlBase}/prevention/detalle-pagos/${localStorage.getItem('brand')}/${iniDate}/${endDate}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    })
    const responseJson = await response.json()
    return responseJson
}