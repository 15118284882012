// react
import React, { useEffect } from "react";

// material ui
import { Card, CardContent, Grid } from "@mui/material";

// otros
import { useTranslation } from "react-i18next";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

// my proyect
import ModalCreate from "./modal/create/ModalCreate";
import SelectType from "./SelectType";
import SpinnerMessageBasic from '../../../components/spinner/SpinnerMessageBasic';

// service
import { updateDiffusion, allDiffusion, addSimpleDiffusion } from './DataDifusion';

// table utils
import { columns, createRow } from './TableDifusionUtils';
import TableEvent from "../../../components/table/TableEvent";

const nameCampaign = [
    'selecttype_1',
    'selecttype_2',
    'selecttype_3',
    'selecttype_4',
    'selecttype_5'
];

const Difusion = () => {

    // traductor
    const { t } = useTranslation();
    const message = t("tablero_msj");

    // control
    const [spinner, setSpinner] = React.useState(true);
    const [isEmpty, setIsEmpty] = React.useState(true);
    const [typeForm, setTypeForm] = React.useState(0);

    // swal
    const MySwal = withReactContent(Swal);

    // modal
    const [open, setOpen] = React.useState(false);

    // tabla
    const [filasCopie, setFilasCopie] = React.useState([]);
    const [filas, setFilas] = React.useState([]);

    const getFilas = (newFilas) => {
        setSpinner(true);
        setIsEmpty(true);
        setFilasCopie(newFilas);
        setFilas(newFilas);
        setSpinner(false);
        setIsEmpty(newFilas.length === 0);
    }

    const updateFilas = (newFilas) => {
        setFilas(filas => updateContet(filas, newFilas));
        setFilasCopie(filasCopie => updateContet(filasCopie, newFilas));
    }

    const updateContet = (editFilas, newFila) => {        
        const index = editFilas.content.findIndex(row => row.id === newFila.object.id);
        let fileLength = editFilas.totalElements;

        if (index !== -1) {
            editFilas.content[index] = newFila.object;
        } else {
            editFilas.content.push(newFila.object);
            fileLength++;
        }

        return {
            ...editFilas,
            totalElements: fileLength
        };
    }

    // add new item aqui
    const handleAdd = (e, newDifusion, fullData) => {
        e.preventDefault();
        console.log("newDifusion",newDifusion);
        console.log("fullData",fullData);
        const totalEvents = fullData.object.brandPackageHistoryDtoE.remainingEvents + fullData.object.brandPermanentEventsDtoE.remainingEvents;
        const spentEvents = fullData.object.listCustomer.find(customer => customer.id === newDifusion.listCustomerId);
        const remainingEvents = totalEvents - spentEvents.customersCount;

        Swal.fire({
            title: t('swal_create_difusion_title'),
            text: t('swal_create_difusion_content', {
                totalEvents: totalEvents.toLocaleString("en-US"), 
                spentEvents: spentEvents.customersCount.toLocaleString("en-US"), 
                remainingEvents: remainingEvents.toLocaleString("en-US") 
            }),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t('swal_create_difusion_button_confirm'),
            cancelButtonText: t('swal_create_difusion_button_cancel'),
            didOpen: () => {
                const swalContainer = document.querySelector('.swal2-container');
                if (swalContainer) {
                    swalContainer.style.zIndex = '1202';
                }
            }
        }).then((result) => {
            if (result.isConfirmed) {
                setOpen(false);
                setSpinner(true);
                setIsEmpty(true);
                newDifusion.typeCampaign = nameCampaign.find((key, index) => typeForm === (index + 1));
                addSimpleDiffusion(newDifusion, addDifusion, MySwal, t);
            }
        })
    }

    const addDifusion = (newFilas) => {
        setFilas(filas => {
            return {
                ...filas,
                content: [newFilas.object, ...filas.content],
                totalElements: (filasCopie.totalElements + 1)
            }
        });
        setFilasCopie(filas);
        setIsEmpty(newFilas.code !== 100);
        setSpinner(false);
    }

    const addFilas = (newFilas) => {
        setIsEmpty(true);
        setSpinner(true);
        setFilas(filas => {
            return {
                ...filas,
                content: [...filas.content, ...newFilas.content],
                totalElements: newFilas.totalElements
            }
        });
        setFilasCopie(filas);
        setIsEmpty(newFilas.content.length === 0);
        setSpinner(false);
    }

    const rowsChanges = (difusion) => {
        updateDiffusion(difusion,updateFilas, MySwal, t);
    }

    const changeTable = (page,size,typeChange) => {
        setSpinner(true);
        if(typeChange) {
            allDiffusion( addFilas,page,size );
        } else {
            allDiffusion( getFilas,page,size );
        }
    }

    useEffect(() => {
        allDiffusion( getFilas, 1, 10 );
    }, []);

    return(
        <>
            {
                open ? 
                    (
                        <ModalCreate
                            open={open}
                            setOpen={setOpen}
                            typeForm={typeForm}
                            mainEvent={handleAdd}
                        />
                    )
                :
                    (<></>)
            }
            <Card>
                <CardContent>
                    <Grid container rowSpacing={4.5} columnSpacing={2.75} >
                        <SelectType
                            t={t}
                            setOpen={setOpen}
                            setTypeForm={setTypeForm}
                            listCopie={filasCopie}
                            setList={setFilas}
                        />
                        <Grid item xs={12} sm={12} md={12} lg={12} >
                            {
                                isEmpty ? 
                                    (
                                        <SpinnerMessageBasic
                                            isActive={spinner}
                                            message={message} 
                                        />
                                    )
                                :
                                    (
                                        // <TablaBasica 
                                        //     heigth={"100%"}
                                        //     alignRow={"center"}
                                        //     columns={columns(t)}
                                        //     rows={createRow(filas, rowsChanges)} >

                                        //     </TablaBasica>
                                        <TableEvent
                                            columns={columns(t)}
                                            createRow={createRow(filas, rowsChanges)}
                                            filas={filas}
                                            eventTable={changeTable}
                                            heigth={"100%"}
                                            alignRow={"center"}
                                            traductor={t}
                                            message={t("listaclientes_msj")}
                                            spinner={false}
                                        />
                                    )
                            }
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    );
}

export default Difusion;