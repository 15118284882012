// react
import React from 'react';
import { useHistory  } from 'react-router-dom'; // cuidado es deprecated con la v5 funciona hay que actualizar a la v6

// material-ui
import { Box, Avatar, ButtonBase, Stack, Typography } from "@mui/material";
import MenuProfile from './MenuProfile';

const Profile = () => {

    const name = localStorage.getItem('name') || "";
    // const email = localStorage.getItem('email') || "";
    const image = localStorage.getItem('image') || "";

    // new
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    
    const history = useHistory(); // cuidado es deprecated  

    const handleLogout = () => {
        let beforeClean = localStorage.getItem("i18nextLng");
        localStorage.clear();
        localStorage.setItem("i18nextLng",beforeClean);
        history.push('/login');
    }

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 0.75 }}>
            <ButtonBase
                ref={anchorRef}
                id="composition-button"
                aria-controls={open ? 'composition-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
                    <Avatar sx={{ width: 32, height: 32 }} src={image}/>
                    <Typography variant="subtitle1">{name}</Typography>
                    <MenuProfile open={open} anchorRef={anchorRef} setOpen={setOpen} handleLogout={handleLogout} />
                </Stack>
            </ButtonBase>
        </Box>
    );

};

export default Profile;