export function genderItems( t ) {
    return [
        {key: 0, value: 0, label: t("modallistacliente_title_select_gender")},
        {key: 1, value: "Male", label: t("modallistacliente_title_select_gender_masculino")},
        {key: 2, value: "Female", label: t("modallistacliente_title_select_gender_femenino")},
    ]
}

export function birthdayItems( t ) {
    const monthKeys = [
        "modallistacliente_title_select_birthday_january",
        "modallistacliente_title_select_birthday_february",
        "modallistacliente_title_select_birthday_march",
        "modallistacliente_title_select_birthday_april",
        "modallistacliente_title_select_birthday_may",
        "modallistacliente_title_select_birthday_june",
        "modallistacliente_title_select_birthday_july",
        "modallistacliente_title_select_birthday_august",
        "modallistacliente_title_select_birthday_september",
        "modallistacliente_title_select_birthday_october",
        "modallistacliente_title_select_birthday_november",
        "modallistacliente_title_select_birthday_december"
    ];

    const items = monthKeys.map((key, index) => {
        return { key: index + 1, value: index + 1, label: t(key) };
    });

    // Adding the initial "select" option
    items.unshift({ key: 0, value: 0, label: t("modallistacliente_title_select_birthday") });

    return items;
}

export function customFlag1Label( t ) {
    return [
        {key: 0, value: 0, label: t("modallistacliente_title_select_customflag1")},
        {key: 1, value: true, label: t("modallistacliente_title_select_customflag1_active")},
        {key: 2, value: false, label: t("modallistacliente_title_select_customflag1_inactive")},
    ]
}

export function customFlag2Label( t ) {
    return [
        {key: 0, value: 0, label: t("modallistacliente_title_select_customflag2")},
        {key: 1, value: true, label: t("modallistacliente_title_select_customflag2_active")},
        {key: 2, value: false, label: t("modallistacliente_title_select_customflag2_inactive")},
    ]
}

export function activeItems( t ) {
    return [
        {key: 0, value: 0, label: t("modallistacliente_title_select_active")},
        {key: 1, value: true, label: t("modallistacliente_title_select_active_active")},
        {key: 2, value: false, label: t("modallistacliente_title_select_active_inactive")},
    ]
}
