import React from 'react';

import { formattedFecha } from "../../utils/formatted/FormattedNumber";

import CanvasJSReact from '@canvasjs/react-charts';

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const LineWidget = ({ t, graph, title, axisY, axisY2, text1, text2 }) => {

    const dataPointsGenerated = graph.map(item => ({
        y: item.couponGenerated,
        label: formattedFecha(item.fecha) // Obtener el día de la fecha
    }));
    const dataPointsRedeemed = graph.map(item => ({
        y: item.couponsRedeemed,
        label:formattedFecha(item.fecha)  // Obtener el día de la fecha
    }));

    const options = {
        animationEnabled: true,


        axisY: {
            title: axisY,
            titleFontColor: "#316FB5",
            lineColor: "#316FB5",
            labelFontColor: "#316FB5",
            tickColor: "#316FB5"
        },
        axisY2: {
            title: axisY2,
            titleFontColor: "#6D7075",
            lineColor: "#6D7075",
            labelFontColor: "#6D7075",
            tickColor: "#6D7075"
        },
        axisX: {
            labelAngle: -45,
            interval: 1
        },
        toolTip: {
            shared: true
        },
        data: [{
            type: "splineArea",
            name: text1,
            showInLegend: true,
            lineColor: "#316FB5",
            color: "#316FB5",
            dataPoints: dataPointsGenerated
        },
        {
            type: "splineArea",
            name: text2,
            showInLegend: true,
            axisYType: "secondary",
            lineColor: "#6D7075",
            color: "#6D7075",
            dataPoints: dataPointsRedeemed
        }]
    }
    return (
        <div>
            <CanvasJSChart options={options} />
        </div>
    );
}

export default LineWidget;
