// assets
import { TeamOutlined } from '@ant-design/icons';

// icons
const icons = {
    TeamOutlined
};
// ==============================|| MENU ITEMS - SERVICIO ||============================== //

const root = {
    id: 'admin',
    title: 'menu_root_title_global',
    type: 'group',
    role: 'ROOT',
    children: [
        {
            id: 2,
            title: 'menu_root_title_usuarios',
            type: 'item',
            url: '/admin',
            icon: icons.TeamOutlined,
            breadcrumbs: false
        }
    ]
};

export default root;
