import { Grid, Divider, Typography, TextField } from "@mui/material";

const Reminder = ({t, typeReminder, difusion, setDifusion}) => {

    return(
        <>
            <Grid container justifyContent="center" sx={{ marginTop: '20px' }} >
                <Grid item xs={5} sm={5} md={5} lg={5}>
                    <Divider sx={{ my: 2 }} />
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2}>
                    <Typography 
                        variant="h5" 
                        sx={{ fontWeight: 'bold', textAlign: 'center' }} 
                    >
                        {
                            typeReminder === 1 ?
                                (t("partcupon_label_recordatorio_1"))
                            :
                                (t("partcupon_label_recordatorio_2"))                        
                        }
                    </Typography>
                </Grid>
                <Grid item xs={5} sm={5} md={5} lg={5}>
                    <Divider sx={{ my: 2 }} />
                </Grid>
            </Grid>

            <Grid item xs={12} sm={3} md={3} lg={3}>
                <TextField
                    label={
                        typeReminder === 1 ?
                            (t("partcupon_textfield_x_dias_1"))
                        :
                            (t("partcupon_textfield_x_dias_2"))
                    }
                    type="number"
                    size="small"
                    fullWidth
                    value={
                        typeReminder === 1 ?
                            difusion.dayReminder1
                        :
                            difusion.dayReminder2
                    }
                    onChange={
                        typeReminder === 1 ?
                            e => setDifusion({ ...difusion, dayReminder1: e.target.value })
                        :
                            e => setDifusion({ ...difusion, dayReminder2: e.target.value })
                    }
                    required
                />
            </Grid>

            <Grid item xs={12} sm={3} md={3} lg={3}>
                <TextField
                    label={t("partcupon_textfield_hora_envio_1")}
                    type="time"
                    size="small"
                    fullWidth
                    value={
                        typeReminder === 1 ?
                            difusion.dateSendReminder1
                        :
                            difusion.dateSendReminder2
                    }
                    onChange={
                        typeReminder === 1 ?
                            e => setDifusion({ ...difusion, dateSendReminder1: e.target.value })
                        :
                            e => setDifusion({ ...difusion, dateSendReminder2: e.target.value })
                    }
                    required
                    InputLabelProps={{
                    shrink: true, // Mostrar la etiqueta incluso si no hay valor seleccionado
                    }}
                />
            </Grid>

            <Grid item xs={12} sm={3} md={3} lg={3}>
                <TextField
                    label={t("partcupon_textfield_titulo_1")}
                    size="small"
                    fullWidth
                    value={
                        typeReminder === 1 ?
                            difusion.titleReminder1
                        :
                            difusion.titleReminder2
                    }
                    onChange={
                        typeReminder === 1 ?
                            e => setDifusion({ ...difusion, titleReminder1: e.target.value })
                        :
                            e => setDifusion({ ...difusion, titleReminder2: e.target.value })
                    }
                    required
                />
            </Grid>

            <Grid item xs={12} sm={3} md={3} lg={3}>
                <TextField
                    label={t("partcupon_textfield_contenido_1")}
                    size="small"
                    fullWidth
                    value={
                        typeReminder === 1 ?
                            difusion.contentReminder1
                        :
                            difusion.contentReminder2
                    }
                    onChange={
                        typeReminder === 1 ?
                            e => setDifusion({ ...difusion, contentReminder1: e.target.value })
                        :
                            e => setDifusion({ ...difusion, contentReminder2: e.target.value })
                    }
                    required
                />
            </Grid>
        </>
    );
}

export default Reminder;