// react
import React from "react";

// material ui
import { Box, Button, Card, CardContent, Grid } from "@mui/material";

// my proyect
import SpinnerMessageBasic from "../spinner/SpinnerMessageBasic";
import General from "./General";
import Options from "./Options";

const Filter = ({t, data, setData, cancelEvent, syncCustomers}) => {
    // control
    const [options, setOptions] = React.useState(false);

    const moreOption = () => {
        setOptions(!options);
        setData(prevState => ({
            ...prevState,
            dataFilter: {
                ...prevState.dataFilter,
                isOption: !options
            }
        }));
    }

    const activeFilter = (e) => {
        e.preventDefault();
        setData(prevData => ({
            ...prevData,
            filter: true
        }));
    }

    if (!data.dataFilter || Object.keys(data.dataFilter).length === 0) {
        return <SpinnerMessageBasic />;
    }

    return(
        <Card sx={{ margin: "5px"}}>
            <CardContent>
                <form onSubmit={activeFilter}>
                    <General 
                        t={t}
                        data={data}
                        setData={setData}
                    />
                    {
                        options ? 
                            <Options
                                t={t} 
                                data={data}
                                setData={setData}
                            />
                        :
                            <></>
                    }
                    <Grid container>
                        <Grid item xs={6}>
                            <Box display="flex" justifyContent="flex-start">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{ backgroundColor: "#52c41a", '&:hover': { bgcolor: '#237804' }, margin: "5px" }}
                                >
                                    {t("modallistacliente_title_filter_button_search")}
                                </Button>
                                <Button
                                    variant="contained"
                                    sx={{ backgroundColor: "#f5222d", '&:hover': { bgcolor: '#a8071a' }, margin: "5px" }}
                                    onClick={ cancelEvent }
                                >
                                    {t("modalcliente_cancel_btn")}
                                </Button>
                                <Button
                                    variant="contained"
                                    sx={{ backgroundColor: "#ffcc00", '&:hover': { bgcolor: '#ec942c' }, margin: "5px" }}
                                    onClick={ cancelEvent }
                                >
                                    {t("modalcliente_sync_btn")}
                                </Button>
                            </Box>
                        </Grid>
                        {
                            data.dataFilter.option ?
                                <Grid item xs={6}>
                                    <Box display="flex" justifyContent="flex-end">
                                        <Button
                                            variant="contained"
                                            sx={{ backgroundColor: "info.main", margin: "5px" }}
                                            onClick={moreOption}
                                        >
                                            {
                                                options ?
                                                    t("filter_options_hide")
                                                    :
                                                    t("filter_options")
                                            }
                                        </Button>
                                    </Box>
                                </Grid>
                            :
                                <></>
                        }
                    </Grid>
                </form>
            </CardContent>
        </Card>

    );
}

export default Filter;