// assets
import { DashboardOutlined, BarChartOutlined } from '@ant-design/icons';

// icons
const icons = {
    DashboardOutlined,
    BarChartOutlined
};
// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const analisis = {
    id: 'analisis',
    title: 'menu_analisis_title_global',
    type: 'group',
    role: 'USER',
    children: [
        {
            id: 1,
            title: 'menu_analisis_title_dashboard',
            type: 'item',
            url: '/dashboard',
            icon: icons.DashboardOutlined,
            breadcrumbs: false
        }
    ]
};

export default analisis;
