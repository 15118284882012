// react
import React, { useEffect } from 'react';

// material-ui
import { Button, Card, CardContent, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";

// otros
import { useTranslation } from "react-i18next";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

// my proyect
import ModalAddUsuario from "./ModalAddUsuario";
import TablaBasica from '../../components/table/TablaBasica';

// service
import { brandList, userList, addUser, updateUser } from './DataUsuario';

// table utils
import { getColumns, createRow } from './TableUsuarioUtils';

const Usuario = () => {

    useEffect(() => {
        brandList(setList);
    }, []);

    const MySwal = withReactContent(Swal);
    const { t } = useTranslation();

    const [marca, setMarca] = React.useState(0);

    const [list, setList] = React.useState([]);

    const handleChange = (event) => {
        setMarca(event.target.value);
        if(event.target.value !== 0) {
            const nameBrand = list.find(brand => brand.id === event.target.value).name;
            userList(event.target.value,setFilas,nameBrand);
        }
    };

    // modal
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleAdd = (usuario) => {
        handleClose();
        addUser({firstName:usuario.firstName, lastName:usuario.lastName, email:usuario.email, password:usuario.pass, roles:usuario.rolesString, isEnable:usuario.estado, brandId:marca}, setFilas, filas, MySwal, t);
        // setFilas([...filas, {id:filas.length + 1, marca:usuario.marca, chq:usuario.roles, evcn:usuario.email, usac:usuario.pass, estado:usuario.estado}]);
    }

    // tabla
    const [filas, setFilas] = React.useState([]);

    const rowsChanges = (usuario) => {
        updateUser({
            id        : usuario.id,
            firstName : usuario.firstName,
            lastName  : usuario.lastName,
            email     : usuario.email,
            password  : usuario.pass,
            roles     : usuario.rolesString,
            isEnable  : usuario.estado,
            brandId   : marca
        }, MySwal, t);
        const updatedFilas = filas.map((fila) =>
          fila.id === usuario.id ? { ...fila, ...{firstName:usuario.firstName, lastName:usuario.lastName, email:usuario.email, password:usuario.pass, roleUser:usuario.rolesString, isEnable:usuario.estado, brandId:marca} } : fila
        );
        setFilas(updatedFilas);
    }

    const newFilas = createRow(filas, rowsChanges);

    return(
        <>
            <Typography variant="h5" sx={{ fontWeight: 'bold', marginTop: '20px' }} >{t("usuario_main_title")}</Typography>

            <Card>
                <CardContent>
                    <Grid container rowSpacing={4.5} columnSpacing={2.75} >
                        <Grid item xs={12} sm={12} md={12} lg={12} align={"right"} >
                            
                            <FormControl sx={{ width: 240}}>
                                <InputLabel size="small" htmlFor="simple-select-label">{t("usuario_inputlabel")}</InputLabel>
                                <Select 
                                    size="small"
                                    id="simple-select"
                                    label={t("usuario_inputlabel")}
                                    value={marca ? marca : ""}
                                    onChange={handleChange}
                                >
                                        <MenuItem key={0} value={0}>
                                            <em>{t("usuario_main_menuitem")}</em>
                                        </MenuItem>
                                        {
                                            list && list.map((brand, index) => (
                                                <MenuItem key={index} value={brand.id}>{brand.name}</MenuItem>
                                            )) 
                                        }
                                </Select>
                                <FormHelperText>{t("usuario_formhelpertext")}</FormHelperText>
                            </FormControl>

                        </ Grid>

                        {
                            marca ?
                            <>
                                <ModalAddUsuario
                                    open={open}
                                    titulo={t("usuario_modal_title")}
                                    btnName={t("usuario_modal_main_btn")}
                                    mainEvent={handleAdd}
                                    closeEvent={handleClose}
                                    data={{marca:"", roles:[] , email:"", pass:"", estado:false}}
                                />

                                <Grid item xs={12} sm={12} md={12} lg={12} align={"right"} >
                                    <Button 
                                        variant="contained"
                                        sx={{ backgroundColor: "#008AE8", '&:hover': { bgcolor: '#316FB5' } }}
                                        onClick={ handleOpen }
                                    >
                                        {t("usuario_add_btn")}
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} >                            
                                    <TablaBasica heigth={"100%"} alignRow={"center"} columns={getColumns(t)} rows={newFilas} ></TablaBasica>
                                </Grid>
                            </>
                            :
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Typography variant='h6' sx={{ textAlign: "center" }} >{t("usuario_select_mark")}</Typography>
                            </Grid>
                        }
                    </Grid>
                </CardContent>
            </Card>
        </>
    );
}

export default Usuario;