// data
import { totalTicketLMR, totalMontoLMR } from './efectivo/DataEfectivoLMR';
import { totalMontoCMR, totalTicketCMR } from './efectivo/DataEfectivoCMR';
import { totalTokenLMR, totalCardLMR, totalVoucherLMR, totalTotalLMR } from './pagosespeciales/DataPagosEspecialesLMR';
import { totalTokenCMR, totalCardCMR, totalVoucherCMR, totalTotalCMR } from './pagosespeciales/DataPagosEspecialesCMR';

// columnas LMR
export function columnsELMR(t) {
    return [
        { id: 'nombreLocacion', label: t("prevencion_columns_lmr_name"), minWidth: 50 },
        { id: 'montoReembolsado', label: t("prevencion_columns_lmr_mont"), minWidth: 50 },
        { id: 'receiptnum', label: t("prevencion_columns_lmr_ticket"), minWidth: 50 },
    ];
}

// columnas LMR
export  function totalELMR(t,rowsLMR) {
    return [
        { id: 'total', label: t("prevencion_footer_lmr_total"), minWidth: 50 },
        { id: 'totalMont', label: totalMontoLMR(rowsLMR), minWidth: 50 },
        { id: 'totalTicket', label: totalTicketLMR(rowsLMR), minWidth: 50 },
    ];
}

// columnas LMR
export  function columnsECMR(t) {
    return [
        { id: 'cashier', label: t("prevencion_columns_cmr_cajero"), minWidth: 50 },
        // { id: 'locacion', label: t("prevencion_columns_cmr_name"), minWidth: 50 },
        { id: 'monto', label: t("prevencion_columns_cmr_mont"), minWidth: 50 },
        { id: 'receiptnum', label: t("prevencion_columns_cmr_ticket"), minWidth: 50 },
    ];
}

// columnas LMR
export  function totalECMR(t,rowsCMR) {
    return [
        { id: 'total', label: t("prevencion_footer_cmr_total"), minWidth: 50 },
        { id: 'totalMont', label: totalMontoCMR(rowsCMR), minWidth: 50 },
        { id: 'totalTicket', label: totalTicketCMR(rowsCMR), minWidth: 50 },
    ];
}

// columnas PLMR
export function columnsPLMR(t) {
    return [
        { id: 'nombreLocacion', label: t("pagosespeciales_columns_lmr_name"), minWidth: 40 },
        { id: 'tokenAmount', label: t("pagosespeciales_columns_lmr_token"), minWidth: 40 },
        { id: 'giftCardAmount', label: t("pagosespeciales_columns_lmr_card"), minWidth: 40 },
        { id: 'couponAmount', label: t("pagosespeciales_columns_lmr_voucher"), minWidth: 40 },
        { id: 'total', label: t("pagosespeciales_columns_lmr_total"), minWidth: 40 },
    ];
}

// footer PLMR
export function totalPLMR(t,rowsLMR) {
    return [
        { id: 'totals', label: t("pagosespeciales_footer_lmr_total"), minWidth: 40 },
        { id: 'totalTokens', label: totalTokenLMR(rowsLMR), minWidth: 40 },
        { id: 'totalCards', label: totalCardLMR(rowsLMR), minWidth: 40 },
        { id: 'totalVouchers', label: totalVoucherLMR(rowsLMR), minWidth: 40 },
        { id: 'totalTotal', label: totalTotalLMR(rowsLMR), minWidth: 40 },
    ];
}

// columnas PCMR
export function columnsPCMR(t) {
    return [
        { id: 'cashier', label: t("pagosespeciales_columns_cmr_cashier"), minWidth: 40 },
        // { id: 'locacion', label: t("pagosespeciales_columns_cmr_name"), minWidth: 90 },
        { id: 'tokenAmount', label: t("pagosespeciales_columns_cmr_token"), minWidth: 40 },
        { id: 'giftCardAmount', label: t("pagosespeciales_columns_cmr_card"), minWidth: 40 },
        { id: 'couponAmount', label: t("pagosespeciales_columns_cmr_voucher"), minWidth: 40 },
        { id: 'total', label: t("pagosespeciales_columns_cmr_total"), minWidth: 40 },
    ];
}

// footer PCMR
export function totalPCMR(t,rowsCMR) {
    return [
        { id: 'totals', label: t("pagosespeciales_footer_cmr_total"), minWidth: 40 },
        { id: 'totalTokens', label: totalTokenCMR(rowsCMR), minWidth: 40 },
        { id: 'totalCards', label: totalCardCMR(rowsCMR), minWidth: 40 },
        { id: 'totalVouchers', label: totalVoucherCMR(rowsCMR), minWidth: 40 },
        { id: 'totalTotal', label: totalTotalCMR(rowsCMR), minWidth: 40 },
    ];
}