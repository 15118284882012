export function getColumns( t ) {
    return [
        { id: 'name', label: t("location_table_name"), minWidth: 90 },
        { id: 'code', label: t("location_table_code"), minWidth: 90 },
        // { id: 'address', label: t("location_table_address"), minWidth: 90 },
        { id: 'city', label: t("location_table_city"), minWidth: 90 },
        // { id: 'state', label: t("location_table_state"), minWidth: 90 },
        { id: 'lat', label: t("location_table_lat"), minWidth: 90 },
        { id: 'lon', label: t("location_table_lon"), minWidth: 90 },
    ];
}

export function createData(id,name,code,address,city,state,lat,lon) {
    return {
        id,
        name,
        code,
        address,
        city,
        state,
        lat,
        lon
    };
}

export function createRow(items) {
    let rows = [];
  
    for(let item of items) {
        if(item.IsActive) {
            rows.push(
                createData(
                    item.LocationId,
                    item.Name,
                    item.Code,
                    item.ContactInfo.Address1,
                    item.ContactInfo.City,
                    item.ContactInfo.State,
                    item.ContactInfo.Latitude,
                    item.ContactInfo.Longitude,
                )
            );
        }
    }
  
    return rows;
}