// assets
import { NotificationOutlined } from '@ant-design/icons';

// icons
const icons = {
    NotificationOutlined
};
// ==============================|| MENU ITEMS - MARKETING ||============================== //

const marketing = {
    id: 'marketing',
    title: 'menu_marketing_title_global',
    type: 'group',
    role: 'CAMPANAS',
    children: [
        {
            id: 4,
            title: 'menu_marketing_title_campanas',
            type: 'item',
            url: '/campanas',
            icon: icons.NotificationOutlined,
            breadcrumbs: false
        }
    ]
};

export default marketing;
