// my service
import { getAllDiffusion } from '../../../services/campanas/difusion/DifusionServiceOld';
// import { getBrandDetalle, getcouponDashboard } from '../../../services/dashboard/DashboardService';

// get board
export const getBrand = async ( setCards ) => {
    try {
        return null;
    } catch (error) {
    }
}

// getAll
export const allDiffusion = async ( setFilas, page, size ) => {
    try {
        const response = await getAllDiffusion( page, size);
        setFilas(response.content);
    } catch (error) {
        console.error(error);
    }
}

// get coupon
export const couponDashboard = async ( setData ) => {
    try {
        return null;
        // const response = await getcouponDashboard();
        // setData(response.object);
    } catch (error) {
        console.error(error);
    }
}