// React
import React from 'react';

// material-ui
import { Card, CardContent, CardHeader, Modal } from "@mui/material";

// my proyect
import FormBirthday from './type_form/birthday/FormBirthday';
import FormClient from './type_form/client/FormClient';
import FormCrossSelling from './type_form/crossselling/FormCrossSelling';
import FormGeolocation from './type_form/geolocation/FormGeolocation';
import FormRecord from './type_form/record/FormRecord';

// otros
import { useTranslation } from "react-i18next";

// styles
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000
};

const ModalCreate = ({ open, setOpen, typeForm, mainEvent }) => {

    const { t } = useTranslation();

    const renderFormByType = (type) => {
        switch(type) {
            case 1: 
                return <FormClient t={t} style={style} setOpen={setOpen} mainEvent={mainEvent} />;
            case 2: 
                return <FormCrossSelling t={t} style={style} setOpen={setOpen} />;
            case 3: 
                return <FormGeolocation t={t} style={style} setOpen={setOpen} />;
            case 4: 
                return <FormRecord t={t} style={style} setOpen={setOpen} />;
            default:
                return <FormBirthday t={t} style={style} setOpen={setOpen} />;
        }
    }

    return(
        <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{zIndex: 1201}}
        >
            <Card sx={style}>
                <CardHeader
                    title = {t("modalnewdifusion_main_title")}
                    sx={{ backgroundColor: "#008AE8", color: "white", textAlign: "center" }}
                />
                <CardContent
                    sx={{
                        maxHeight: 'calc(100vh - 200px)',
                        mWidth: 'calc(100vh - 200px)',
                        overflowY: "auto",
                        "&::-webkit-scrollbar": {
                            width: "6px",
                            height: "6px",
                        },
                        "&::-webkit-scrollbar-track": {
                            background: "#f1f1f1",
                        },
                        "&::-webkit-scrollbar-thumb": {
                            background: "#888",
                            borderRadius: "10px",
                        },
                        "&::-webkit-scrollbar-thumb:hover": {
                            background: "#555",
                        },
                    }}
                >
                    {renderFormByType(typeForm)}
                </CardContent>
            </Card>
        </Modal>
    );
}

export default ModalCreate;