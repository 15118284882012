// react
import React from 'react';

// material-ui
import { ClickAwayListener, Grow, Paper, Popper, MenuItem, MenuList } from '@mui/material';

// material icon
import LogoutIcon from '@mui/icons-material/Logout';

// otros
import { useTranslation } from "react-i18next";

export default function MenuProfile({ open, anchorRef, setOpen, handleLogout }) {

  const { t } = useTranslation();

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open,anchorRef]);

  return (
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
          style={{margin: 0}}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom',
                  margin: 0
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    {/* <MenuItem onClick={handleClose}>Seleccionar idioma</MenuItem> */}
                    <MenuItem onClick={handleLogout}>
                        <LogoutIcon sx={{ mr: 1 }}/>
                        {t("btn_logout")}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
  );
}