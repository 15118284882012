// assets
import { SolutionOutlined, TeamOutlined, MailOutlined } from '@ant-design/icons';

// icons
const icons = {
    SolutionOutlined,
    TeamOutlined,
    MailOutlined
};
// ==============================|| MENU ITEMS - SERVICIO ||============================== //

const servicio = {
    id: 'clientes',
    title: 'menu_servicio_title_global',
    type: 'group',
    role: 'ADMIN',
    children: [
        {
            id: 5,
            title: 'menu_servicio_title_marcas',
            type: 'item',
            url: '/marcas',
            icon: icons.SolutionOutlined,
            breadcrumbs: false
        },
        {
            id: 8,
            title: 'menu_servicio_title_email_marcas',
            type: 'item',
            url: '/email',
            icon: icons.MailOutlined,
            breadcrumbs: false
        },
        {
            id: 6,
            title: 'menu_servicio_title_usuarios',
            type: 'item',
            url: '/usuarios',
            icon: icons.TeamOutlined,
            breadcrumbs: false
        }
    ]
};

export default servicio;
