export const createServiceHandler = (service) => {
    return async (...args) => {
        try {
            const response = await service(...args.slice(0, -1));
            const callback = args[args.length - 1];
            callback(response);
        } catch (error) {
            console.error(error);
        }
    };
};