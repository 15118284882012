// my service
import { getDetalleEfectivo, getDetallePagos } from '../../services/prevention/PreventionService';

// get detalle efectivo
export const detalleEfectivo = async ( setFilas, iniDate, endDate ) => {
    try {
        const response = await getDetalleEfectivo(iniDate, endDate);
        setFilas(response);
    } catch (error) {
        console.error(error);
    }
}

// get detalle pagos
export const detallePagos = async ( setFilas, iniDate, endDate ) => {
    try {
        const response = await getDetallePagos(iniDate, endDate);
        setFilas(response);
    } catch (error) {
        console.error(error);
    }
}
