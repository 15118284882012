// React
import React from 'react';

// material ui
import { Card, CardContent, Grid, InputAdornment, TextField } from '@mui/material';

// icons
import SearchIcon from '@mui/icons-material/Search';

// otros
import { useTranslation } from "react-i18next";

// my proyect
import TablaBasica from '../../../components/table/TablaBasica';

// service
import { listLocation } from './DataLocacion';

// table utils
import { createRow, getColumns } from './TableLocacionUtils';

const Locacion = () => {

    React.useEffect(() => {
        listLocation( localStorage.getItem('brand'), setFilas, setOriginalFilas );
    }, [])

    const { t } = useTranslation();

    // serch
    const [serch, setSerch] = React.useState("");

    const serchList = (serch) => {
        setSerch(serch);

        if(serch.length === 0) {
            setFilas(originalFilas);
        } else {
            const lowerCaseSearch = serch.toLowerCase();
            let searchResultName = searchInField(originalFilas, 'Name', lowerCaseSearch);
            let searchResultCode = searchInField(originalFilas, 'Code', lowerCaseSearch);
            let searchResultCity = searchInNestedField(originalFilas, 'ContactInfo', 'City', lowerCaseSearch);
            let searchResultLatitude = searchInNestedField(originalFilas, 'ContactInfo', 'Latitude', lowerCaseSearch);
            let searchResultLongitude = searchInNestedField(originalFilas, 'ContactInfo', 'Longitude', lowerCaseSearch);
            let combinedResult = [...new Set([...searchResultName, ...searchResultCode, ...searchResultCity, ...searchResultLatitude, ...searchResultLongitude])];
            setFilas(combinedResult);
        }
    }

    const searchInField = (items, field, searchString) => {
        return items.filter(item => 
            String(item[field]).toLowerCase().includes(searchString.toLowerCase())
        );
    }

    const searchInNestedField = (items, field1, field2, searchString) => {
        return items.filter(item => 
            String(item[field1][field2]).toLowerCase().includes(searchString.toLowerCase())
        );
    }
    // search

    // tabla
    const [filas, setFilas] = React.useState([]);
    const [originalFilas, setOriginalFilas] = React.useState([]);

    const newFilas = createRow(filas);

    return (
        <>
            <Card>
                <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={0.75}>
                        <Grid item xs={12} sm={12} md={12} lg={12} align={"right"} >
                            <TextField
                                value={serch}
                                size='small'
                                onChange={ e => {serchList(e.target.value);} }
                                label={t("clientes_serch_textfield")}
                                InputProps={{
                                    startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TablaBasica heigth={"100%"} alignRow={"center"} columns={getColumns(t)} rows={newFilas} ></TablaBasica>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    );
}

export default Locacion;