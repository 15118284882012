// material-ui
import { useTheme } from '@mui/material/styles';
import { ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';

// otros
import { useTranslation } from "react-i18next";

// project import
// import { activeItem } from 'store/reducers/menu';

// ==============================|| NAVIGATION - LIST ITEM ||============================== //

const NavItem = ({ item, open, handleMenu }) => {

    const { t } = useTranslation();

    const theme = useTheme();

    // let itemTarget = '_self';
    // if (item.target) {
    //     itemTarget = item.target;
    // }

    // let listItemProps = { component: forwardRef((props, ref) => <Link ref={ref} {...props} target={itemTarget} />) };
    // if (item?.external) {
    //     listItemProps = { component: 'a', href: item.url, target: itemTarget };
    // }

    const Icon = item.icon;
    const itemIcon = item.icon ? <Icon style={{ fontSize: '1.25rem' }} /> : false;

    const textColor = theme.palette.text.primary;

    return (
        <ListItemButton
            // {...listItemProps}
            disabled={item.disabled}
            sx={{ mb: open ? 0 : -1 }}
            onClick={() => handleMenu(item.id)}
        >
            {itemIcon && (
                <ListItemIcon
                    sx={{
                        minWidth: 28,
                        color: textColor
                    }}
                >
                    {itemIcon}
                </ListItemIcon>
            )}
            <ListItemText
                primary={
                    <Typography style={{ color: textColor, opacity: open ? 1 : 0 }}>
                        {t(item.title)}
                    </Typography>
                }
            />
        </ListItemButton>
    );
};

export default NavItem;
