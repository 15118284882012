// material ui
import { Button, Grid } from "@mui/material";

const ButtonSubmit = ({t, setOpen}) => {
    return(
        <Grid container spacing={2} sx={{ marginTop: '5px' }} justifyContent="flex-end">
            <Grid item xs={12} sm={2} md={2} lg={2}>
                    <Button
                        type="submit"
                        variant="contained"
                        sx={{ backgroundColor: "#52c41a", '&:hover': { bgcolor: '#237804' } }}
                    >
                        {t("admin_modal_main_btn")}
                    </Button>
            </Grid>
            <Grid item xs={12} sm={2} md={2} lg={2}>
                    <Button 
                        variant="contained"
                        sx={{ backgroundColor: "#f5222d", '&:hover': { bgcolor: '#a8071a' } }}
                        onClick={() => setOpen(false)}
                    >
                        {t("modalnewdifusion_cancel_btn")}
                    </Button>
            </Grid>
        </Grid>
    );
}

export default ButtonSubmit;