// React
import React from 'react';

// material-ui
import { Button, Card, CardContent, CardHeader, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Modal, Switch, TextField } from "@mui/material";

// otros
import { useTranslation } from "react-i18next";

// styles
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
};

const ModalAdmin = ({ open, titulo, btnName, mainEvent, closeEvent, data }) => {

    const { t } = useTranslation();

    const [firstName, setFirstName] = React.useState(data.firstname);
    
    const [lastName, setLastName] = React.useState(data.lastname);
    
    const [email, setEmail] = React.useState(data.email);

    const [estado, setEstado] = React.useState(data.estado);

    const handleAdd = (e) => {
        mainEvent({ id:data.id, firstName, lastName, email, password:"", isEnable:estado, roles: [{ name:"ADMIN" }] });
        closeEvent();

        if(!data.id) {
            handleKeepIni();
        }
    }

    const handleClose = (e) => {
        closeEvent();
        handleKeepIni();
    }

    const handleKeepIni = (e) => {

        setFirstName(data.firstname);

        setLastName(data.lastname);

        setEmail(data.email);
    
        setEstado(data.estado);

    }

    return(
        <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <form onSubmit={handleAdd}>
                <Card sx={style}>
                    <CardHeader
                        title={titulo}
                        sx={{ backgroundColor: "#008AE8", color: "white", textAlign: "center" }}
                    />
                    <CardContent>
                        <Grid container spacing={2} sx={{ marginTop: '5px' }}>
                            <Grid item xs={12} sm={3} md={3} lg={3}>
                                <TextField
                                    value={firstName}
                                    onChange={ e => {setFirstName(e.target.value);} }
                                    label={t("modaladmin_textfield_firstname")}
                                    fullWidth
                                    required
                                    onInvalid={e => e.target.setCustomValidity(t("modaladmin_textfield_fistname_required"))}
                                    onBlur={e => {
                                        if (/^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ\s]+$/.test(e.target.value)) {
                                            e.target.setCustomValidity("");
                                        } else {
                                            e.target.setCustomValidity(t("modaladmin_textfield_nonumbers_required"));
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3} md={3} lg={3}>
                                <TextField
                                    value={lastName}
                                    onChange={ e => {setLastName(e.target.value);} }
                                    label={t("modaladmin_textfield_lastname")}
                                    fullWidth
                                    required
                                    onInvalid={e => e.target.setCustomValidity(t("modaladmin_textfield_lastname_required"))}
                                    onBlur={e => {
                                        if (/^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ\s]+$/.test(e.target.value)) {
                                            e.target.setCustomValidity("");
                                        } else {
                                            e.target.setCustomValidity(t("modaladmin_textfield_nonumbers_required"));
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3} md={3} lg={3}>
                                <TextField
                                    type="email"
                                    value={email}
                                    onChange={ e => {setEmail(e.target.value);} }
                                    label={t("modaladmin_textfield_email")}
                                    fullWidth
                                    required
                                    onInvalid={e => e.target.setCustomValidity(t("modaladmin_textfield_email_required"))}
                                    onBlur={e => e.target.setCustomValidity("")}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3} md={3} lg={3}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">{t("modaladmin_textfield_status")}</FormLabel>
                                    <FormGroup>
                                        <FormControlLabel
                                        control={<Switch checked={estado} onChange={e => {setEstado(e.target.checked);} } />}
                                        label={ estado ? t("chipestado_true") : t("chipestado_false") }
                                        />
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                        
                        <Grid container spacing={2} sx={{ marginTop: '5px' }} justifyContent="flex-end">
                            <Grid item xs={12} sm={2} md={2} lg={2}>
                                    <Button 
                                        type="submit"
                                        variant="contained"
                                        sx={{ backgroundColor: "#52c41a", '&:hover': { bgcolor: '#237804' } }}
                                    >
                                        {btnName}
                                    </Button>
                            </Grid>
                            <Grid item xs={12} sm={2} md={2} lg={2}>
                                    <Button 
                                        variant="contained"
                                        sx={{ backgroundColor: "#f5222d", '&:hover': { bgcolor: '#a8071a' } }}
                                        onClick={ handleClose }
                                    >
                                        {t("modaluser_cancel_btn")}
                                    </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </form>
        </Modal>
    );
}

export default ModalAdmin;