// react
import React from "react";

// material ui
import { Button, Grid, InputAdornment, TextField } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';

// my proyect
import SpinnerMessageBasic from "../../../components/spinner/SpinnerMessageBasic";
import ModalCreate from "./modal/create/ModalCreate";

// data
import { customerListAddData, customerListData } from "./DataCustomerList";
import TCustomerList from "./table/TCustomerList";

const SectionTable = ({ t }) => {
    const [open,setOpen] = React.useState(false);
    const [search, setSearch] = React.useState("");
    const [fullCustomerList, setFullCustomerList] = React.useState([]);
    const [state, setState] = React.useState({
        customerList: [],
        empty: true,
        spinner: true        
    });

    const { customerList, empty, spinner } = state;

    const getTable = (row) => {
        setFullCustomerList(row);
        setState(prevState => ({
            ...prevState,
            customerList: row,
            empty: !(row?.content),
            spinner: row?.totalElements?.code > 0
        }));
    }

    const addTable = (newRow) => {
        setState(prevState => ({
            ...prevState,
            customerList: {
                ...prevState.customerList,
                content: [...prevState.customerList.content, newRow.object],
            },
            empty: !(newRow?.object),
            spinner: newRow?.code === 100
        }));
    }

    React.useEffect(() => {
        customerListData(1,10,getTable);
    },[]);

    const createCustomerList = (newCustomerList) => {
        setOpen(false);
        setState(prevData => ({
            ...prevData,
            empty: true,
            spinner:true
        }));
        customerListAddData(newCustomerList,addTable);
    };

    const modalProps = {
        t:t,
        open:open,
        setOpen:setOpen,
        mainEvent:createCustomerList
    };

    if(empty) {
        return <SpinnerMessageBasic isActive={spinner} typeSpinner={true} message={t("marca_table_typography")} />;
    }

    const handleSearch = (e) => {
        const value = e.target.value;
        setSearch(value);
        // Filtra los clientes basándose en el valor de búsqueda
        if (value) {
            const filteredCustomers = fullCustomerList.content.filter((customer) =>
                customer.name.toLowerCase().includes(value.toLowerCase())
            );
            setState((prevState) => ({
                ...prevState,
                customerList: { ...fullCustomerList, content: filteredCustomers },
            }));
        } else {
            // Si el campo de búsqueda está vacío, muestra la lista completa
            setState((prevState) => ({
                ...prevState,
                customerList: fullCustomerList,
            }));
        }
    };

    return(
        <Grid item xs={12} sm={12} md={12} lg={12} align={"right"}>
            <Button
                variant="contained"
                onClick={ e=> {setOpen(true)}}
                sx={{ backgroundColor: "#008AE8", '&:hover': { bgcolor: '#316FB5' }, mr:'5px' }}
            >
                {t("clientes_add_btn")}
            </Button>
            <TextField
                label={t("clientes_serch_textfield")}
                size='small'
                value={search}
                onChange={handleSearch}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
                disabled={customerList.totalElements <= 0}
            />
            <TCustomerList
                t={t}
                customerList={customerList}
                getTable={getTable}
            />
            {open && <ModalCreate {...modalProps} />}
        </Grid>
    );
}

export default SectionTable;