// React
import React, { useState } from 'react';
import { useHistory  } from 'react-router-dom';
import { styled,
    Drawer as MuiDrawer,
    Divider,
    Stack,
    Grid
} from '@mui/material';

// assets
import logo from '../../assets/logo_tracpeak.png';

// my proyect
import Navigation from './Navigation';

const Menu = ({ drawerWidth, open, handleMenu }) => {

    // lo que seria como estilos
    const openedMixin = (theme) => ({
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: 'hidden',
    });
    
    const closedMixin = (theme) => ({
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: `calc(${theme.spacing(7)} + 1px)`,
        [theme.breakpoints.up('sm')]: {
            width: `calc(${theme.spacing(8)} + 1px)`,
        },
    });
    
    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    }));
    
    const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
        ({ theme, open }) => ({
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',
            ...(open && {
                ...openedMixin(theme),
                '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
    );
    // fin - lo que seria como estilos
    
    // eventos
    const [isHovered, setIsHovered] = useState(false);
    
    const handleMouseEnter = () => {
        setIsHovered(true);
    };
    
    const handleMouseLeave = () => {
        setIsHovered(false);
    };
    
    // eventos de path
    const history = useHistory(); // cuidado es deprecated

    const handleImgClick = (e) => {
        history.push("/dashboard")
    }
    // fin - eventos de path
    // fin - eventos
    
    return (
        <Drawer variant="permanent" open={open}>
            <DrawerHeader>
                <Stack direction="row" spacing={1} >
                    <Grid 
                        container
                        spacing={0}
                        direction={"column"}
                        alignItems={"center"}
                        justifyContent={"center"}
                    >
                        <img
                            src={logo}
                            width="50%"
                            alt="Tracpeak"
                            onClick={handleImgClick}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            style={isHovered ? { cursor: 'pointer' } : {}}
                        />
                    </Grid>
                </Stack>
            </DrawerHeader>
            <Divider />
            <Navigation open={open} handleMenu={handleMenu} />
        </Drawer>
    );
}

export default Menu;