// assets
import { TeamOutlined } from '@ant-design/icons';

// icons
const icons = {
    TeamOutlined
};
// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const clientes = {
    id: 'clientes',
    title: 'menu_clientes_title_global',
    type: 'group',
    role: 'USER',
    children: [
        {
            id: 7,
            title: 'menu_clientes_title_clientes',
            type: 'item',
            url: '/clientes',
            icon: icons.TeamOutlined,
            breadcrumbs: false
        }
    ]
};

export default clientes;
