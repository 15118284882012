// columnas
export function getColumns( t ) {
    return [
        { id: 'plu', label: t("articulos_columns_plu"), minWidth: 40 },
        { id: 'styleno', label: t("articulos_columns_styleno"), minWidth: 90 },
        { id: 'description', label: t("articulos_columns_description"), minWidth: 90 },
        { id: 'attributes', label: t("articulos_columns_attributes"), minWidth: 90 },
        { id: 'brandname', label: t("articulos_columns_brandname"), minWidth: 90 },
        { id: 'season', label: t("articulos_columns_season"), minWidth: 40 },
        { id: 'department', label: t("articulos_columns_department"), minWidth: 90 },
        { id: 'clase', label: t("articulos_columns_clase"), minWidth: 90 },
        { id: 'subclass1', label: t("articulos_columns_subclass1"), minWidth: 90 },
        { id: 'subclass2', label: t("articulos_columns_subclass2"), minWidth: 90 },
        { id: 'relation', label: t("articulos_columns_relation"), minWidth: 90 },
        { id: 'related', label: t("articulos_columns_related"), minWidth: 90 },
        { id: 'related2', label: t("articulos_columns_related2"), minWidth: 90 },
    ];
}

// filas
export function createData(id, plu, styleno, description, attributes, brandname, season, department, clase, subclass1, subclass2, relation, related, related2) {
    return { id, plu, styleno, description, attributes, brandname, season, department, clase, subclass1, subclass2, relation, related, related2 };
}

export function createRow(items) {
    let rows = [];

    for(let item of items.content) {
        rows.push(
            createData(
                item.id,
                item.primaryPlu,
                item.primaryStyleno,
                item.primaryDescription,
                item.primaryAttributes,
                item.primaryBrandname,
                item.primarySeason,
                item.primaryDepartment,
                item.primaryClass,
                item.primarySubclass1,
                item.primarySubclass2,
                item.relationTypeDescription,
                item.relatedPlu,
                item.relatedDescription
            )
        );
    }  

    return rows;
}