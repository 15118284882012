// React
import React from "react";

// material ui
import { Grid, Typography } from "@mui/material";

// my proyect
import Filter from "../../../../../components/filter/Filter";
import TListCustomer from "../../table/TListCustomer";
import TListDiffusion from "../../table/TListDiffusion";

// css
import  { gridSection } from '../../style/StyleEditModel';

// service
import { customerFieldsData } from "../../DataCustomerList";

const Section = ({ t, data, setData }) => {
    const [dataFilter, setDataFilter] = React.useState(null);

    const cancelEvent = () => {
        setData(prevData => ({
            ...prevData,
            reset: true,
            select:false,
            vfilter: false,
            dataFilter: dataFilter?.object
        }));
    }

    React.useEffect(() => {
        customerFieldsData(setDataFilter);
    }, []);

    React.useEffect(() => {
        if (dataFilter) {
            setData((prevData) => ({
                ...prevData,
                dataFilter: dataFilter?.object,
            }));
        }
    }, [dataFilter, setData]);

    return (
        <Grid item xs={6} sx={gridSection}>
            <Typography variant="h5" align="center" sx={{ marginBottom: "3px" }}>
                {
                    data.type ?
                        t("filter_table_name_customer")
                        :
                        t("filter_table_name_my_list")
                }
            </Typography>
            <Filter
                t={t}
                data={data}
                setData={setData}
                cancelEvent={cancelEvent}
            />
            {
                data.type ?
                    <TListCustomer
                        t={t}
                        data={data}
                        setData={setData}
                    />
                :
                    <TListDiffusion
                        t={t}
                        data={data}
                        setData={setData}
                    />
            }
        </Grid>
    );
};

export default Section;