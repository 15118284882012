// react
import React from 'react';
import { format, startOfMonth, endOfMonth } from 'date-fns';

// material ui
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import { Grid, IconButton, TextField } from '@mui/material';

const CustomTextField = styled(TextField)(({ theme }) => ({
    "& .MuiInputBase-root": {
        color: "white",
    },
    "& .MuiInputBase-root:hover": {
        borderColor: "white",
    },
    "& .MuiSvgIcon-root": {
        color: "white",
    },
    "& input::-webkit-calendar-picker-indicator": {
        filter: "invert(100%)",
    },
}));

const DashBoardSearch = ({t, giveDates}) => {

    const now = new Date();
    const setIni = format(startOfMonth(now), 'yyyy-MM-dd');
    const setEnd = format(endOfMonth(now), 'yyyy-MM-dd');

    const [iniDate, setIniDate] = React.useState(setIni);
    const [endDate, setEndDate] = React.useState(setEnd);

    const handlDates = () => {
        giveDates(iniDate,endDate)
    }

    return(
        <Grid sx={{ width: { xs: '100%' } }}>
            <Grid container spacing={2} sx={{ marginLeft: '5px' }}>
                <Grid item xs={2} sm={2}>
                    <CustomTextField 
                        id="startDate"
                        size="small"
                        label={t("mainsearch_date_ini")}
                        type="date"
                        defaultValue={iniDate}
                        fullWidth
                        onChange={e => {setIniDate(e.target.value);}}
                        InputLabelProps={{
                        style: {
                            color: "white"
                        },
                        }}
                        InputProps={{
                        style: {
                            color: "white",
                            border: "1px solid white",
                            borderRadius: "4px",
                            fontSize: "10px"
                        },
                        }}
                        variant="filled"
                    />
                </Grid>
                <Grid item xs={2} sm={2}>
                    <CustomTextField 
                        id="endDate"
                        size="small"
                        label={t("mainsearch_date_end")}
                        type="date"
                        defaultValue={endDate}
                        fullWidth
                        onChange={e => {setEndDate(e.target.value);}}
                        InputLabelProps={{
                        style: {
                            color: "white"
                        },
                        }}
                        InputProps={{
                        style: {
                            color: "white",
                            border: "1px solid white",
                            borderRadius: "4px",
                            fontSize: "10px"
                        },
                        }}
                        variant="filled"
                    />
                </Grid>
                <Grid item xs={2} sm={2} >
                    <IconButton
                        sx={{ 
                        marginTop: '2px',
                        backgroundColor: "#008AE8",
                        color: "white",
                        border: "1px solid white",
                        borderRadius: 1,
                        '&:hover': { bgcolor: '#316FB5' }
                        }}
                        onClick={handlDates}
                    >
                        <SearchIcon />
                    </IconButton>
                </Grid>
            </Grid>
        </Grid>
    );

}

export default DashBoardSearch;