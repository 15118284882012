
const urlBase = window._env_.SERVER_URL;

const commonHeaders = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem('token')}`
};

export const makeFetchRequest = async (url, method, body = null, isFormData = false) => {
    const headers = isFormData ? 
        { 'Authorization': `Bearer ${localStorage.getItem('token')}` } : 
        commonHeaders;

    const options = {
        method,
        headers,
    };

    if (body) {
        options.body = isFormData ? body : JSON.stringify(body);
    }

    const response = await fetch(`${urlBase}${url}`, options);
    return await response.json();
};