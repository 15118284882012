// React
import React from 'react';

// material-ui
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, Button, Card, CardContent, CardHeader, FormControlLabel, FormGroup, FormLabel, Grid, Modal, Switch, TextField } from "@mui/material";

// otros
import { useTranslation } from "react-i18next";

// styles
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
};

const ModalMarca = ({ open, titulo, btnName, mainEvent, closeEvent, data }) => {

    const { t } = useTranslation();

    const [marca, setMarca] = React.useState(data.marca);

    const [chq, setChq] = React.useState(data.chq);
    
    const [cshq, setCshq] = React.useState(data.cshq);

    const [api, setApi] = React.useState(data.api);
    
    const [crm, setCrm] = React.useState(data.crm);

    const [vsn, setVsn] = React.useState(data.vsn);

    const list = [
        {id:"v4", name:"Version 4"},
        {id:"v6", name:"Version 6"}
    ];

    const [pak, setPak] = React.useState(data.pln);

    const [estado, setEstado] = React.useState(data.estado);
    
    const handleClose = (e) => {
        closeEvent(open);
        handleKeepIni();
    }

    const handleChange = (event) => {
        setPak(event.target.value);
    };

    const handleVersion = (event) => {
        setVsn(event.target.value);
    };
    
    const handleAdd = (e) => {
        mainEvent(
            {
                marca,
                chq,
                cshq,
                api,
                crm,
                vsn,
                pak,
                estado
            });

        if(!data.id) {
            handleKeepIni();            
        }
    }

    const handleKeepIni = (e) => {

        setMarca(data.marca);
    
        setChq(data.chq);
        
        setCshq(data.cshq);

        setApi(data.api);
        
        setCrm(data.crm);

        setVsn(data.vsn);
    
        setPak(data.pln);
    
        setEstado(data.estado);

    }

    return(
        <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <form onSubmit={handleAdd}>
                <Card sx={style}>
                    <CardHeader
                        title = {titulo}
                        sx={{ backgroundColor: "#008AE8", color: "white", textAlign: "center" }}
                    />
                    <CardContent>
                        <Grid container spacing={2} sx={{ marginTop: '5px' }}>
                            <Grid item xs={12} sm={4} md={4} lg={4}>
                                <TextField
                                    value={marca}
                                    onChange={ e => {setMarca(e.target.value);} }
                                    label={t("modalmarca_textfield_name")}
                                    fullWidth
                                    required
                                    onInvalid={e => e.target.setCustomValidity(t("modalmarca_textfield_name_required"))}
                                    onBlur={e => e.target.setCustomValidity("")}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={4}>
                                <TextField
                                    value={chq}
                                    onChange={ e => {setChq(e.target.value);} }
                                    label={t("modalmarca_textfield_chq")}
                                    fullWidth
                                    required
                                    onInvalid={e => e.target.setCustomValidity(t("modalmarca_textfield_chq_required"))}
                                    onBlur={e => e.target.setCustomValidity("")}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={4}>
                                <TextField
                                    value={api}
                                    onChange={ e => {setApi(e.target.value);} }
                                    label={t("modalmarca_textfield_api")}
                                    fullWidth
                                    required
                                    onInvalid={e => e.target.setCustomValidity(t("modalmarca_textfield_api_required"))}
                                    onBlur={e => e.target.setCustomValidity("")}
                                />
                            </Grid>
                            
                            <Grid item xs={12} sm={4} md={4} lg={4}>
                                <TextField
                                    value={cshq}
                                    onChange={ e => {setCshq(e.target.value);} }
                                    label={t("modalmarca_textfield_cshq")}
                                    fullWidth
                                    onInvalid={e => e.target.setCustomValidity(t("modalmarca_textfield_cshq_required"))}
                                    onBlur={e => e.target.setCustomValidity("")}
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} md={4} lg={4}>
                                
                                <FormControl sx={{ width: 240}}>
                                    <InputLabel size="small" htmlFor="simple-select-label">{t("modalmarca_textfield_vsn")}</InputLabel>
                                    <Select 
                                        size="small"
                                        id="simple-select"
                                        label={t("modalmarca_textfield_vsn")}
                                        value={vsn || ""}
                                        onChange={handleVersion}
                                    >
                                            <MenuItem key={0} value={0}>
                                                <em>{t("modalmarca_main_select")}</em>
                                            </MenuItem>
                                            {
                                                list && list.map((version, index) => (
                                                    <MenuItem key={index} value={version.id}>{version.name}</MenuItem>
                                                )) 
                                            }
                                    </Select>
                                    <FormHelperText>{t("modalmarca_main_select")}</FormHelperText>
                                </FormControl>

                            </ Grid>
                            
                            <Grid item xs={12} sm={4} md={4} lg={4}>
                                <TextField
                                    value={crm}
                                    onChange={ e => {setCrm(e.target.value);} }
                                    label={t("modalmarca_textfield_crm")}
                                    fullWidth
                                    onInvalid={e => e.target.setCustomValidity(t("modalmarca_textfield_crm_required"))}
                                    onBlur={e => e.target.setCustomValidity("")}
                                />
                            </Grid>
                            
                            <Grid item xs={12} sm={4} md={4} lg={4}>
                            
                                <FormControl sx={{ width: 240}}>
                                    <InputLabel size="small" htmlFor="simple-select-label">{t("modalmarca_textfield_pk")}</InputLabel>
                                    <Select
                                        id="simple-select"
                                        label={t("modalmarca_textfield_pk")}
                                        value={pak || ""}
                                        onChange={handleChange}
                                        required
                                    >
                                            <MenuItem key={0} value={0}>
                                                <em>{t("modalmarca_main_menuitem")}</em>
                                            </MenuItem>
                                            {
                                                data.packageList && data.packageList.map((packag, index) => (
                                                    <MenuItem key={index} value={packag.id}>{packag.name + ' (Eventos: ' + packag.events + ')' }</MenuItem>
                                                )) 
                                            }
                                    </Select>
                                    <FormHelperText>{t("modalmarca_textfield_pk_label")}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={4} >
                                <FormControl component="fieldset">
                                <FormLabel component="legend">{t("modalmarca_textfield_estado")}</FormLabel>
                                <FormGroup>
                                    <FormControlLabel
                                    control={<Switch checked={estado} onChange={e => {setEstado(e.target.checked);} } />}
                                    label={ estado ? t("chipestado_true") : t("chipestado_false") }
                                    />
                                </FormGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                        
                        <Grid container spacing={2} sx={{ marginTop: '5px' }} justifyContent="flex-end">
                            <Grid item xs={12} sm={2} md={2} lg={2}>
                                    <Button 
                                        type="submit"
                                        variant="contained"
                                        sx={{ backgroundColor: "#52c41a", '&:hover': { bgcolor: '#237804' } }}
                                    >
                                        {btnName}
                                    </Button>
                            </Grid>
                            <Grid item xs={12} sm={2} md={2} lg={2}>
                                    <Button 
                                        variant="contained"
                                        sx={{ backgroundColor: "#bd5259", '&:hover': { bgcolor: '#a8071a' } }}
                                        onClick={ handleClose }
                                    >
                                        {t("modalmarca_cancel_btn")}
                                    </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </form>

        </Modal>
    );
}

export default ModalMarca;