// material-ui
import Edit from '@mui/icons-material/Edit';

// my proyect
import ButtonTableRow from "../../../components/button/ButtonTableRow";
import ChipEstado from "../../../components/chip/ChipEstado";
import ModalEdit from './modal/edit/ModalEdit';
import { t } from 'i18next';

// columns
export function columns(t) {
    return [
        { id: 'name', label: t("difusion_columns_name"), minWidth: 40 },
        { id: 'type', label: t("difusion_columns_type"), minWidth: 40 },
        { id: 'events', label: t("difusion_columns_event"), minWidth: 40 },
        { id: 'email', label: t("difusion_columns_email_2"), minWidth: 40 },
        { id: 'push', label: t("difusion_columns_pasesUpdate"), minWidth: 40 },
        // { id: 'pasesAdd', label: t("difusion_columns_sms"), minWidth: 40 },
        { id: 'pasesAdd', label: t("difusion_columns_passes"), minWidth: 40 },
        { id: 'cGenerado', label: t("difusion_columns_cGenerado"), minWidth: 40 },
        { id: 'cRedimidos', label: t("difusion_columns_cRedimidos"), minWidth: 40 },
        { id: 'mGenerado', label: t("difusion_columns_cCreados"), minWidth: 40 },
        { id: 'mRedimidos', label: t("difusion_columns_mRedimidos"), minWidth: 40 },
        { id: 'estatus', label: t("difusion_columns_estatus"), minWidth: 40 },
        { id: 'acciones', label: t("difusion_columns_acciones"), minWidth: 40 },
    ];
}

// filas
function chipEstado(estado) {
    return <ChipEstado color={estado ? "success" : "error"} label={estado ? "chipestado_true" : "chipestado_false"} />;
}

function modalDifusion({ openModal, mainEvent, closeEvent, data }) {
    return <ModalEdit
        open={openModal}
        titulo={localStorage.getItem('i18nextLng') === 'en' ? "Edit Diffusion" : "Editar Difusíon"}
        btnName={localStorage.getItem('i18nextLng') === 'en' ? "Edit" : "Editar"}
        mainEvent={mainEvent}
        closeEvent={closeEvent}
        data={data}
    />;
}

function buttonTableRow(data, eventEditar) {
    return <ButtonTableRow
        icon={<Edit />}
        bgColor={"#008AE8"}
        iconColor={"white"}
        colorHover={"#316FB5"}
        data={data}
        modal={modalDifusion}
        mainEvent={eventEditar}
    />;
}

export function createData(id, name, type, eventos, email, push, pasesAdd, pasesUpdate, cGenerado,
    cRedimidos, mGenerado, mRedimidos, estatus, listCustomerId, cuponProgramId, valueCoupon, dateOf,
    dateAt, dateSend, typeCoupon, listCustomerName, designName, passHeader, general, additional, dayReminder1,
    dateSendReminder1, titleReminder1, contentReminder1, dayReminder2, dateSendReminder2, titleReminder2,
    contentReminder2,
    eventEditar) {
    return {
        id,
        name,
        type: t(type),
        events: eventos,
        email,
        push,
        pasesAdd,
        pasesUpdate,
        cGenerado,
        cRedimidos,
        mGenerado,
        mRedimidos,
        estatus: chipEstado(estatus),
        listCustomerId,
        cuponProgramId,
        valueCoupon,
        dateOf,
        dateAt,
        dateSend,
        typeCoupon,
        listCustomerName,
        designName,
        passHeader,
        general,
        additional,
        dayReminder1,
        dateSendReminder1,
        titleReminder1,
        contentReminder1,
        dayReminder2,
        dateSendReminder2,
        titleReminder2,
        contentReminder2,
        acciones: buttonTableRow({
            id, name, type, email, pasesAdd, pasesUpdate, cGenerado, cRedimidos, estatus, listCustomerId,
            cuponProgramId, valueCoupon, dateOf, dateAt, dateSend, typeCoupon, listCustomerName, designName,
            passHeader, general, additional, dayReminder1, dateSendReminder1, titleReminder1, contentReminder1,
            dayReminder2, dateSendReminder2, titleReminder2, contentReminder2,
        }, eventEditar)
    };
}

export function createRow(items, eventEditar) {
    let rows = [];

    for (let item of items.content) {
        rows.push(
            createData(
                item.id,
                item.name,
                item.typeCampaign,
                (item.eventos).toLocaleString("en-US"),
                (item.email).toLocaleString("en-US"),
                (item.push).toLocaleString("en-US"),
                (item.addedPass).toLocaleString("en-US"),
                (item.updatedPass).toLocaleString("en-US"),
                (item.couponsGenerated).toLocaleString("en-US"),
                (item.couponsRedeemed).toLocaleString("en-US"),
                "$ " + (item.couponsAmountCreated).toLocaleString("en-US"),
                "$ " + (item.couponsAmountRedeemed).toLocaleString("en-US"),
                item.isEnable,
                item.listCustomerId,
                item.cuponProgramId,
                "$ " + (item.valueCoupon ).toLocaleString("en-US"),
                item.dateOf,
                item.dateAt,
                item.dateSend,
                item.typeCoupon,
                item.listCustomerName,
                item.designName,
                item.passHeader,
                item.general,
                item.additional,
                item.dayReminder1,
                item.dateSendReminder1,
                item.titleReminder1,
                item.contentReminder1,
                item.dayReminder2,
                item.dateSendReminder2,
                item.titleReminder2,
                item.contentReminder2,
                eventEditar
            )
        );
    }

    return rows;
}