import { Grid, Typography } from "@mui/material";
import { formattedDigital } from "../../../../utils/formatted/FormattedNumber";
import {CardItemDashboard} from "../../../../components/card/CardItemDashboard";

export const CouponCard = ({ t, cupones }) => {

    const { totalGenerated, totalActive, totalExpired, totalRedeemed } = cupones;
    return (
        <>
            <Typography
                variant="h5"
                sx={{ fontWeight: 'bold', marginTop: '20px', marginBottom: '5px' }}
            >
                {t("dashboard_coupons_name")}
            </Typography>
            <Grid item xs={12} sm={12} md={12} ld={12} marginBottom={3.5}>
                <Grid container rowSpacing={0.5} columnSpacing={2.5}>
                    <CardItemDashboard t={t} value={totalGenerated} title="dashboard_coupons_generated" formattedDigital={formattedDigital} />
                    <CardItemDashboard t={t} value={totalActive} title="dashboard_coupons_current" formattedDigital={formattedDigital} />
                    <CardItemDashboard t={t} value={totalExpired} title="dashboard_coupons_noredeemed" formattedDigital={formattedDigital} />
                    <CardItemDashboard t={t} value={totalRedeemed} title="dashboard_coupons_redeemed" formattedDigital={formattedDigital} />
                </Grid>
            </Grid></>
    );
};