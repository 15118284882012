import React from "react";
import { Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import SpinnerMessageBasic from "../../../../components/spinner/SpinnerMessageBasic";
import CountUp from "react-countup";
import { priceNumber } from "../../../../utils/formatted/FormattedNumber";
import VerticalBarWidget from "../../../../components/widgets/VerticalBarWidget";
import { faker } from '@faker-js/faker';

const EventBar = ({ t }) => {
    const [empty, setEmpty] = React.useState(false);

    if (empty) {
        return <SpinnerMessageBasic />;
    }

    // test
    const eventsNumber = 10499;

    const labels = [t("event_bar_data_actual"), t("event_bar_data_additional")];
    
    const data = {
        labels,
        datasets: [
            {
                label: t("event_bar_dataset_1"),
                data: labels.map(() => faker.number.int({ min: 9999, max: 10000 })),
                backgroundColor: 'rgba(91, 14, 45, 0.5)',
            },
            {
                label: t("event_bar_dataset_2"),
                data: labels.map(() => faker.number.int({ min: 0, max: 1000 })),
                backgroundColor: 'rgba(255, 167, 129, 0.5)',
            },
        ],
    };

    return (
        <Grid item xs={12} sm={12} md={12} lg={12}>
            <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <CardHeader
                    title={<Typography variant="h5">{t("dashboard_pases_cinco")}</Typography>}
                    subheader={
                        <Typography variant="h4" fontWeight="bold">
                            <CountUp end={eventsNumber}  />
                        </Typography>
                    }
                />
                <CardContent sx={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'flex-end' }}>
                    <VerticalBarWidget data={data} />
                </CardContent>
            </Card>
        </Grid>
    );
};

export default EventBar;