// React
import React, { useState, useEffect } from "react";

// material ui
import { Grid } from "@mui/material";

// my proyect
import SpinnerMessageBasic from "../../../../components/spinner/SpinnerMessageBasic";
import TFilter from "../../../../components/table/TFilter";

// service
import { listCustomerData, listCustomerFilterData } from "../DataCustomerList";

// utils
import { getColumnsCheck, createRowCheck} from "./TableUtils";

const TCustomer = ({ t, data, setData }) => {
    const [state, setState] = useState({
        spinner: true,
        empty: true,
        filtro:false,
        lCustomer: [],
        numberData: {
            elements:0,
            rango:10
        }
    });

    const { spinner, empty, filtro, lCustomer, numberData } = state;

    const fetchData = (page, size, callback, filter = null) => {
        if (data.filter) {
            listCustomerFilterData(page, size, filter, callback);
        } else {
            listCustomerData(page, size, callback);
        }
    };

    const handleCustomerData = (data) => {
        setState(prevState => ({
            ...prevState,
            numberData: {
                elements:data.totalElements,
                rango:data.size
            },
            lCustomer: data,
            empty: !(data?.content),
            spinner: data?.totalElements <= 0
        }));
        setData((prevData) => ({
            ...prevData,
            totalLista: data.totalElements,
        }));
        allSelect(data);
    };

    const handleAddCustomerData = (data) => {
        setState(prevState => ({
            ...prevState,
            lCustomer: {
                ...prevState.lCustomer,
                content: [...prevState.lCustomer.content, ...data.content],
            },
            empty: !(data?.content),
            spinner: data?.totalElements <= 0
        }));
        setData((prevData) => ({
            ...prevData,
            totalLista: data.totalElements,
        }));
        allSelect(data);
    };

    const editList = (check,id) => {
        if (check) {
            setData((prevData) => ({
                ...prevData,
                lista: [...prevData.lista, id],
            }));
        } else {
            setData((prevData) => ({
                ...prevData,
                lista: prevData.lista.filter((prevId) => prevId !== id)
            }));
        }
        allSelect(data);
    }

    const allSelect = (newFilas) => {
        if(data.select) {
            const ids = newFilas.content.map(fila => fila.id);
            setData(prevData => ({
                ...prevData,
                lista: [...prevData.lista, ...ids]
            }));
        }
    }

    useEffect(() => {
        if (data.reset) {
            fetchData(1, 100, handleCustomerData);
        }

        if (data.filter) {
            fetchData(1, 100, handleCustomerData, data.dataFilter);
        }
    }, [data.reset, data.filter]);

    if(empty) {
        return <SpinnerMessageBasic isActive={spinner} typeSpinner={true} message={t("marca_table_typography")} />;
    }

    // event check
    const checkboxEvent = (e) => {
        const stateCheck = e.target.checked;
        if(stateCheck) {
            const ids = lCustomer.content.map(fila => fila.id);
            setData(prevData => ({
                ...prevData,
                select: stateCheck,
                lista: ids
            }));
        } else {
            setData(prevData => ({
                ...prevData,
                select: stateCheck,
                lista: []
            }));
        }
    }

    const changeTable = (page, size, typeChange) => {
        if(typeChange) {
            fetchData(page, size, handleAddCustomerData, data.filter ? data.dataFilter : undefined);
        } else {
            fetchData(page, size, handleCustomerData, data.filter ? data.dataFilter : undefined);
        }
    };

    const setReset = (status) => {
        setData(prevData => ({
            ...prevData,
            reset: status,
            validaF: false
        }));
        if(filtro) {
            setState(prevState => ({
                ...prevState,
                spinner: true,
                filtro: false
            }));
        }
    }

    const setTableFilter = (status) => {
        setData(prevState => ({
            ...prevState,
            filter: status,
            validaF: true
        }));
        setState(prevState => ({
            ...prevState,
            filtro: true
        }));
    }

    const isSpinner = (status) => {
        setState(prevState => ({
            ...prevState,
            spinner: status
        }));
    }

    return(
        <Grid item sx={{ margin: "5px" }}>
            <TFilter
                traductor={t}
                columns={getColumnsCheck(t,checkboxEvent,data.select)}
                createRow={createRowCheck(lCustomer,data.lista,editList)}
                eventTable={changeTable}
                numberData={numberData}
                reset={data.reset}
                isReset={setReset}
                filter={data.filter}
                isFilter={setTableFilter}
                spinner={spinner}
                isSpinner={isSpinner}
            />
        </Grid>
    );

}

export default TCustomer;