import { createServiceHandler } from "../../../utils/request/DataUtils";

import { customerFieldsService, listCustomerService, listCustomerFilterService } from "../../../services/customer/CustomerService";
import { customerListAddService, customerListService, customerListNoDiffusionService,
    customerListNoDiffusionFilterService, customerListInDiffusionService, customerListInDiffusionFilterService,
    customerListEditService } from "../../../services/campanas/customerlist/CustomerListService";

export const customerFieldsData = createServiceHandler(customerFieldsService);
export const listCustomerData = createServiceHandler(listCustomerService);
export const listCustomerFilterData = createServiceHandler(listCustomerFilterService);
export const customerListAddData = createServiceHandler(customerListAddService);
export const customerListData = createServiceHandler(customerListService);
export const customerListNoDiffusionData = createServiceHandler(customerListNoDiffusionService);
export const customerListNoDiffusionFilterData = createServiceHandler(customerListNoDiffusionFilterService);
export const customerListInDiffusionData = createServiceHandler(customerListInDiffusionService);
export const customerListInDiffusionFilterData = createServiceHandler(customerListInDiffusionFilterService);
export const customerListEditData = createServiceHandler(customerListEditService);