// assets
import { AlertOutlined } from '@ant-design/icons';

// icons
const icons = {
    AlertOutlined
};
// ==============================|| MENU ITEMS - SEGURIDAD ||============================== //

const seguridad = {
    id: 'seguridad',
    title: 'menu_seguridad_title_global',
    type: 'group',
    role: 'PREVENCION',
    children: [
        {
            id: 3,
            title: 'menu_seguridad_title_prevencion',
            type: 'item',
            url: '/prevencion',
            icon: icons.AlertOutlined,
            breadcrumbs: false
        }
    ]
};

export default seguridad;
