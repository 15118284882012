import React from 'react';
import { FormControl, FormControlLabel, FormGroup, FormLabel, Grid, InputLabel, MenuItem, Select, Switch } from "@mui/material";

const Options = ({t, data, setData}) => {
    const handleSwitchChange = (key, value) => {
        setData(prevState => ({
            ...prevState,
            dataFilter: {
                ...prevState.dataFilter,
                [key]: {
                    ...prevState.dataFilter[key],
                    value: value ? "true" : "",
                },
            }
        }));
    };
    
    const handleSelectChange = (key, value) => {
        setData(prevState => ({
            ...prevState,
            dataFilter: {
                ...prevState.dataFilter,
                [key]: {
                    ...prevState.dataFilter[key],
                    value: value,
                },
            }
        }));
    };

    const renderCustomFlags = () => {
        return Object.keys(data.dataFilter).map((key) => {
            if (key.startsWith('customFlag')) {
                return (
                    <Grid item xs={3} sx={{ padding: "5px"}} key={data.dataFilter[key].label}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">{data.dataFilter[key].label}</FormLabel>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={data.dataFilter[key].value !== ""}
                                            onChange={e => handleSwitchChange(key, e.target.checked)}
                                        />
                                    }
                                    label={ data.dataFilter[key].value === "" ? t("chipestado_false") : t("chipestado_true") }
                                />
                            </FormGroup>
                        </FormControl>
                    </Grid>
                );
            }
            return null;
        });
    };

    const renderCustomLookups = () => {
        return Object.keys(data.dataFilter).map((key) => {
            if (key.startsWith('customLookup')) {
                return (
                    <Grid item xs={3} sx={{ padding: "5px"}} key={data.dataFilter[key].label}>
                        <FormControl fullWidth>
                            <InputLabel size="small">{data.dataFilter[key].label}</InputLabel>
                            <Select 
                                size="small"
                                label={data.dataFilter[key].label}
                                value={data.dataFilter[key].value || ''}
                                onChange={e => handleSelectChange(key, e.target.value)}
                            >
                                {data.dataFilter[key].lcustomerGroups.map((item, i) => (
                                    <MenuItem key={i} value={item.lookUpValues}>
                                    {item.lookUpAlias}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                );
            }
            return null;
        });
    };

    return(
        <Grid container direction="row" justifyContent="space-between">
            {renderCustomFlags()}
            {renderCustomLookups()}
        </Grid>
    )
}

export default Options;
