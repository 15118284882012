// my service
import { getBrandList } from '../../services/marcas/MarcasService';
import { getUserListByBrandId, getNewUser, getUpdatedUser } from '../../services/user/UsuarioService';

// getAllBrands
export const brandList = async ( setList ) => {
    try {
        const response = await getBrandList(localStorage.getItem('token'), 1, 100);
        setList(response.content);
    } catch (error) {
        console.error(error);
    }
};

// getAllUsers by id brand
export const userList = async ( idBrand, setUserList, nameBrand ) => {
    try {
        const response = await getUserListByBrandId(idBrand, localStorage.getItem('token'));
        const userListWithBrandName = response.content.map((user) => {
          return {
            ...user,
            marca: nameBrand
          };
        });
        setUserList(userListWithBrandName);
    } catch (error) {
        console.error(error);
    }
}

// add user
export const addUser = async ( usuario, setFilas, filas, MySwal, t ) => {
    try {
        const response = await getNewUser(localStorage.getItem("token"),usuario);
        if(response.code === 100) {
            setFilas([...filas, response.object]);
            MySwal.fire({
                title: '<span style="font-weight: normal; font-family: Lato, sans-serif;">'+t("datausuario_adduser_success_title")+'</span>',
                html: '<span style="font-style: normal; font-family: Lato, sans-serif;">'+t("datausuario_adduser_success_message")+'</span>',
                icon: 'success',
                confirmButtonColor: '#008ae8'
            });
        } else {
            MySwal.fire({
                title: '<span style="font-weight: normal; font-family: Lato, sans-serif;">'+t("datausuario_adduser_error_title")+'</span>',
                html: '<span style="font-style: normal; font-family: Lato, sans-serif;">'+t("datausuario_adduser_error_message")+'</span>',
                icon: 'error',
                confirmButtonColor: '#008ae8'
            });
        }
    } catch(error) {
        console.error(error);
        MySwal.fire({
            title: '<span style="font-weight: normal; font-family: Lato, sans-serif;">'+t("datausuario_adduser_error_title")+'</span>',
            html: '<span style="font-style: normal; font-family: Lato, sans-serif;">'+t("datausuario_adduser_error_message")+'</span>',
            icon: 'error',
            confirmButtonColor: '#008ae8'
        });
    }
}

// edit user
export const updateUser = async ( updateUser, MySwal, t, filas, setOriginalFilas, setFilas ) => {
    try {
        const response = await getUpdatedUser(localStorage.getItem("token"),updateUser);
        if(response.code === 100) {
            const updatedFilas = filas.map((fila) =>
                fila.id === response.object.id ? 
                    { 
                        ...fila, 
                        ...{
                            firstName : response.object.firstName,
                            lastName  : response.object.lastName,
                            email     : response.object.email,
                            password  : response.object.password,
                            roleUser  : response.object.roles,
                            isEnable  : response.object.isEnable
                        }
                    } 
                : fila
            );

            setOriginalFilas(updatedFilas);
            
            setFilas(updatedFilas);

            MySwal.fire({
                title: '<span style="font-weight: normal; font-family: Lato, sans-serif;">'+t("datausuario_updateuser_success_title")+'</span>',
                html: '<span style="font-style: normal; font-family: Lato, sans-serif;">'+t("datausuario_updateuser_success_message")+'</span>',
                icon: 'success',
                confirmButtonColor: '#008ae8'
            });
        } else {
            MySwal.fire({
                title: '<span style="font-weight: normal; font-family: Lato, sans-serif;">'+t("datausuario_updateuser_error_title")+'</span>',
                html: '<span style="font-style: normal; font-family: Lato, sans-serif;">'+t("datausuario_updateuser_error_message")+'</span>',
                icon: 'error',
                confirmButtonColor: '#008ae8'
            });
        }
    } catch(error) {
        console.error(error);
        MySwal.fire({
            title: '<span style="font-weight: normal; font-family: Lato, sans-serif;">'+t("datausuario_updateuser_error_title")+'</span>',
            html: '<span style="font-style: normal; font-family: Lato, sans-serif;">'+t("datausuario_updateuser_error_message")+'</span>',
            icon: 'error',
            confirmButtonColor: '#008ae8'
        });
    }
}