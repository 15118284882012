// react
import React from "react";

// material ui
import { Box, Card, CardContent, Grid } from "@mui/material";

// my proyect
import ColorPicker from "../../../../components/select-color/ColorPicker";

const DesignColor = ({ t,colorLabel,setColorLabel,colorForeground,setColorForeground,colorBackground,setColorBackground }) => {

    return(
        <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box display="flex" justifyContent="center">
                <Box display="inline-flex">
                    <Card>
                        <CardContent>
                            <Grid container justifyContent="flex-end" spacing={2}>
                                <ColorPicker
                                    label={t("color_label")}
                                    color={ colorLabel }
                                    setColor={ setColorLabel }
                                />
                                <ColorPicker
                                    label={t("color_foreground")}
                                    color={ colorForeground }
                                    setColor={ setColorForeground }
                                />
                                <ColorPicker
                                    label={t("color_background")}
                                    color={ colorBackground }
                                    setColor={ setColorBackground }
                                />
                            </Grid>
                        </CardContent>
                    </Card>
                </Box>
            </Box>
        </Grid>
    )
}

export default DesignColor;
