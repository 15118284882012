// React
import React from 'react';

// materila ui
import { Grid } from '@mui/material';
import DesignPasse from './passe/DesignPasse';
import DesignForm from './form/DesignForm';

// otros
import { useTranslation } from "react-i18next";
import iniLogo from "../../../assets/passes/logoo.png";
import iniImage from "../../../assets/passes/imagen-strip.jpeg";

// data
import { designAddData } from "./DataDesign"
import { showMessage } from '../../../utils/messages/Message';

const Design = ({setPestana}) => {

    const { t } = useTranslation();

    const [colorLabel, setColorLabel] = React.useState({
        r: '241',
        g: '112',
        b: '19',
        a: '1',
    });

    const [colorForeground, setColorForeground] = React.useState({
        r: '142',
        g: '68',
        b: '173',
        a: '1',
    });

    const [colorBackground, setColorBackground] = React.useState({
        r: '44',
        g: '62',
        b: '80',
        a: '1',
    });

    const rgbaToRgb = (color) => {
        return `rgb(${color.r}, ${color.g}, ${color.b})`;
    };
    
    const rgbaToHex = (color) => {
        let r = parseInt(color.r, 10).toString(16),
            g = parseInt(color.g, 10).toString(16),
            b = parseInt(color.b, 10).toString(16);
    
        r = r.length === 1 ? "0" + r : r;
        g = g.length === 1 ? "0" + g : g;
        b = b.length === 1 ? "0" + b : b;
    
        return `#${r}${g}${b}`;
    };

    const [logo, setLogo] = React.useState(iniLogo);
    
    const [image, setImage] = React.useState(iniImage);

    const getResponse = (data) => {
        setPestana(5);
        if(data?.code === 100)
            showMessage({title: t("design_title_success"), message: t("design_message_success"), type: 'success'});
        else
            showMessage({title: t("design_title_error"), message: t("design_message_error"), type: 'error'});
    }

    const eventAddDesign = (design) => {
        let data = {
            name: design.name,
            colorLabel: `${rgbaToRgb(colorLabel)}|${rgbaToHex(colorLabel)}`,
            colorForeground: `${rgbaToRgb(colorForeground)}|${rgbaToHex(colorForeground)}`,
            colorBackground: `${rgbaToRgb(colorBackground)}|${rgbaToHex(colorBackground)}`
        }

        const formData = new FormData();
        formData.append("req", JSON.stringify(data));
        formData.append("logo", design.logo);
        formData.append("image", design.image);

        designAddData(formData,getResponse);
    }

    return(
        <Grid container rowSpacing={0.5} columnSpacing={1.5}>
            <DesignPasse 
                t={t}
                colorLabel={colorLabel}
                setColorLabel={setColorLabel}
                colorForeground={colorForeground}
                setColorForeground={setColorForeground}
                colorBackground={colorBackground}
                setColorBackground={setColorBackground}
                logo={logo}
                image={image}
            />
            <DesignForm 
                t={t}
                setLogo={setLogo}
                setImage={setImage}
                eventAddDesign={eventAddDesign}
            />
        </Grid>
    );
}

export default Design;