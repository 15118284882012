// React
import React from 'react';

// material-ui
import { IconButton } from "@mui/material";

const ButtonTableRow = ({ icon, bgColor, iconColor, colorHover, data, modal, mainEvent }) => {
    // modal
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleMain = (marca) => {
        handleClose();
        mainEvent(marca);
    }

    return(
        <>
            {
                open ? 
                    modal({openModal:open, mainEvent:handleMain, closeEvent:handleClose, data:data })
                :
                    <></>
            }
            
            <IconButton
                sx={{ 
                    backgroundColor: bgColor,
                    color: iconColor,
                    borderRadius: 1,
                    '&:hover': { bgcolor: colorHover },
                    marginRight: '5px'
                }}
                onClick={handleOpen}
            >
                {icon}
            </IconButton>
        </>
    );
}

export default ButtonTableRow;