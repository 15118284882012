// url base
const urlBase = window._env_.SERVER_URL;

// get - user list by id brand
export async function getUserListByBrandId( brandId, token ) {
    const response = await fetch(`${urlBase}/user/${brandId}/list?page=${1}&size=${100}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    })
    const responseJson = await response.json()
    return responseJson
}

// post - user add
export async function getNewUser( token, newUser ) {
    const response = await fetch(`${urlBase}/user`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(newUser)
    })
    const responseJson = await response.json()
    return responseJson
}

// post - user edit
export async function getUpdatedUser( token, updateUser )  {
    const response = await fetch(`${urlBase}/user/${updateUser.id}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(updateUser)
    })
    const responseJson = await response.json()
    return responseJson
}