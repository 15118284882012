import { Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import { styleEGraphCard, styleEGraphContent, styleEGraphHeader } from "../../style/StyleDash";
import LineWidget from "../../../../components/widgets/LineWidget";
import { getDynamicMonths, datasets } from "./EGraphUtils";
import React from "react";
import CountUp from 'react-countup';
import { formattedNumber } from "../../../../utils/formatted/FormattedNumber";

const EventGraph = ({ t, graph }) => {

    // const labels = getDynamicMonths(getReport?.ddashboardReport);
    //
    // const data = {
    //     labels,
    //     datasets: datasets(getReport?.ddashboardReport, t),
    // };

    return (
        <>
            {/* <Typography
                variant="h5"
                sx={{ fontWeight: 'bold', marginTop: '20px', marginBottom: '5px' }}
            >
                {t("dashboard_coupons_grafica")}
            </Typography> */}
            <Grid item xs={12} sm={12} md={12} lg={12} marginBottom={2.5}>
                <Card >
                    {/* <CardHeader
                    title={<Typography variant="h5">{t("difusion_columns_email")}</Typography>}
                    subheader={
                        <Typography variant="h4" fontWeight="bold">
                            <CountUp
                                end={getReport?.totalEvents}
                                formattingFn={formattedNumber}
                            />
                        </Typography>
                    }
                    sx={styleEGraphHeader}
                /> */}
                    <CardContent >
                        <LineWidget
                            t={t} graph={graph} title={`${t("dashboard_coupons_grafica")}`}
                            axisY={`${t("dashboard_coupons_table_y_1")}`}
                            axisY2={`${t("dashboard_coupons_table_y_2")}`}
                            text1={`${t("dashboard_coupons_table_text_1")}`}
                            text2={`${t("dashboard_coupons_table_text_2")}`}
                        />
                    </CardContent>
                </Card>
            </Grid>
        </>
    );
};

export default EventGraph;