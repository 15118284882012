import { Grid } from "@mui/material";
import React from "react";
import Filter from "../../components/filter/Filter";
import { customerFieldsData } from "./DataCustomer";
import TableLCustomer from "./table/TableLCustomer";
import { gridFilter } from "./style/Style";

const Section = ({ t, data, setData }) => {
    const [dataFilter, setDataFilter] = React.useState(null);

    React.useEffect(() => {
        customerFieldsData(setDataFilter);
    }, []);

    React.useEffect(() => {
        if (dataFilter) {
            setData((prevData) => ({
                ...prevData,
                dataFilter: dataFilter?.object,
            }));
        }
    }, [dataFilter, setData]);

    const cancelEvent = () => {
        setData((prevData) => ({
            ...prevData,
            reset: true,
            dataFilter: dataFilter?.object,
        }));
    };
    const syncCustomers = () => {

    }

    return (
        <>
            <Grid item xs={12} sx={gridFilter}>
                <Filter
                    t={t}
                    data={data}
                    setData={setData}
                    cancelEvent={cancelEvent}
                />
            </Grid>
            <TableLCustomer
                t={t}
                data={data}
                setData={setData}
            />
        </>
    );
};

export default Section;
