// React
import React, { Component } from "react";

// my proyect
import Routes from "./routes/Routes";

// material-ui
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontSize: 12,
    fontFamily: 'Lato, sans-serif',
  },
  palette: {
    primary: {
      main: '#556cd6', // Color principal
      hard: '#010a1f',
    },
    secondary: {
      main: '#19857b', // Color secundario
    },
    error: {
      main: '#f44336', // Color para mensajes de error (danger)
    },
    warning: {
      main: '#ff9800', // Color para mensajes de advertencia
    },
    info: {
      main: '#2196f3', // Color para mensajes informativos
    },
    success: {
      main: '#2e7d32', // Color para mensajes de éxito
    },
  }
});

class App extends Component {

  state = {
    sesion: localStorage.getItem('sesion') || false
  };

  sesionFuncion = (sesion) => {
    this.setState({ sesion });
    localStorage.setItem('sesion', sesion);
  };

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Routes sesionFuncion={this.sesionFuncion} sesion={this.state.sesion} />
      </ThemeProvider>
    );
  };

}

export default App;