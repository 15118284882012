
const urlBase = window._env_.SERVER_URL;


// get - user list
export async function getAllUser( token ) {
    const response = await fetch(`${urlBase}/user/list?page=${1}&size=${100}`,{
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    })
    const responseJson = await response.json()
    return responseJson
}