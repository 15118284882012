export const modalBasic = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: "#f5f5f5"
};

export const modalW520 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 520,
    background: "#f5f5f5"
};

export const gridMain = {
    marginTop: "5px",
    minHeight: 'calc(100vh - 165px)',
    maxHeight: 'calc(100vh - 165px)',
    overflowY: "auto",
    "&::-webkit-scrollbar": {
        width: "0px",
        height: "0px",
    },
    "&::-webkit-scrollbar-track": {
        background: "##f5f5f5",
    },
    "&::-webkit-scrollbar-thumb": {
        background: "#888",
        borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
        background: "#555",
    },
}