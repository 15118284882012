export const optionsPie = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        y: {
            display: false,
        },
    },
    plugins: {
        legend: {
            position: 'top',
            display: false,
        },
        title: {
            display: false,
            text: 'Chart.js Line Chart',
        },
        tooltip: {
            enabled: false,
            external: function(context) {
                const tooltip = context.tooltip;
        
                if (tooltip.opacity === 0) {
                const tooltipEl = document.getElementById('chartjs-tooltip');
                if (tooltipEl) {
                    tooltipEl.style.opacity = '0';
                }
                return;
                }
        
                const {chart} = context;
                const position = chart.canvas.getBoundingClientRect();
                const left = position.left + window.pageXOffset + tooltip.caretX;
                const top = position.top + window.pageYOffset + tooltip.caretY - tooltip.height - 10;
        
                let tooltipEl = document.getElementById('chartjs-tooltip');
                if (!tooltipEl) {
                    tooltipEl = document.createElement('div');
                    tooltipEl.id = 'chartjs-tooltip';
                    tooltipEl.style.position = 'absolute';
                    tooltipEl.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
                    tooltipEl.style.color = '#fff';
                    tooltipEl.style.borderRadius = '6px';
                    tooltipEl.style.padding = '6px';
                    tooltipEl.style.pointerEvents = 'none';
                    tooltipEl.style.zIndex = '9999';
                    document.body.appendChild(tooltipEl);
                }
        
                let innerHtml = tooltip.title.map(t => `<div>${t}</div>`).join('');
                    tooltip.dataPoints.forEach((bodyItem) => {
                    const backgroundColor = chart.data.datasets[bodyItem.datasetIndex].backgroundColor[bodyItem.dataIndex];
                    innerHtml += `<div style="display:flex; align-items:center; margin-right:5px;">
                                        <div style="background:#ffffff; width:10px; height:10px; display:flex; align-items:center; justify-content:center; margin-right:5px;">
                                            <span style="background:${backgroundColor}; width:10px; height:10px;"></span>
                                        </div>
                                        ${chart.data.datasets[bodyItem.datasetIndex].label}: ${bodyItem.formattedValue}
                                    </div>`;
                });
                tooltipEl.innerHTML = innerHtml;
                tooltipEl.style.left = left + 'px';
                tooltipEl.style.top = top + 'px';
                tooltipEl.style.opacity = '1';
            },
        }
    },
};