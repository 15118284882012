import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import SectionTable from "./SectionTable";

const EmailBrand = () => {
    const { t } = useTranslation();
    return(
        <Box>
            <Typography
                variant="h5"
                sx={{ fontWeight: 'bold', marginTop: '20px' }}
            >
                {t("menu_servicio_title_email_marcas")}
            </Typography>
            <SectionTable t={t} />
        </Box>
    );
};

export default EmailBrand;