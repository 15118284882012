// react
import React from "react";

// material ui
import { Button, Card, CardContent, Grid, InputAdornment, TextField } from "@mui/material";

// icons
import SearchIcon from '@mui/icons-material/Search';

// otros
import { useTranslation } from "react-i18next";

// my proyect
import SpinnerMessageBasic from "../../../components/spinner/SpinnerMessageBasic";

// service
import { listArticle, listArticleSynchronous, listArticleByText } from './DataArticulos';

// table utils
import { createRow, getColumns } from './TableArticulosUtils';
import TableEvent from "../../../components/table/TableEvent";

const Articulo = () => {
    // traductor
    const { t } = useTranslation();
    const [mensaje, setMensaje] = React.useState(t("spinnerarticulo_msj"));

    // control
    const [spinner, setSpinner] = React.useState(true);
    const [isEmpty, setIsEmpty] = React.useState(true);

    // tabla
    const [filas, setFilas] = React.useState([]);
    const [originalFilas, setOriginalFilas] = React.useState([]);

    // serch
    const [search, setSearch] = React.useState("");
    const [isFilter, setIsFilter] = React.useState(false);

    //methods
    React.useEffect(() => {
        listArticle( updateFilas,1,10 );
    }, [])

    const updateFilas = (newFilas) => {
        setFilas(newFilas);
        setOriginalFilas(newFilas);
        setIsEmpty(newFilas.content.length === 0);
        setSpinner(false);
    }

    const addFilas = (newFilas) => {
        setIsEmpty(true);
        setSpinner(true);
        setFilas(filas => {
            return {
                ...filas,
                content: [...filas.content, ...newFilas.content]
            }
        });
        setOriginalFilas(filas);
        setIsEmpty(newFilas.content.length === 0);
        setSpinner(false);
    }

    const synchronousFilas = () => {
        setIsEmpty(true);
        setSpinner(true);
        setMensaje(t("spinnerarticulo_msj_syn"));
        listArticleSynchronous(updateFilas);
    }

    const changeTable = (page,size,typeChange) => {
        setSpinner(true);

        if(isFilter) {
            searchFilas(addFilas,search,page,size);
        }
        else if(typeChange) {
            listArticle( addFilas,page,size );
        } else {
            listArticle( updateFilas,page,size );
        }
    }

    const searchFilas = (functionList,text,page,size) => {
        listArticleByText(functionList,text,page,size);
    }

    const searchArticle = (searchTxt) => {
        setSpinner(true);
        setIsFilter(true);
        setSearch(searchTxt);

        if(searchTxt.length === 0) {
            setIsFilter(false);
            listArticle( updateFilas,1,10 );
        } else {
            searchFilas(updateFilas,searchTxt,1,10);
        }
    }

    const searchList = (search) => {
        setSearch(search);
    
        if (search.length === 0) {
            setFilas(originalFilas);
        } else {
            const lowerCaseSearch = search.toLowerCase();
    
            const updatedFilas = originalFilas.content.filter(fila => {
                return (
                    fila.invenStyleRelatedId?.toLowerCase().includes(lowerCaseSearch) ||
                    fila.relationTypeDescription?.toLowerCase().includes(lowerCaseSearch) ||
                    fila.primaryStyleId?.toLowerCase().includes(lowerCaseSearch) ||
                    fila.primaryItemid?.toLowerCase().includes(lowerCaseSearch) ||
                    String(fila.primaryPlu).includes(lowerCaseSearch) ||
                    fila.primaryStyleno?.toLowerCase().includes(lowerCaseSearch) ||
                    fila.primaryDescription?.toLowerCase().includes(lowerCaseSearch) ||
                    fila.primaryAttributes?.toLowerCase().includes(lowerCaseSearch) ||
                    fila.primaryBrandname?.toLowerCase().includes(lowerCaseSearch) ||
                    fila.primarySeason?.toLowerCase().includes(lowerCaseSearch) ||
                    fila.primaryDepartment?.toLowerCase().includes(lowerCaseSearch) ||
                    fila.primaryClass?.toLowerCase().includes(lowerCaseSearch) ||
                    fila.primarySubclass1?.toLowerCase().includes(lowerCaseSearch) ||
                    fila.primarySubclass2?.toLowerCase().includes(lowerCaseSearch) ||
                    fila.relatedStyleid?.toLowerCase().includes(lowerCaseSearch) ||
                    fila.relatedItemid?.toLowerCase().includes(lowerCaseSearch) ||
                    String(fila.relatedPlu).includes(lowerCaseSearch) ||
                    fila.relatedDescription?.toLowerCase().includes(lowerCaseSearch) ||
                    String(fila.relationKind).includes(lowerCaseSearch)
                )
            });
            
            setFilas(filas => {
                return {
                    ...filas,
                    content: [...updatedFilas],
                    totalElements: updatedFilas.length
                }
            });
        }
    }

    return(
        <Card>
            <CardContent>
                <Grid container rowSpacing={4.5} columnSpacing={2.75} marginBottom={2.5}>
                    <Grid item xs={12} sm={12} md={12} lg={12} align={"right"} >
                        <Button 
                            variant="contained"
                            sx={{ textTransform :"none", backgroundColor: "#008AE8", '&:hover': { bgcolor: '#316FB5' }, mr:'5px' }}
                            disabled={spinner}
                            onClick={synchronousFilas}
                        >
                            {t("articulos_main_btn")}
                        </Button>
                        <TextField
                            value={search}
                            size="small"
                            onChange={ e => {searchArticle(e.target.value);} }
                            label={t("articulos_main_search")}
                            InputProps={{
                                startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                                ),
                            }}
                            disabled={isEmpty}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} >
                        {
                            isEmpty ? 
                                (
                                    <SpinnerMessageBasic 
                                        isActive={spinner}
                                        message={mensaje} 
                                    />
                                )
                            :
                                (
                                    <TableEvent 
                                        columns={getColumns(t)}
                                        createRow={createRow(filas)}
                                        filas={filas}
                                        eventTable={changeTable}
                                        heigth={"100%"}
                                        alignRow={"center"}
                                        traductor={t}
                                        message={t("spinnerarticulo_msj_syn")}
                                        spinner={false}
                                    />
                                )
                        }
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

export default Articulo;