// React
import React from 'react';

// material-ui
import { Typography, Tabs, Tab, Grid,Card } from '@mui/material';

// otros
import { useTranslation } from "react-i18next";

// my proyect
import Tablero from './tablero/Tablero';
import CustomerList from './customerlist/CustomerList';
import Articulo from './articulos/Articulos';
import Difusion from './difusion/Difusion';
import Locacion from './locaciones/Locaciones';
import Design from './design/Design';

// css
import { styleEGraphCard, styleEGraphContent, styleEGraphHeader, styleTittle } from "../dashboard/style/StyleDash";

const TabPanel = ({ value, index, children }) => (
    <div hidden={value !== index}>
        {value === index && <Grid sx={{ p: 3 }}>{children}</Grid>}
    </div>
);

const Campana = () => {

    const { t } = useTranslation();

    const [pestana, setPestana] = React.useState(0);

    const [title, setTitle] = React.useState("campana_tab_clientes");
    const tabTitles = [
        "campana_tab_clientes",
        "campana_tab_articulos",
        "campana_tab_location",
        "campana_tab_design",
        "campana_tab_difusion"
    ];

    const handlePestana = (event, newPestana) => {
        setPestana(newPestana);
        setTitle(tabTitles[newPestana]);
    };

    return (
        <>
            <Card sx={styleTittle}>
                <Typography
                    variant="h4"
                    sx={{ fontWeight: 'bold', marginTop: '20px', marginBottom: '5px' }}
                >
                    {`${t("campana_main_title")} / ${t(title)}`}
                </Typography>
            </Card>
            <Tabs value={pestana} onChange={handlePestana}>
                <Tab sx={{ textTransform: "none" }} label={t("campana_tab_clientes")} />
                <Tab sx={{ textTransform: "none" }} label={t("campana_tab_articulos")} />
                <Tab sx={{ textTransform: "none" }} label={t("campana_tab_location")} />
                <Tab sx={{ textTransform: "none" }} label={t("campana_tab_design")} />
                <Tab sx={{ textTransform: "none" }} label={t("campana_tab_difusion")} />
            </Tabs>

            {/*<TabPanel value={pestana} index={0} key={0} >*/}
            {/*    <Tablero />*/}
            {/*</TabPanel>*/}

            <TabPanel value={pestana} index={0} key={1}>
                <CustomerList t={t} />
            </TabPanel>

            <TabPanel value={pestana} index={1} key={2}>
                <Articulo />
            </TabPanel>

            <TabPanel value={pestana} index={2} key={3}>
                <Locacion />
            </TabPanel>

            <TabPanel value={pestana} index={3} key={4}>
                <Design setPestana={setPestana} />
            </TabPanel>

            <TabPanel value={pestana} index={4} key={5}>
                <Difusion />
            </TabPanel>
        </>
    );

}

export default Campana;