import { Grid, Typography } from "@mui/material";
import { formattedDigital } from "../../../../utils/formatted/FormattedNumber";
import { CardItemDashboard } from "../../../../components/card/CardItemDashboard";

export const EventsCard = ({ t, eventos }) => {
    const { totalPlan, totalAvailable, totalPlus, totalUsed } = eventos;
    const size = 6;
    return (
        <>
            <Typography
                variant="h5"
                sx={{ fontWeight: 'bold', marginTop: '20px', marginBottom: '5px' }}
            >
                {t("event_card_name")}
            </Typography>

            <Grid item xs={12} sm={12} md={12} lg={12} marginBottom={2.5}>
                <Grid container columnSpacing={2.5} rowSpacing={2.}>
                    {/*<CardItemDashboard t={t} value={totalPlan} title="event_card_mont" formattedDigital={formattedDigital} />*/}
                    <CardItemDashboard t={t} value={totalUsed} title="event_card_used" formattedDigital={formattedDigital} size={size}/>
                    {/*<CardItemDashboard t={t} value={totalPlus} title="event_card_additional_available" formattedDigital={formattedDigital} />*/}
                    <CardItemDashboard t={t} value={totalAvailable} title="event_card_available" formattedDigital={formattedDigital} size={size}/>
                </Grid>
            </Grid>
        </>
    );
};