import React from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import { Button, Popover, List, ListItem, ListSubheader} from "@mui/material";

const languageMap = {
  en: { label: "English", dir: "ltr", active: true },
  es: { label: "Español", dir: "ltr", active: false }
};

const LanguageSelect = () => {
    let selected = localStorage.getItem("i18nextLng") || "en";
    const { t } = useTranslation();
  
    const [menuAnchor, setMenuAnchor] = React.useState(null);
    
    React.useEffect(() => {
      if (languageMap[selected.slice(0,2)]) {
        document.body.dir = languageMap[selected.slice(0,2)].dir;
      }
    }, [menuAnchor, selected]);
  
    return (
      <div className="d-flex justify-content-end align-items-center language-select-root">
        <Button onClick={({ currentTarget }) => setMenuAnchor(currentTarget)}>
          {languageMap[selected.slice(0,2)]?.label}
          <ArrowDropDown fontSize="small" />
        </Button>
        <Popover
          open={!!menuAnchor}
          anchorEl={menuAnchor}
          onClose={() => setMenuAnchor(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
        >
          <div>
            <List>
              <ListSubheader>{t("select_language")}</ListSubheader>
              {Object.keys(languageMap)?.map((item) => (
                <ListItem
                  style={{ fontFamily: 'Lato, sans-serif' }}
                  button
                  key={item}
                  onClick={() => {
                    i18next.changeLanguage(item);
                    setMenuAnchor(null);
                  }}
                >
                  {languageMap[item]?.label}
                </ListItem>
              ))}
            </List>
          </div>
        </Popover>
      </div>
    );
  };

export default LanguageSelect;
