const formatCurrency = (amount) => `$ ${amount.toLocaleString("en-US")}`;
const formatNumber = (amount) => `${amount.toLocaleString("en-US")}`;

export function getColumns(t) {
    return [
        { id: 'name', label: t("difusion_columns_name"), minWidth: 50 },
        { id: 'eventos', label: t("difusion_columns_event"), minWidth: 50 },
        { id: 'cGenerado', label: t("difusion_columns_cGenerado"), minWidth: 50 },
        { id: 'cRedimidos', label: t("difusion_columns_cRedimidos"), minWidth: 50 },
        { id: 'mGenerado', label: t("difusion_columns_mGenerado"), minWidth: 50 },
        { id: 'mRedimidos', label: t("difusion_columns_mRedimidos"), minWidth: 50 },
    ];
}

export function createRow(items) {
    return items.map(item => ({
        id: item.name,
        name: item.name,
        type: item.typeDiffusion,
        eventos: formatNumber(item.noEvents),
        pasesAdd: formatNumber(item.noAmountCreated),
        pasesUpdate: formatNumber(item.noAmountCreated),
        cGenerado: formatNumber(item.noCupones),
        cRedimidos: formatNumber(item.noRedeemed),
        mGenerado: formatCurrency(item.noAmountCreated),
        mRedimidos: formatCurrency(item.noAmountCreated),
    }));
}
