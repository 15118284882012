// react
import React from 'react'

// material ui
import { InputAdornment, TextField } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const TextPassword = ({ isHidden, setIsHidden, txtPassword, setTxtPassword, labelName, labelError, onBlur }) => {
    return(
        <TextField
            type={ isHidden ? "password" : "text" }
            size="small"
            label={ labelName }
            value={ txtPassword }
            onChange={ e => { setTxtPassword( e.target.value ); } }
            onInvalid={e => e.target.setCustomValidity( labelError )}
            onBlur={ onBlur }
            fullWidth
            required
            InputProps={{
                endAdornment: (
                    <InputAdornment
                        position="end"
                        style={{ cursor: "pointer" }}
                        onClick={ e => { setIsHidden( !isHidden ) }}
                    >
                        {
                            isHidden ?
                                <Visibility
                                    sx={{ 
                                        color: "#316FB5",
                                        ":hover": { color: "#4C88C9" } 
                                    }}
                                />
                            :
                                <VisibilityOff
                                    sx={{ 
                                        color: "#316FB5",
                                        ":hover": { color: "#4C88C9" } 
                                    }}
                                />
                        }
                    </InputAdornment>
                ),
                style: {
                    fontWeight: "bold"
                }
            }}
        />
    );
}

export default TextPassword