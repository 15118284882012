// react
import React, { useEffect } from 'react';

// material-ui
import { Button, Card, CardContent, Grid, InputAdornment, TextField, Typography } from "@mui/material";

// icons
import SearchIcon from '@mui/icons-material/Search';

// otros
import { useTranslation } from "react-i18next";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

// my proyect
import ModalAddUser from './ModalAddUser';
import TablaBasica from '../../components/table/TablaBasica';

// service
import { addUser, updateUser } from '../usuarios/DataUsuario';
import { userList } from './DataAdmin';

// table utils
import { getColumns, createRow } from './TableAdminUtils';

const Admin = () => {

    useEffect(() => {
        userList(setOriginalFilas,setFilas);
    }, []);

    const MySwal = withReactContent(Swal);

    const { t } = useTranslation();

    // modal
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleAdd = (user) => {
        addUser(user, setFilas, filas, MySwal, t);
    }

    // serch
    const [serch, setSerch] = React.useState("");

    const serchList = (serch) => {
        setSerch(serch);

        if(serch.length === 0) {
            setFilas(originalFilas);
        } else {
            let updatedFilas = filas.filter(fila => fila.firstName.includes(serch));

            if(updatedFilas.length === 0) {
                updatedFilas = originalFilas.filter(fila => fila.firstName.includes(serch));
            }

            setFilas(updatedFilas);
        }
    }

    // table
    const [originalFilas, setOriginalFilas] = React.useState([]);
    const [filas, setFilas] = React.useState([]);

    const rowsChanges = (usuario) => {
        updateUser({
            id        : usuario.id,
            firstName : usuario.firstName,
            lastName  : usuario.lastName,
            email     : usuario.email,
            password  : null,
            roles     : [],
            isEnable  : usuario.isEnable,
            brandId   : null
        }, MySwal, t, originalFilas, setOriginalFilas, setFilas);
    }

    const newFilas = createRow(filas, rowsChanges);

    return(
        <>
            <Typography variant="h5" sx={{ fontWeight: 'bold' }} >{t("admin_main_title")}</Typography>

            <ModalAddUser
                open={open}
                titulo={t("admin_modal_title")}
                btnName={t("admin_modal_main_btn")}
                mainEvent={handleAdd}
                closeEvent={handleClose}
                data={{ email:"", estado:false }}
            />

            <Card>
                <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={2.75} align={"right"} >
                        <Grid item xs={12} sm={12} md={12} lg={12} >
                            <Button 
                                variant="contained"
                                sx={{ backgroundColor: "#008AE8", '&:hover': { bgcolor: '#316FB5' }, mr:'5px' }}
                                onClick={ handleOpen }
                            >
                                {t("admin_add_btn")}
                            </Button>
                            <TextField
                                size="small"
                                value={serch}
                                onChange={ e => {serchList(e.target.value);} }
                                label={t("admin_serch_textfield")}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <SearchIcon />
                                    </InputAdornment>
                                  ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} >
                            {
                                newFilas.length ?
                                    <TablaBasica heigth={"100%"} alignRow={"center"} columns={getColumns(t)} rows={newFilas} ></TablaBasica>
                                :
                                    <Typography align="center" variant="h4">{t("marca_table_typography")}</Typography>
                            }
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    );
}

export default Admin;