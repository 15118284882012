// react
import React from "react";

// material ui
import { Button, Grid, Typography, Popover } from "@mui/material";

// other
import { SketchPicker } from 'react-color';

const ColorPicker = ({label, color, setColor}) => {

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleChangeColor = (color) => {
        setColor(color.rgb);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const getColor = (color) => {
        const { r, g, b, a } = color;
        return `rgba(${r},${g},${b},${a})`;
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return(
        <Grid item xs={4} sm={4} md={4} lg={4} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="subtitle1">{label}</Typography>
            <Button 
                onClick={handleClick}
                style={{
                    backgroundColor: getColor(color),
                    width: "100%",
                    height: "30px",
                    boxShadow: "3px 3px 5px rgba(0,0,0,0.3)",
                    border: "1px solid #333"
                }} 
            />
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <SketchPicker
                    color={ color }
                    onChange={ handleChangeColor }
                />
            </Popover>
        </Grid>
    );

}

export default ColorPicker;