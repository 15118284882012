// react
import React from "react";

// material ui
import { Grid } from "@mui/material";

// my proyect
import TEvent from "../../../../components/table/TEvent";
import { showMessage } from "../../../../utils/messages/Message";

// data
import { customerListData, customerListEditData } from "../DataCustomerList";

// utils
import { getColumns, createRow} from "./TableUtils";

const TCustomerList = ({ t, customerList, getTable}) => {
    const [state,setState] = React.useState({
        reset: false,
        spinner: false,
        numberData: {
            elements: customerList?.totalElements,
            rango: customerList?.size
        }
    });

    const changeTable = (page, size) => {
        customerListData(page, size, getTable);
        setState(preState => ({
            ...preState,
            spinner: false
        }));
    };

    const responseEdit = (data) => {
        if(data.code === 100)
            showMessage({title: t("modalcliente_update_success_title"), message: t("modalcliente_update_success_message"), type: 'success'});
        else
            showMessage({title: t("modalcliente_update_error_title"), message: t("modalcliente_update_error_message"), type: 'error'});
        setState(preState => ({
            ...preState,
            spinner: false
        }));
    }
    
    const editList = (listcustomerData) => {
        setState(preState => ({
            ...preState,
            spinner: true
        }));
        customerListEditData(listcustomerData.customer.data.id,listcustomerData,responseEdit);
        
    };

    React.useEffect(() => {
        setState((preState) => ({
            ...preState,
            spinner: customerList?.content?.length === 0,
        }));
    }, [customerList.content]);

    return(
        <Grid item sx={{ margin: "5px" }}>
            <TEvent
                traductor={t}
                columns={getColumns(t)}
                createRow={createRow(customerList,editList)}
                eventTable={changeTable}
                state={state}
                setState={setState}
            />
        </Grid>
    );
}

export default TCustomerList;