// React
import React from 'react';

// material-ui
import { Box, Grid, Typography,Card } from "@mui/material";

// otros
import { useTranslation } from "react-i18next";

// my proyect
import Section from './Section';

// css
import { styleEGraphCard, styleEGraphContent, styleEGraphHeader, styleTittle } from "../dashboard/style/StyleDash";

const tableCustomer = {
    reset: true,
    filter: false,
    dataFilter: []
};

const Customer = () => {
    const { t } = useTranslation();
    const [data, setData] = React.useState(tableCustomer);
    return (
        <Box>
            <Card sx={styleTittle}>
                <Typography
                    variant="h4"
                    sx={{ fontWeight: 'bold', marginTop: '20px', marginBottom: '5px' }}
                >
                    {t("cliente_main_title")}
                </Typography>
            </Card>
            <Grid container rowSpacing={1} columnSpacing={0.75} marginBottom={2.5}>
                <Section t={t} data={data} setData={setData} />
            </Grid>
        </Box>
    );
}

export default Customer;