// React
import React from 'react';
import { useHistory  } from 'react-router-dom'; // cuidado es deprecated con la v5 funciona hay que actualizar a la v6

// material-ui
import { Button, Card, CardContent, CardHeader, Grid, TextField, Typography } from '@mui/material';

// assets
import logo from '../../assets/logo_tracpeak.png';

// otros
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useTranslation } from "react-i18next";

// my service
import { forgot } from '../../services/login/LoginService';

const Forgot = () => {

    // styles
    const styleCard = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '400px',
        background: 'rgba(49,111,181,.4)',
        boxSizing: 'border-box',
        boxShadow: '0 15px 25px rgba(49,111,181,.6)',
        borderRadius: '10px',
    };

    const backGroundMain = {
        position: 'absolute',
        opacity: '0.3',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '500px',
        height: 'auto',
        pointerEvents: 'none',
    };

    const logInLink = {
        color: '#316FB5',
        textDecoration: 'underline',
        cursor: 'pointer',
        marginTop: '10px',
        display: 'inline-block',
        fontFamily: 'Lato, sans-serif',
        fontSize: '16px',
        ':hover': {
          color: '#4C88C9'
        }
    };
    // fin styles

    const { t } = useTranslation();

    const history = useHistory(); // cuidado es deprecated

    const MySwal = withReactContent(Swal);

    const [ email, setEmail ] = React.useState("");

    const handleRecover = async (e) => {
        e.preventDefault();

        // hay que agregar una imagen login o algo asi q se ve mal
        const response = await forgot({ email });

        if(response.code === 100) {
            MySwal.fire({
                title:'<span style="font-weight: normal;">'+t("fp_sw_title_success")+'</span>',
                html: '<span style="font-style: normal;">'+t("fp_sw_html_success")+'</span>',
                icon: 'success',
                showConfirmButton: false,
                customClass: {
                    content: {
                      fontFamily: 'Lato, sans-serif' // Establecer la fuente Lato en el objeto de estilo
                    }
                },
                timer: 3000,
                didClose: () => { history.push('/'); }
            });
        } else {
            MySwal.fire({
                title:'<span style="font-weight: normal;">'+t("fp_sw_title_error")+'</span>',
                html: '<span style="font-style: normal;">'+t("fp_sw_html_error")+'</span>',
                icon: 'error',
                showConfirmButton: false,
                customClass: {
                    content: {
                      fontFamily: 'Lato, sans-serif' // Establecer la fuente Lato en el objeto de estilo
                    }
                },
                timer: 3000,
                didClose: () => { history.push('/'); }
            });
        }
    };

    return(
        <form onSubmit={ handleRecover } >
            <img src={logo} style={backGroundMain} alt="logo" />
            <Card sx={ styleCard } >
                <CardHeader
                    title={
                        <Typography variant="h5" component="div" fontWeight="bold">
                            { t("fp_main_title") }
                        </Typography>
                    }
                />
                <CardContent>
                    <Grid container rowSpacing={2.75} columnSpacing={2.75} align={"center"} >
                        <Grid item xs={12} sm={12} md={12} lg={12} >
                            <TextField
                                type="email"
                                size="small"
                                label={ t("fp_textfield_email") }
                                value={ email }
                                onChange={ e => { setEmail(e.target.value); } }
                                onInvalid={e => e.target.setCustomValidity(t("fp_textfield_email_required"))}
                                onBlur={e => e.target.setCustomValidity("")}
                                fullWidth
                                required
                                InputProps={{
                                  style: {
                                    fontWeight: "bold"
                                  }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} >
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{ backgroundColor: "#316FB5", '&:hover': { bgcolor: '#4C88C9' } }}
                                fullWidth
                            >
                                { t("fp_btn_recover") }
                            </Button>
                        </Grid>
                        <Grid  item xs={12} sm={12} md={12} lg={12} >
                            <p
                                style={ logInLink }
                                onClick={() => {
                                    history.push('/');
                                }}
                            >
                                { t("fp_link_login") }
                            </p>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </form>
    );

};

export default Forgot;