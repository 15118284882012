import { Box, Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import PieWidget from "../../../components/widgets/PieWidget";

const Efectivo2 = ({altura}) => {
    return (
        <Grid item xs={12} sm={12} md={2} lg={2} sx={{ display: 'flex', flexDirection: 'column', height: altura }}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <CardHeader
                title={
                    <Typography variant="h6"># Tickets con Reembolso de Efectivo</Typography>
                }
                subheader={
                    <Typography variant="h4">100,000,000</Typography>
                }
                sx={{ height: '30%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
            />
            <CardContent sx={{ height: '49%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <PieWidget />
            </CardContent>
            </Card>
        </Grid>
    );
};

export default Efectivo2;