// React
import React, { useState, useEffect } from "react";

// material ui
import { Grid } from "@mui/material";

// my project
import SpinnerMessageBasic from "../../../components/spinner/SpinnerMessageBasic";
import TFilter from "../../../components/table/TFilter";

// service
import { listCustomerData, listCustomerFilterData } from "../DataCustomer";

// utils
import { createRow, getColumns } from "./TableUtils";

// css
import { gridTable } from "../style/Style";

const TableLCustomer = ({ t, data, setData }) => {
    const [state, setState] = useState({
        spinner: true,
        empty: true,
        filtro:false,
        lCustomer: [],
        numberData: {
            elements:0,
            rango:10
        }
    });

    const { spinner, empty, filtro, lCustomer, numberData } = state;

    const fetchData = (page, size, callback, filter = null) => {
        if (data.filter) {
            listCustomerFilterData(page, size, filter, callback);
        } else {
            listCustomerData(page, size, callback);
        }
    };

    const handleCustomerData = (data) => {
        setState(prevState => ({
            ...prevState,
            numberData: {
                elements:data.totalElements,
                rango:data.size
            },
            lCustomer: data,
            empty: data?.content ? false : true,
            spinner: data?.totalElements > 0 ? false : true
        }));
    };

    const handleAddCustomerData = (data) => {
        setState(prevState => ({
            ...prevState,
            lCustomer: {
                ...prevState.lCustomer,
                content: [...prevState.lCustomer.content, ...data.content],
            },
            empty: data?.content ? false : true,
            spinner: data?.totalElements > 0 ? false : true
        }));
    };

    useEffect(() => {
        if (data.reset) {
            fetchData(1, 100, handleCustomerData);
        }

        if (data.filter) {
            fetchData(1, 100, handleCustomerData, data.dataFilter);
        }
    }, [data.reset, data.filter]);

    if (empty) {
        return <SpinnerMessageBasic isActive={spinner} typeSpinner={true} message={t("marca_table_typography")} />;
    }

    const changeTable = (page, size, typeChange) => {
        if(typeChange) {
            fetchData(page, size, handleAddCustomerData, data.filter ? data.dataFilter : undefined);
        } else {
            fetchData(page, size, handleCustomerData, data.filter ? data.dataFilter : undefined);
        }
    };

    const setReset = (status) => {
        setData(prevData => ({
            ...prevData,
            reset: status
        }));
        if(filtro) {
            setState(prevState => ({
                ...prevState,
                spinner: true,
                filtro: false
            }));
        }
    }

    const setTableFilter = (status) => {
        setData(prevState => ({
            ...prevState,
            filter: status
        }));
        setState(prevState => ({
            ...prevState,
            filtro: true
        }));
    }

    const isSpinner = (status) => {
        console.log(status)
        setState(prevState => ({
            ...prevState,
            spinner: status
        }));
    }

    return (
        <Grid item xs={12} sx={gridTable} marginBottom={5.5}>
            <TFilter
                traductor={t}
                columns={getColumns(t)}
                createRow={createRow(lCustomer)}
                eventTable={changeTable}
                numberData={numberData}
                reset={data.reset}
                isReset={setReset}
                filter={data.filter}
                isFilter={setTableFilter}
                spinner={spinner}
                isSpinner={isSpinner}
            />
        </Grid>
    );
};

export default TableLCustomer;