// maerila ui
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";

// utils
import { genderItems, birthdayItems } from "./utils/LabelsForms";

const General = ({t,data,setData}) => {
    return(
        <Grid container direction="row" justifyContent="space-between">
            <Grid item xs={3} sx={{ padding: "5px"}}>
                <TextField
                    label={t("modallistacliente_title_filter_email")}
                    size="small"
                    fullWidth
                    value={data.dataFilter.email}
                    onChange={e =>
                        setData(prevState => ({
                            ...prevState,
                            dataFilter: {
                                ...prevState.dataFilter,
                                email: e.target.value
                            }
                        }))
                    }
                />
            </Grid>

            <Grid item xs={3} sx={{ padding: "5px"}}>
                <TextField
                    label={t("modallistacliente_title_filter_name")}
                    size="small"
                    fullWidth
                    value={data.dataFilter.name}
                    onChange={e =>
                        setData(prevState => ({
                            ...prevState,
                            dataFilter: {
                                ...prevState.dataFilter,
                                name: e.target.value
                            }
                        }))
                    }
                />
            </Grid>

            <Grid item xs={3} sx={{ padding: "5px"}}>
                <FormControl fullWidth>
                    <InputLabel size="small">{t("modallistacliente_title_filter_gender")}</InputLabel>
                    <Select
                        size="small"
                        label={t("modallistacliente_title_filter_gender")}
                        value={data.dataFilter.gener}
                        onChange={e =>
                            setData(prevState => ({
                                ...prevState,
                                dataFilter: {
                                    ...prevState.dataFilter,
                                    gener: e.target.value
                                }
                            }))
                        }
                    >
                        {genderItems(t).map(item =>
                            <MenuItem key={item.key} value={item.value}>
                                <em>{item.label}</em>
                            </MenuItem>
                        )}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={3} sx={{ padding: "5px"}}>
                <FormControl fullWidth>
                    <InputLabel size="small">{t("modallistacliente_title_filter_birthday")}</InputLabel>
                    <Select
                        size="small"
                        label={t("modallistacliente_title_filter_birthday")}
                        value={data.dataFilter.bmonth}
                        onChange={e =>
                            setData(prevState => ({
                                ...prevState,
                                dataFilter: {
                                    ...prevState.dataFilter,
                                    bmonth: e.target.value
                                }
                            }))
                        }
                    >
                        {birthdayItems(t).map(item =>
                            <MenuItem key={item.key} value={item.value}>
                                <em>{item.label}</em>
                            </MenuItem>
                        )}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );
}

export default General;