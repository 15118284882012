// my service
import { getAllUser } from '../../services/admin/AdminService';

// getAllUsers by id brand
export const userList = async ( setOriginalUserList, setUserList ) => {
    try {
        const response = await getAllUser(localStorage.getItem('token'));
        const userList = response.content.map((user) => {
          return {
            ...user
          };
        });
        setOriginalUserList(userList);
        setUserList(userList);
    } catch (error) {
        console.error(error);
    }
}