// material-ui
import Edit from '@mui/icons-material/Edit';

// my proyect
import ButtonTableRow from "../../components/button/ButtonTableRow";
import ChipEstado from "../../components/chip/ChipEstado";
import ModalAdmin from './ModalAdmin';

export function getColumns( t ) {
    return [
        { id: 'firstname', label: t("admin_columns_firstname"), minWidth: 90 },
        { id: 'lastname', label: t("admin_columns_lastname"), minWidth: 90 },
        { id: 'roles', label: t("admin_columns_roles"), minWidth: 90 },
        { id: 'email', label: t("admin_columns_email"), minWidth: 90 },
        { id: 'estado', label: t("admin_columns_status"), minWidth: 90 },
        { id: 'acciones', label: t("admin_columns_acciones"), minWidth: 90 },
    ];
}

function chipEstado(estado) {
    return <ChipEstado color={ estado ? "success" : "error" } label={ estado ? "chipestado_true" : "chipestado_false" } />;
}

function modalAdmin({openModal, mainEvent, closeEvent, data}) {
    return <ModalAdmin 
        open={openModal}
        titulo={localStorage.getItem('i18nextLng') === 'en' ? "Edit User" : "Editar Usuario"}
        btnName={localStorage.getItem('i18nextLng') === 'en' ? "Edit" : "Editar"}
        mainEvent={mainEvent}
        closeEvent={closeEvent}
        data={data}
        typeModal={false}
    />;
}

function buttonTableRow(data, eventEditar) {
    return <ButtonTableRow 
        icon={<Edit />}
        bgColor={"#008AE8"}
        iconColor={"white"}
        colorHover={"#316FB5"}
        data={data}
        modal={modalAdmin}
        mainEvent={eventEditar}
    />;
}

function rolesFormat(rolesFull) {
    const role = rolesFull.filter(role => role.name !== 'USER');

    if(localStorage.getItem('i18nextLng') === 'en') {
        const rolesEdit = role.map(rol => (
            {
                value : rol.id, 
                label : rol.name === "USER" ? "USER" :
                        rol.name === "PREVENCION" ? "PREVENTION" : 
                        rol.name === "CAMPANAS" ? "CAMPAINGN" :
                        rol.name === "ADMIN" ? "ADMIN" : "ROOT"
            }
        ));

        return rolesEdit;
    } else {
        const rolesEdit = role.map(rol => (
            {
                value : rol.id, 
                label : rol.name === "USER" ? "USUARIO" :
                        rol.name === "PREVENCION" ? "PREVENCIÓN" : 
                        rol.name === "CAMPANAS" ? "CAMPAÑAS" :
                        rol.name === "ADMIN" ? "ADMIN" : "ROOT"
            }
        ));

        return rolesEdit;
    }

}

function rolesAutocomplete(rolesArray) {

    if(localStorage.getItem('i18nextLng') === 'en') {
        const rolesLenguageArray = rolesArray.map((rol) => 
            rol.name === "USER" ? "USUARIO" :
            rol.name === "PREVENCION" ? "PREVENCIÓN" : 
            rol.name === "CAMPANAS" ? "CAMPAÑAS" :
            rol.name === "ADMIN" ? "ADMIN" : "ROOT"
        );

        let rolesAutocomplete = "";
        
        for (const rol of rolesLenguageArray) {
          rolesAutocomplete += rol + " ";
        }
        
        return rolesAutocomplete.trim();
    } else {
        const rolesLenguageArray = rolesArray.map((rol) => 
            rol.name === "USER" ? "USUARIO" :
            rol.name === "PREVENCION" ? "PREVENCIÓN" : 
            rol.name === "CAMPANAS" ? "CAMPAÑAS" :
            rol.name === "ADMIN" ? "ADMIN" : "ROOT"
        );

        let rolesAutocomplete = "";
        
        for (const rol of rolesLenguageArray) {
          rolesAutocomplete += rol + " ";
        }
        
        return rolesAutocomplete.trim();
    }
}

export function createData(id, marca, firstname, lastname, roles, email, estado, eventEditar) {
    return { 
        id, 
        marca, 
        firstname, 
        lastname, 
        roles:rolesAutocomplete(roles), 
        email, 
        estado: chipEstado(estado), 
        acciones: buttonTableRow({id, firstname, lastname, roles:rolesFormat(roles), email, estado}, eventEditar) 
    };
}

export function createRow(items, eventEditar) {
    let rows = [];
  
    for(let item of items) {
      rows.push(createData(item.id, item.marca, item.firstName, item.lastName, item.roleUser ? item.roleUser : item.roles , item.email, item.isEnable, eventEditar));
    }
  
    return rows;
}