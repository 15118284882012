// react
import React from "react";

// material-ui
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";

// otros
import { useTranslation } from "react-i18next";
import SpinnerMessageBasic from "../spinner/SpinnerMessageBasic";

const TablaBasica = ({ heigth, alignRow, columns, rows, footTotal, spinner, message }) => {

    const { t } = useTranslation();

    // eventos de la tabla
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    // fin evemtos de la tabla

    return(
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            {
                rows.length ?
                    <>
                        <TableContainer 
                            sx={{
                                maxHeight: heigth,
                                overflowX: "auto",
                                "&::-webkit-scrollbar": {
                                    width: "6px",
                                    height: "6px",
                                },
                                "&::-webkit-scrollbar-track": {
                                    background: "#f1f1f1",
                                },
                                "&::-webkit-scrollbar-thumb": {
                                    background: "#888",
                                    borderRadius: "10px",
                                },
                                "&::-webkit-scrollbar-thumb:hover": {
                                    background: "#555",
                                },
                            }}
                        >
                            <Table stickyHeader size="small" >
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth }}
                                                sx={{ textAlign: "center", backgroundColor: "rgba(0, 138, 232, 0.3)", color: "gray" }}
                                                >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row) => {
                                            return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell key={column.id} align={alignRow}>
                                                    {column.format && typeof value === 'number'
                                                        ? column.format(value)
                                                        : value}
                                                    </TableCell>
                                                );
                                                })}
                                            </TableRow>
                                            );
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {
                            footTotal ?
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {footTotal.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth }}
                                                sx={{ textAlign: "center", backgroundColor: "rgba(0, 138, 232, 0.3)", color: "black" }}
                                                >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                            </Table>
                            : 
                                <></>
                        }

                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage={t("tablabasica_rowsperpage")}
                        />
                    </>
                :
                    <Grid 
                        container
                        spacing={0}
                        direction={"column"}
                        alignItems={"center"}
                        justifyContent={"center"}
                    >
                        <Grid item sx={{ marginTop: '5px', marginBottom: '5px' }}>
                            <SpinnerMessageBasic 
                                isActive={spinner}
                                typeSpinner={true}
                                message={message}
                            />
                        </Grid>
                    </Grid>
            }
        </Paper>
    );
};

export default TablaBasica;