// my proyect
import analisis from './analisis'
import seguridad from './seguridad'
import marketing from './marketing'
import clientes from './clientes'
import servicio from './servicio'
import root from './root'

export const userMenu = {
    items: [
        analisis,
        seguridad,
        marketing,
        clientes
    ]
};

export const adminMenu = {
    items: [
        servicio
    ]
};

export const rootMenu = {
    items: [
        root
    ]
};