
const urlBase = window._env_.SERVER_URL;

// get
export async function getAllArticle(page,size) {
    const response = await fetch(`${urlBase}/article/${localStorage.getItem('brand')}?page=${page}&size=${size}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    })
    const responseJson = await response.json()
    return responseJson
}

// get
export async function getAllArticleByText(text,page,size) {
    const response = await fetch(`${urlBase}/article/search/${localStorage.getItem('brand')}?searchText=${text}&page=${page}&size=${size}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    })
    const responseJson = await response.json()
    return responseJson
}

// get
export async function getAllSynchronousArticles() {
    const response = await fetch(`${urlBase}/article/synchronous/${localStorage.getItem('brand')}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    })
    const responseJson = await response.json()
    return responseJson
}

// get
export async function getAllCatalog() {
    const response = await fetch(`${urlBase}/catalog/${localStorage.getItem('brand')}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    })
    const responseJson = await response.json()
    return responseJson
}