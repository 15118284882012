// React
import React from 'react';

// material ui
import { Box, Grid, IconButton, Modal, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

// my proyect
import InputCreate from '../input/InputCreate';
import Section from './Section';

// css
import  { styleModal } from '../../style/StyleEditModel';

const modalCreate = {
    name: "",
    reset: true,
    filter: false,
    validaF: false,
    select: false,
    totalLista: 0,
    lista: [],
    dataFilter: [],
    brandId: localStorage.getItem('brand')
};

const ModalCreate = ({ t, open, setOpen, mainEvent }) => {
    const [data,setData] = React.useState(modalCreate);

    const handleKeyDown = (event) => {
        if (event.key === "Escape") {
            setOpen(false);
        }
    };

    return(
        <Modal open={open}>
            <Box style={styleModal} onKeyDown={handleKeyDown} tabIndex="-1">
                <Grid container rowSpacing={1} columnSpacing={0.75}>
                    <Grid item xs={12}>
                        <Box display="flex"
                            style={{
                                padding: "3px",
                                background: "#008AE8",
                                color: "white",
                                position: "sticky",
                                top: 0,
                                zIndex: 0
                            }}
                        >
                            <Box flexGrow={1} />
                            <Typography variant="h5" style={{ fontWeight: "bold" }}>
                                {t("clientes_modal_title")}
                            </Typography>
                            <Box flexGrow={1} />
                            <IconButton onClick={e => {setOpen(false)}} style={{ color: "white" }}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Grid>
                    <InputCreate t={t} data={data} setData={setData} mainEvent={mainEvent}/>
                    <Section t={t} data={data} setData={setData} />
                </Grid>
            </Box>
        </Modal>
    );
}

export default ModalCreate;