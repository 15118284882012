// material ui

import { Divider, Grid, TextField, Typography } from "@mui/material";

const CouponInfo = ({t, difusion, setDifusion}) => {
    return(
        <>
            <Grid container justifyContent="center" sx={{ marginTop: '20px' }} >
                <Grid item xs={5} sm={5} md={5} lg={5}>
                    <Divider sx={{ my: 2 }} />
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2}>
                    <Typography
                        variant="h5" 
                        sx={{ fontWeight: 'bold', textAlign: 'center' }} 
                    >
                        {t("infocoupon_label_main")}
                    </Typography>
                </Grid>
                <Grid item xs={5} sm={5} md={5} lg={5}>
                    <Divider sx={{ my: 2 }} />
                </Grid>
            </Grid>

            <Grid item xs={12} sm={3} md={3} lg={3}>
                <TextField
                    label={t("partcupon_textfield_encabezado_de_pase")}
                    size="small"
                    fullWidth
                    value={difusion.passHeader}
                    onChange={e => setDifusion({ ...difusion, passHeader: e.target.value})}
                    required
                />
            </Grid>

            <Grid item xs={12} sm={3} md={3} lg={3}>
                <TextField
                    label={t("partcupon_textfield_contenido_general")}
                    size="small"
                    fullWidth
                    value={difusion.general}
                    onChange={e => setDifusion({ ...difusion, general: e.target.value})}
                    required
                />
            </Grid>

            <Grid item xs={12} sm={3} md={3} lg={3}>
                <TextField
                    label={t("partcupon_textfield_texto_adicional")}
                    size="small"
                    fullWidth
                    value={difusion.additional}
                    onChange={e => setDifusion({ ...difusion, additional: e.target.value})}
                    required
                />
            </Grid>
        </>
    );
}

export default CouponInfo;