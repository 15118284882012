// material-ui
import { Box, Typography } from '@mui/material';

// project import
import { userMenu, adminMenu, rootMenu } from './items';
import NavGroup from './NavGroup';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = ({open, handleMenu}) => {
    const rolesList = JSON.parse(localStorage.getItem('roles'));
    let menuItem;

    for(const rol of rolesList) {

        if (rol.name === "ROOT") {
          menuItem = rootMenu;
        } else if (rol.name === "ADMIN") {
          menuItem = adminMenu;
        } else {
          menuItem = userMenu;
        }
        
    }

    const navGroups = menuItem.items.map((item) => {
        if(item.type === "group") {
            for(const rol of rolesList) {
                if(rol.name === item.role) {
                    return <NavGroup key={item.id} item={item} open={open} handleMenu={handleMenu} />;
                }
            }
        } else {
            for(const rol of rolesList) {
                if(rol === item.role) {                        
                    return (
                        <Typography key={item.id} variant="h6" color="error" align="center">
                            Fix - Navigation Group
                        </Typography>
                    );
                }
            }
        }
        return null;
    });

    return <Box sx={{ pt: 2, mt: open ? 1 : 0 }}>{navGroups}</Box>;
};

export default Navigation;
