// my service
import { getAllCatalog, getAllArticle, getAllSynchronousArticles, getAllArticleByText } from '../../../services/campanas/catalogo/CatalogoService';

// getAll
export const listArticle = async (updateFilas,page,size) => {
    try {
        const response = await getAllArticle(page,size);
        updateFilas(response);
    } catch (error) {
        console.error(error);
    }
}

// getAll
export const listArticleByText = async (updateFilas,text,page,size) => {
    try {
        const response = await getAllArticleByText(text,page,size);
        updateFilas(response);
    } catch (error) {
        console.error(error);
    }
}
// getAll
export const listArticleSynchronous = async (updateFilas) => {
    try {
        const response = await getAllSynchronousArticles();
        updateFilas(response);
    } catch (error) {
        console.error(error);
    }
}

// getAll
export const listCatalog = async (updateFilas) => {
    try {
        const response = await getAllCatalog();
        updateFilas(response.object.ApiDocument.Response.Styles);
    } catch (error) {
        console.error(error);
    }
}