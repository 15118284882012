// react
import React from "react";

// otro
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

// material ui
import { Box, Card, CardContent, Grid, TextField, Typography, Button } from "@mui/material";

const designData = {
    name: "",
    logo: "",
    image: ""
}

const DesignForm = ({t, setLogo, setImage, eventAddDesign}) => {
    
    const MySwal = withReactContent(Swal);

    const [design, setDesign] = React.useState(designData);

    const fileInputRefLogo = React.useRef(null);
    const fileInputRefImg = React.useRef(null);

    const [selectedFileNameLogo, setSelectedFileNameLogo] = React.useState('');
    const [selectedFileNameImg, setSelectedFileNameImg] = React.useState('');

    const handleFileInputChangeLogo = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (!file.type.startsWith("image/png")) {
                MySwal.fire({
                    title: '<span style="font-weight: normal; font-family: Lato, sans-serif;">'+t("title_warning_size")+'</span>',
                    html: '<span style="font-style: normal; font-family: Lato, sans-serif;">'+t("mss_warning_type")+'</span>',
                    icon: 'warning',
                    confirmButtonColor: '#008ae8'
                });
                return;
            }

            if (file.size > 4 * 1024 * 1024) {
                MySwal.fire({
                    title: '<span style="font-weight: normal; font-family: Lato, sans-serif;">'+t("title_warning_size")+'</span>',
                    html: '<span style="font-style: normal; font-family: Lato, sans-serif;">'+t("mss_warning_size")+'</span>',
                    icon: 'warning',
                    confirmButtonColor: '#008ae8'
                });
                return;
            }
            const url = URL.createObjectURL(file);
            setDesign({ ...design, logo: file });
            setLogo(url);
            setSelectedFileNameLogo(file.name);
        }
    };

    const handleFileInputChangeImg = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (!file.type.startsWith("image/png")) {
                MySwal.fire({
                    title: '<span style="font-weight: normal; font-family: Lato, sans-serif;">'+t("title_warning_size")+'</span>',
                    html: '<span style="font-style: normal; font-family: Lato, sans-serif;">'+t("mss_warning_type")+'</span>',
                    icon: 'warning',
                    confirmButtonColor: '#008ae8'
                });
                return;
            }

            if (file.size > 4 * 1024 * 1024) {
                MySwal.fire({
                    title: '<span style="font-weight: normal; font-family: Lato, sans-serif;">'+t("title_warning_size")+'</span>',
                    html: '<span style="font-style: normal; font-family: Lato, sans-serif;">'+t("mss_warning_size")+'</span>',
                    icon: 'warning',
                    confirmButtonColor: '#008ae8'
                });
                return;
            }
            const url = URL.createObjectURL(file);
            setDesign({ ...design, image: file });
            setImage(url);
            setSelectedFileNameImg(file.name);
        }
    };

    const handleOpenFileDialogLogo = () => {
        fileInputRefLogo.current.click();
    };

    const handleOpenFileDialogImg = () => {
        fileInputRefImg.current.click();
    };

    const handleAddDesign = (e) => {
        e.preventDefault();
        eventAddDesign(design);
    }

    return(
        <Grid item xs={12} sm={6} md={6} lg={6}>
            <Typography
                variant="h4" 
                sx={{ fontWeight: 'bold', textAlign: 'center', marginBottom: "25px" }} 
            >
                {t("title_design")}
            </Typography>
            <Box display="flex" justifyContent="center">
                <Box display="inline-flex" sx={{width:"50%"}}>
                    <Card>
                        <CardContent>
                            <form onSubmit={handleAddDesign} >
                                <Typography sx={{marginBottom: "15px"}}>
                                    {t("name_description")}
                                </Typography>
                                <Grid item xs={12} sm={12} md={12} lg={12} sx={{marginBottom: "15px"}}>
                                    <TextField
                                        label={t("name_design")}
                                        size="small"
                                        fullWidth
                                        value={design.name}
                                        onChange={e => setDesign({ ...design, name: e.target.value })}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} sx={{marginBottom: "15px"}}>
                                    <TextField
                                        label={t("logo_design")}
                                        size="small"
                                        fullWidth
                                        value={selectedFileNameLogo}
                                        onClick={handleOpenFileDialogLogo}
                                        required
                                        InputProps={{
                                            endAdornment: (
                                                <Button 
                                                    variant="contained"
                                                    style={{ marginRight: '-4.5%', borderTopLeftRadius: 0, borderBottomLeftRadius: 0, width: "60%" }}
                                                >
                                                    {t("partcupon_button")}
                                                </Button>
                                            ),
                                        }}
                                    />
                                    <input
                                        type="file"
                                        accept="image/png"
                                        ref={fileInputRefLogo}
                                        style={{ display: 'none' }}
                                        onChange={handleFileInputChangeLogo}
                                    />
                                </Grid>
                                <Typography sx={{marginBottom: "15px"}}>
                                    {t("logo_description")}
                                </Typography>
                                <Grid item xs={12} sm={12} md={12} lg={12} sx={{marginBottom: "15px"}}>
                                    <TextField
                                        label={t("partcupon_textfield_imagen")}
                                        size="small"
                                        value={selectedFileNameImg}
                                        fullWidth
                                        onClick={handleOpenFileDialogImg}
                                        required
                                        InputProps={{
                                            endAdornment: (
                                                <Button 
                                                    variant="contained"
                                                    style={{ marginRight: '-4.5%', borderTopLeftRadius: 0, borderBottomLeftRadius: 0, width: "60%" }}
                                                >
                                                    {t("partcupon_button")}
                                                </Button>
                                            ),
                                        }}
                                    />
                                    <input
                                        type="file"
                                        accept="image/png"
                                        ref={fileInputRefImg}
                                        style={{ display: 'none' }}
                                        onChange={handleFileInputChangeImg}
                                    />
                                </Grid>
                                <Typography sx={{marginBottom: "15px"}}>
                                    {t("image_description")}
                                </Typography>
                                <Grid container spacing={2} justifyContent="flex-end">
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                sx={{ backgroundColor: "#52c41a", '&:hover': { bgcolor: '#237804' } }}
                                            >
                                                {t("btn_description")}
                                            </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </CardContent>
                    </Card>  
                </Box>
            </Box>       
        </Grid>
    );

}

export default DesignForm;