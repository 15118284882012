// material ui
import { Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";

const General = ({ t, list, difusion, setDifusion }) => {

    const getSvsZoneId = (value) => {
        const programCouponId = value.target.value;
        const programCoupon = list.object.programCoupon.find(e => e.couponProgramId === programCouponId)
        setDifusion({
            ...difusion,
            svsZoneId: programCoupon.svsZoneId,
            cuponProgramId: programCoupon.couponProgramId
        });
    }

    return (
        <>
            <Grid container justifyContent="center" sx={{ marginTop: '20px' }} >

                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography
                        variant="subtitle1"
                        sx={{ textAlign: 'left' }}
                    >
                        {t("explicacion_diffusion_customer")}
                    </Typography>
                </Grid>

                <Grid item xs={5} sm={5} md={5} lg={5}>
                    <Divider sx={{ my: 2 }} />
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2}>
                    <Typography
                        variant="h5"
                        sx={{ fontWeight: 'bold', textAlign: 'center' }}
                    >
                        {t("selecttype_1")}
                    </Typography>
                </Grid>
                <Grid item xs={5} sm={5} md={5} lg={5}>
                    <Divider sx={{ my: 2 }} />
                </Grid>
            </Grid>

            {/* <Grid item xs={12} sm={3} md={3} lg={3}>
                <TextField
                    label={t("partcupon_select_tipo_campana")}
                    size="small"
                    fullWidth
                    value={t("selecttype_1")}
                    required
                    disabled
                />
            </Grid> */}

            <Grid item xs={12} sm={3} md={3} lg={3}>
                <TextField
                    label={t("partcupon_textfield_nombre_campana")}
                    size="small"
                    fullWidth
                    value={difusion.name}
                    onChange={e => setDifusion({ ...difusion, name: e.target.value })}
                    inputProps={{ maxLength: 25 }}
                    required
                />
            </Grid>

            <Grid item xs={12} sm={3} md={3} lg={3}>
                <FormControl fullWidth >
                    <InputLabel size="small" htmlFor="simple-select-label">{t("partcupon_select_tipo_design")}</InputLabel>
                    <Select
                        size="small"
                        id="simple-select"
                        label={t("partcupon_select_tipo_design")}
                        value={difusion.designId ? difusion.designId : ""}
                        onChange={e => setDifusion({ ...difusion, designId: e.target.value })}
                    // required
                    >
                        <MenuItem key={0} value={0} disabled>
                            <em>{t("partcupon_tipo_design_menuitem")}</em>
                        </MenuItem>
                        {
                            list.object.listaDesigns.map((data) => (
                                <MenuItem key={data.id} value={data.id}>{data.name}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={12} sm={3} md={3} lg={3}>

                <FormControl fullWidth >
                    <InputLabel size="small" htmlFor="simple-select-label">{t("partcupon_select_enviar_a")}</InputLabel>
                    <Select
                        size="small"
                        id="simple-select"
                        label={t("partcupon_select_enviar_a")}
                        value={difusion.listCustomerId ? difusion.listCustomerId : ""}
                        onChange={e => setDifusion({ ...difusion, listCustomerId: e.target.value })}
                        required
                    >
                        <MenuItem key={0} value={0} disabled>
                            <em>{t("partcupon_tipo_enviar_a")}</em>
                        </MenuItem>
                        {
                            list.object.listCustomer.map((customerList) => (
                                <MenuItem key={customerList.id} value={customerList.id}>{customerList.name}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>

            </Grid>

            <Grid item xs={12} sm={3} md={3} lg={3}>

                <FormControl fullWidth >
                    <InputLabel size="small" htmlFor="simple-select-label">{t("partcupon_select_programa_cupon")}</InputLabel>
                    <Select
                        size="small"
                        id="simple-select"
                        label={t("partcupon_select_programa_cupon")}
                        value={difusion.cuponProgramId ? difusion.cuponProgramId : ""}
                        onChange={getSvsZoneId}
                        required
                    >
                        <MenuItem key={0} value={0} disabled>
                            <em>{t("partcupon_tipo_programa_cupon")}</em>
                        </MenuItem>
                        {
                            list.object.programCoupon.map((programa) => (
                                <MenuItem key={programa.couponProgramId} value={programa.couponProgramId}>{programa.description}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>

            </Grid>

            <Grid item xs={12} sm={3} md={3} lg={3}>

                <FormControl fullWidth >
                    <InputLabel size="small" htmlFor="simple-select-label">{t("partcupon_select_tipo_cupon")}</InputLabel>
                    <Select
                        size="small"
                        id="simple-select"
                        label={t("partcupon_select_tipo_cupon")}
                        value={difusion.typeCoupon ? difusion.typeCoupon : ""}
                        onChange={e => setDifusion({ ...difusion, typeCoupon: e.target.value })}
                        required
                    >
                        <MenuItem key={0} value={0} disabled>
                            <em>{t("partcupon_tipo_tipo_cupon")}</em>
                        </MenuItem>
                        <MenuItem key={1} value={1}>$</MenuItem>
                        <MenuItem key={2} value={2}>%</MenuItem>
                    </Select>
                </FormControl>

            </Grid>

            <Grid item xs={12} sm={3} md={3} lg={3}>
                <TextField
                    label={t("partcupon_textfield_valor")}
                    size="small"
                    fullWidth
                    value={difusion.valueCoupon}
                    onChange={e => setDifusion({ ...difusion, valueCoupon: e.target.value })}
                    required
                />
            </Grid>

            <Grid item xs={12} sm={3} md={3} lg={3}>
                <TextField
                    label={t("partcupon_textfield_fecha_envio")}
                    type="datetime-local"
                    size="small"
                    fullWidth
                    value={difusion.dateSend}
                    onChange={e => setDifusion({ ...difusion, dateSend: e.target.value })}
                    required
                    InputLabelProps={{
                        shrink: true, // Mostrar la etiqueta incluso si no hay valor seleccionado
                    }}
                />
            </Grid>

            <Grid item xs={12} sm={3} md={3} lg={3}>
                <TextField
                    label={t("partcupon_textfield_vigencia_del")}
                    type="datetime-local"
                    size="small"
                    fullWidth
                    value={difusion.dateOf}
                    onChange={e => setDifusion({ ...difusion, dateOf: e.target.value })}
                    required
                    InputLabelProps={{
                        shrink: true, // Mostrar la etiqueta incluso si no hay valor seleccionado
                    }}
                />
            </Grid>

            <Grid item xs={12} sm={3} md={3} lg={3}>
                <TextField
                    label={t("partcupon_textfield_vigencia_al")}
                    type="datetime-local"
                    size="small"
                    fullWidth
                    value={difusion.dateAt}
                    onChange={e => setDifusion({ ...difusion, dateAt: e.target.value })}
                    required
                    InputLabelProps={{
                        shrink: true, // Mostrar la etiqueta incluso si no hay valor seleccionado
                    }}
                />
            </Grid>
        </>
    );
}

export default General;