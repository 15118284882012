// material-ui
import { Chip, Grid } from "@mui/material";

// otros
import { useTranslation } from "react-i18next";

const ChipEstado = ({ color, label, icon }) => {
    const { t } = useTranslation();

    return(    
    <Grid>
        <Chip
            variant="combined"
            color={color}
            label={t(label)}
            size="small"
            icon={icon}
        />
    </Grid>
    );
};

export default ChipEstado;