// material-ui
import { Edit, Add } from '@mui/icons-material';

// my proyect
import ButtonTableRow from "../../components/button/ButtonTableRow";
import ChipEstado from "../../components/chip/ChipEstado";
import ModalMarcasEdit from './ModalMarcasEdit';
import ModalAddEvents from './ModalAddEvent';

export function getColumns( t ) {
    return [
        { id: 'marca', label: t("marca_columns_marca"), minWidth: 50 },
        { id: 'chq', label: t("marca_columns_chq"), minWidth: 50 },
        { id: 'cshq', label: t("marca_columns_cshq"), minWidth: 50 },
        { id: 'api', label: t("marca_columns_api"), minWidth: 50 },
        { id: 'crm', label: t("marca_columns_crm"), minWidth: 50 },
        { id: 'vsn', label: t("marca_columns_vsn"), minWidth: 50 },
        { id: 'pln', label: t("marca_columns_pln"), minWidth: 50 },
        { id: 'evcn', label: t("marca_columns_evcn"), minWidth: 50 },
        { id: 'add', label: t("marca_columns_add"), minWidth: 50 },
        { id: 'tot', label: t("marca_columns_tot"), minWidth: 50 },
        { id: 'use', label: t("marca_columns_use"), minWidth: 50 },
        { id: 'usac', label: t("marca_columns_usac"), minWidth: 50 },
        { id: 'estado', label: t("marca_columns_estado"), minWidth: 50 },
        { id: 'acciones', label: t("marca_columns_acciones"), minWidth: 50 },
    ];
}

function chipEstado( estado ) {
    return <ChipEstado color={ estado ? "success" : "error" } label={ estado ? "chipestado_true" : "chipestado_false" } />;
}

function modalMarcasEdit({ openModal, mainEvent, closeEvent, data }) {
    return <ModalMarcasEdit 
        open={openModal}
        titulo={localStorage.getItem('i18nextLng') === 'en' ? "Edit Mark" : "Editar Marca"}
        btnName={localStorage.getItem('i18nextLng') === 'en' ? "Edit" : "Editar"}
        mainEvent={mainEvent}
        closeEvent={closeEvent}
        data={data}
    />;
}

function modalAddEvents({ openModal, mainEvent, closeEvent, data }) {
    return <ModalAddEvents 
        open={openModal}
        titulo={localStorage.getItem('i18nextLng') === 'en' ? "Add Events" : "Agregar Eventos"}
        btnName={localStorage.getItem('i18nextLng') === 'en' ? "Add" : "Agregar"}
        mainEvent={mainEvent}
        closeEvent={closeEvent}
        data={data}
    />;
}

function buttonTableRow( data, eventEditar ) {
    return (
        <div style={{ display: 'flex' }}>
          <ButtonTableRow
            icon={<Add />}
            bgColor={"#008AE8"}
            iconColor={"white"}
            colorHover={"#316FB5"}
            data={data}
            modal={modalAddEvents}
            mainEvent={eventEditar}
          />
          <ButtonTableRow
            icon={<Edit />}
            bgColor={"#008AE8"}
            iconColor={"white"}
            colorHover={"#316FB5"}
            data={data}
            modal={modalMarcasEdit}
            mainEvent={eventEditar}
          />
        </div>
      );
}

function createData( brand, packageHistory, permanentEvents, packag, packageList, updateEvent  ) {
  return {
    id:brand.id,
    marca:brand.name,
    chq:brand.chqServer,
    cshq:brand.cshqServer,
    api:brand.chqApi,
    crm:brand.crmApi ? brand.crmApi.substring(0, 10) + '...' : "",
    vsn:brand.chqVersion,
    estado: chipEstado(brand.isEnable),
    usac:brand.usersCount,
    pln:packag.name,
    evcn:packag.events,
    add:permanentEvents.remainingEvents,
    tot:(packageHistory.remainingEvents + permanentEvents.remainingEvents),
    use:(packageHistory.usedEvents + permanentEvents.usedEvents),
    acciones:buttonTableRow({brand, packageHistory, permanentEvents, packag, packageList}, updateEvent)
  };
}

export function createRow( items, packageList, updateEvent ) {
    let rows = [];
  
    for(let item of items) {
      rows.push(
        createData(
          item.brandDtoE,
          item.brandPackageHistoryDtoE,
          item.brandPermanentEventsDtoE,
          item.packagDtoE,
          packageList,
          updateEvent
        )
      );
    }
  
    return rows;
}