// React
import React, {useEffect,useState} from 'react';
import { useHistory  } from 'react-router-dom'; // cuidado es deprecated con la v5 funciona hay que actualizar a la v6

// otros
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useTranslation } from "react-i18next";
import jwt_decode from 'jwt-decode';

// material-ui
import { Visibility, VisibilityOff } from '@mui/icons-material';

// my service
import { loginUser } from '../../services/login/LoginService';

// assets
import logo from '../../assets/logo_tracpeak.png';
import './Login.css';

const emailRegexp = new RegExp(/[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/);

function Login(props) {

    const { t } = useTranslation();

    const [isPasswordHidden, setIsPasswordHidden] = useState(true);

    const MySwal = withReactContent(Swal);
    const history = useHistory(); // cuidado es deprecated
    
    // verifica que exista sesion
    const verificaSesion = () => {
        // - Si hay sesion permite acceso sio regresa a raiz
        if(localStorage.getItem('sesion')) { history.push('/dashboard') }
    };

    useEffect(() => {
        verificaSesion();
    });
    // verifica que exista sesion

    const [emailUser, setEmailUser] = React.useState({
        email: "",
        passw: "",
        hasError: false,
        hasErrorPw: false
    });

    const handleClick = async e => {
        e.preventDefault();

        const hasError = props.type ? false : !emailRegexp.test(emailUser.email);
        setEmailUser((prevState) => ({ ...prevState, hasError }));
        
        const hasErrorPw = emailUser.passw === "" ? true : false;
        setEmailUser((prevState) => ({ ...prevState, hasErrorPw }));
        
        if(!hasError && !hasErrorPw) {
            
            const token = await loginUser({
                email: emailUser.email,
                password: emailUser.passw
            });

            if(token.token) {

                const loginData = jwt_decode(token.token);

                // acceso - temporal - 9HwenKQiSj6J2Sh!
                MySwal.fire({
                    title: '<span style="font-weight: normal; font-family: Lato, sans-serif;">'+t("sw_title_login_success")+'</span>',
                    html: '<span style="font-style: normal; font-family: Lato, sans-serif;">' + t("sw_html_login_success") + ' ' + loginData['name'] + " " + loginData['lastName'] + '!</span>',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 2000,  // opcional, para cerrar automáticamente después de cierto tiempo (en milisegundos)
                    didClose: () => {
                        // Código a ejecutar después de que el temporizador se complete y se cierre el mensaje
                        // Aquí puedes realizar las acciones adicionales que deseas realizar
                        props.sesionFuncion(true);

                        // temp
                        localStorage.setItem('name', loginData['name'] + " " + loginData['lastName']);
                        localStorage.setItem('email', loginData['email']);
                        // localStorage.setItem('image', "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRuyms3C5U_OrPuPQhv9WF-McOoTq5SLNPGfg&usqp=CAU");
                        localStorage.setItem('image', "");
                        localStorage.setItem('brand', loginData['brand']);
                        localStorage.setItem('roles', JSON.stringify(loginData['role']));
                        localStorage.setItem('token', token.token);
                        // fin temp 
                        
                        history.push('/dashboard'); // cuidado es deprecated
                    }
                });
                // acceso
            } else {
                // campos no coinciden
                MySwal.fire({
                    title: '<span style="font-weight: normal; font-family: Lato, sans-serif">'+t("sw_title_login_error")+'</span>',
                    html: '<span style="font-style: normal; font-family: Lato, sans-serif">'+t("sw_html_login_error")+'</span>',
                    icon: 'error',
                    confirmButtonColor: '#008ae8'
                });
            }
        }
    }

    function handleChangeEmail(evt) {
        setEmailUser({
            email: evt.target.value.toLowerCase(),
            passw: emailUser.passw,
            hasError: emailUser.hasError,
            hasErrorPw: emailUser.hasErrorPw
        });
    }

    function handleChangePass(evt) {
        setEmailUser({
            email: emailUser.email,
            passw: evt.target.value,
            hasError: emailUser.hasError,
            hasErrorPw: emailUser.hasErrorPw
        });
    }

    const togglePasswordVisibility = () => {
        setIsPasswordHidden(!isPasswordHidden);
    };

    const handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        handleClick(e);
      }
    };
    
    return(
        <div className="div-main" onKeyDown={handleKeyDown}>
          <header className="header-main">
            <img src={logo} className="bg-logo" alt="logo" />
            <div className="login-box">
                <img src={logo} className="login-logo" alt="logo" />
                <form>
                    <div className="user-box">
                        <input 
                            type="text"
                            name=""
                            required=""
                            value={emailUser.email}
                            onChange={handleChangeEmail}
                        />
                        <label className='text-title-login'>{t("email")}</label>
                        <p className="text-error" style={{ visibility: emailUser.hasError ? "visible" : "hidden" }}>
                            {t("email_error")}
                        </p>
                    </div>
                    <div className="user-box">
                        <input 
                            type={isPasswordHidden ? "password" : "text"}
                            name=""
                            required=""
                            value={emailUser.passw}
                            onChange={handleChangePass}
                        />
                        
                        <i className="icon-pass" onClick={togglePasswordVisibility}>
                            {isPasswordHidden ? <Visibility /> : <VisibilityOff />}
                        </i>

                        <label className='text-title-login'>{t("password")}</label>
                        <p className="text-error" style={{ visibility: emailUser.hasErrorPw ? "visible" : "hidden" }}>
                            {t("password_error")}
                        </p>
                    </div>
                    <a href="/" onClick={handleClick} >
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        {t("btn_login")}
                    </a>
                </form>
                <p
                    className="forgot-password-link"
                    onClick={() => {
                        history.push('/forgot-password');
                    }}
                >
                    {t("forgot_password")}
                </p>
            </div>    
          </header>
        </div>
    );
}

export default Login;