// react
import React from "react";

// material-ui
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import SpinnerMessageBasic from "../spinner/SpinnerMessageBasic";

const TablaBasica = ({ heigth, alignRow, columns, rows, message, bColor="rgb(0, 138, 232)", lColor="white"}) => {

    const [page] = React.useState(0);
    const [rowsPerPage] = React.useState(10);

    return(
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer 
                sx={{
                    maxHeight: heigth,
                    overflowX: "auto",
                    "&::-webkit-scrollbar": {
                        width: "6px",
                        height: "6px",
                    },
                    "&::-webkit-scrollbar-track": {
                        background: "#f1f1f1",
                    },
                    "&::-webkit-scrollbar-thumb": {
                        background: "#888",
                        borderRadius: "10px",
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                        background: "#555",
                    },
                }}
            >
                <Table stickyHeader size="small" >
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                    sx={{ textAlign: "center", backgroundColor: bColor, color: lColor }}
                                    >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            !rows.length ?
                                (
                                    <TableRow>
                                        <TableCell
                                            style={{ 
                                                verticalAlign: 'middle', 
                                                textAlign: 'center', 
                                                height: '200px' // define this based on your needs 
                                            }}
                                            colSpan={columns.length}
                                        >
                                            <SpinnerMessageBasic 
                                                isActive={true}
                                                typeSpinner={true}
                                                message={message}
                                            />
                                        </TableCell>
                                    </TableRow>
                                )
                            :
                                (
                                    rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                        return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                            {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell key={column.id} align={alignRow}>
                                                {column.format && typeof value === 'number'
                                                    ? column.format(value)
                                                    : value}
                                                </TableCell>
                                            );
                                            })}
                                        </TableRow>
                                        );
                                    })
                                )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

export default TablaBasica;