export const gridSection = {
    maxHeight: 'calc(100vh - 165px)',
    width: 'calc(100vw - 50px)',
    overflowY: "auto",
    "&::-webkit-scrollbar": {
        width: "6px",
        height: "6px",
    },
    "&::-webkit-scrollbar-track": {
        background: "#f1f1f1",
    },
    "&::-webkit-scrollbar-thumb": {
        background: "#888",
        borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
        background: "#555",
    },
}

export const styleModal = {
    width: '100vw',
    height: '100vh',
    background: "#f5f5f5"
};