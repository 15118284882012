import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { optionsPie } from './style/PieWidgetStyle';

ChartJS.register(ArcElement, Tooltip, Legend);

const PieWidget = ({ data })  => {
    return(
        <Pie options={optionsPie} data={data} />
    );
};

export default PieWidget;
