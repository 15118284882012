// react
import React from "react";

// material ui
import { Box, Button, Card, CardContent, Grid, TextField } from "@mui/material";

// my proyect
import { showMessage, showMessageSure } from "../../../../../utils/messages/Message";

const InputCreate = ({t, data, mainEvent}) => {
    const [nameList, setNameList] = React.useState("");

    const addList = (e) => {
        e.preventDefault();
        const create = {...data,name:nameList};
        if(create.select || create.lista.length > 0) {
            let agregarNumber = 0;
            if (create.select) {
                agregarNumber = create.totalLista;
            } else {
                agregarNumber = create.lista.length;
            }
            const combinedMessage = `${t('edit_add_customers',{addCustomer:agregarNumber})} ${t('edit_totale_customers',{total:agregarNumber})}`.trim();
            showMessageSure({
                title: t("menssage_title_atencion"),
                message: combinedMessage,
                type: "warning",
                btnCancel: t("swal_create_difusion_button_cancel"),
                btnConfirm: t("continuar"),
                event: () => mainEvent(create)
            });
            
        } else {
            showMessage({title: t("menssage_title_oi"), message: t("menssage_conte_vacio"), type: 'error'});
        }
    }

    return(
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ backgroundColor: "transparent" }} >
            <Box display="flex" justifyContent="center" alignItems="center" sx={{ backgroundColor: "transparent" }} >
                <Card sx={{margin: "5px"}}>
                    <CardContent>
                        <form onSubmit={addList}>
                            <TextField
                                label={t("modallistacliente_textfield_name")}
                                sx={{ marginRight: "10px" }}
                                size="small"
                                value={nameList}
                                onChange={(e) => setNameList(e.target.value)}
                                required
                            />
                            <Button
                                variant="contained"
                                type="submit"
                                sx={{ backgroundColor: "info.main", margin: "1px" }}
                            >
                                {t("clientes_modal_main_btn")}
                            </Button>
                        </form>
                    </CardContent>
                </Card>
            </Box>
        </Grid>
    );
}

export default InputCreate;