function createData(id, nombreLocacion, montoReembolsado, receiptnum) {
    return { id, nombreLocacion, montoReembolsado, receiptnum};
}

export function createRowLMR(items) {
    let rows = [];
    let id = 0;
    
    items.sort((a, b) => b.montoReembolsado - a.montoReembolsado);

    for(let item of items) {
        id++;
        rows.push(
            createData(
                id,
                item.nombreLocacion,
                "$"+Number(item.montoReembolsado).toLocaleString("en-US"),
                item.receiptnum
            )
        );
    }

    return rows;
}
// calcular el total de tickets LMR
export function totalTicketLMR(rows) {
    return rows.map(({ receiptnum }) => receiptnum).reduce((sum, i) => sum + i, 0);
}

// calcular total de monto reembolsado en efectivo LMR
export function totalMontoLMR(rows) {
    const total = rows.reduce((acc, row) => {
        const monto = parseFloat(row.montoReembolsado);
        return acc + monto;
    }, 0);

    return "$" + total.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g,'$1,'); // toFixed redondea el número hacia arriba
}