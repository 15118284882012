export const styleDashGrid = {
    marginTop: '5px',
    maxHeight: 'calc(100vh - 160px)',
    width: '100%',
    overflowY: "auto",
    "&::-webkit-scrollbar": {
        width: "0px",
        height: "0px",
    },
    "&::-webkit-scrollbar-track": {
        background: "#f1f1f1",
    },
    "&::-webkit-scrollbar-thumb": {
        background: "#888",
        borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
        background: "#555",
    }
}

export const styleEGraphCard = {
    backgroundColor: "rgba(0, 138, 232, 0.3)"
}

export const styleEGraphHeader = {
    height: "20%"
}

export const styleEGraphContent = {
    height: "55%"
}

export const styleTittle = {
    width: '100%',
    marginTop: '10px',
    backgroundColor: "#6D7075",
    padding: "0px 0px 10px 10px",
    border: "1px solid #b1b1b1",
    borderRadius: "6px",
    color: "White"
}

    ;