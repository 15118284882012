import React from "react";
import ChipEstado from "../../../components/chip/ChipEstado";

export function getColumns(t) {
    return [
        { id: 'name', label: t("cliente_table_name"), minWidth: 90 },
        { id: 'email', label: t("cliente_table_email"), minWidth: 90 },
        { id: 'rec_date', label: t("cliente_table_rec_date"), minWidth: 90 },
        { id: 'last_date', label: t("cliente_table_last_date"), minWidth: 90 },
        { id: 'active_coupons', label: t("cliente_table_number_coupons"), minWidth: 20 },
        { id: 'pass_status', label: t("cliente_table_pass_status"), minWidth: 20 },
        { id: 'coupons', label: t("cliente_table_coupons"), minWidth: 20 },
        { id: 'mount_redeeded', label: t("cliente_table_mount_reedeemed"), minWidth: 20 },
    ];
}

export const EstadoChip = React.memo(function EstadoChip({ estado }) {
    return <ChipEstado color={estado ? "primary" : "error"} label={estado ? "chipestado_true" : "chipestado_false"} />;
});

const opcionesFecha = { year: 'numeric', month: 'long', day: 'numeric' };

function createData(item) {
    let iniDate = new Date(item.recDate);
    let endDate = new Date(item.lastDate);
    return { 
        key: item.id,
        id: item.id, 
        name: item.fullName, 
        email: item.email, 
        rec_date: iniDate.toLocaleDateString(localStorage.getItem('i18nextLng') === 'en' ? 'en-EN' : 'es-ES', opcionesFecha), 
        last_date: item.lastDate != null ? endDate.toLocaleDateString(localStorage.getItem('i18nextLng') === 'en' ? 'en-EN' : 'es-ES', opcionesFecha) : "",
        active_coupons: item.numberCoupons.toLocaleString("en-US"), 
        pass_status: <EstadoChip estado={item.isPass} />, 
        coupons: item.redeededCoupons.toLocaleString("en-US"),
        mount_redeeded: `$${item.mountRedeeded.toFixed(2).toLocaleString("en-US")}`
    };
}

export function createRow(items) {
    return items.content.map(createData);
}
