import { Checkbox, FormControlLabel } from '@mui/material';
import Edit from '@mui/icons-material/Edit';
import CheckInList from '../../customerlist/utils/CheckInList';
import ButtonTableRow from "../../../../components/button/ButtonTableRow";
import ChipEstado from "../../../../components/chip/ChipEstado";
import ModalEdit from "../modal/edit/ModalEdit";

const monthsEN = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const monthsES = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
const opcionesFecha = { year: 'numeric', month: 'long', day: 'numeric' };

export function getColumnsCheck(t, evento, status) {
    return [
        {
            id: 'select',
            label: (
                <FormControlLabel
                    label={t("modallistacliente_columns_select")}
                    control={<Checkbox onChange={evento} color="default" checked={status} />}
                />
            ),
            minWidth: 90
        },
        { id: 'email', label: t("modallistacliente_columns_email"), minWidth: 90 },
        { id: 'name', label: t("modallistacliente_columns_name"), minWidth: 90 },
        { id: 'gender', label: t("modallistacliente_columns_gender"), minWidth: 90 },
        { id: 'birthday', label: t("modallistacliente_columns_birthday"), minWidth: 90 }
    ];
}

export function createRowCheck(items, lista, evento) {
    return items.content.map(item => ({
        key: item.id,
        select: <CheckInList id={item.id} lista={lista} evento={evento} />,
        email: item.email,
        name: item.fullName,
        gender: item.gender,
        birthday: getMonthName(item.bdMonth)
    }));
}

function getMonthName(monthNumber) {
    const months = localStorage.getItem('i18nextLng') === 'en' ? monthsEN : monthsES;
    const index = monthNumber - 1;
    const mensaje = localStorage.getItem('i18nextLng') === 'en' ? "" : "";
    
    if (index < 0 || index > 11) {
        return mensaje;
    }

    return months[index];
}

export function getColumns(t) {
    return ['name', 'estado', 'ini', 'end', 'acciones'].map((col) => ({
        id: col,
        label: t(`clientes_columns_${col}`),
        minWidth: 90,
    }));
}

function chipEstado(estado) {
    return <ChipEstado color={estado ? "success" : "error"} label={estado ? "chipestado_true" : "chipestado_false"} />;
}

function buttonTableRow(data, eventEditar) {
    return (
        <ButtonTableRow 
            icon={<Edit />}
            bgColor={"#008AE8"}
            iconColor={"white"}
            colorHover={"#316FB5"}
            data={data}
            modal={modalEdit}
            mainEvent={eventEditar}
        />
    );
}

function formatDate(date) {
    if (date) {
        const newDate = new Date(date);
        return newDate.toLocaleDateString(localStorage.getItem('i18nextLng') === 'en' ? 'en-EN' : 'es-ES', opcionesFecha);
    }
    return "";
}

export function createRow(items, eventEditar) {
    return items.content.map(item => ({
        key: item.id,
        name: item.name,
        estado: chipEstado(item.isEnable),
        ini: formatDate(item.recCreated),
        end: formatDate(item.recUpdated),
        acciones: buttonTableRow({ id: item.id, name: item.name, estado: item.isEnable, ini: item.recCreated, end: item.recUpdated }, eventEditar)
    }));
}

function modalEdit({ openModal, mainEvent, closeEvent, data }) {
    return (
        <ModalEdit 
            open={openModal}
            mainEvent={mainEvent}
            closeEvent={closeEvent}
            data={data}
        />
    );
}
