import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { optionsVertical } from './style/VerticalWidgetStyle';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);


const VerticalBarWidget = ({ data }) => {
    return <Bar options={optionsVertical} data={data} />;
}

export default VerticalBarWidget;
