// React
import React from 'react';

// material-ui
import { Autocomplete, Button, Card, CardContent, CardHeader, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Modal, Switch, TextField } from "@mui/material";

// otros
import { useTranslation } from "react-i18next";

// styles
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
};

const ModalAddUsuario = ({ open, titulo, btnName, mainEvent, closeEvent, data }) => {

    const { t } = useTranslation();

    const [marca, setMarca] = React.useState(data.marca);

    const isOptionEqualToValue = (option, value) => option.value === value.value;

    const [roles, setRoles] = React.useState(data.roles);
    
    const rolesOptions = [
        { value: "PREVENCION", label: t("modaluser_select_rol_prevencion") },
        { value: "CAMPANAS", label: t("modaluser_select_rol_campana") },
    ];

    const [rolesString, setRolesString] = React.useState([{ name:"USER" }]);

    const [email, setEmail] = React.useState(data.email);

    const [estado, setEstado] = React.useState(data.estado);
    
    const handleClose = (e) => {
        closeEvent(open);
        handleKeepIni();
    }
    
    const handleAdd = (e) => {
        mainEvent({ id:data.id, marca, firstName:"", lastName:"", rolesString, email, pass:"", estado });

        if(!data.id) {
            handleKeepIni();            
        }
    }

    const handleKeepIni = (e) => {

        setMarca(data.marca);
    
        setRoles(data.roles);

        setEmail(data.email);
    
        setEstado(data.estado);

        setRolesString(data.roles);

    }

    const handleSelectRol = (event, newValue) => {
        const selectedRoles = newValue.map((option) => option.value);

        var rolesList = [
            {
                name:"USER"
            }
        ];        

        selectedRoles.forEach((role) => {
            rolesList.push({
                name: role,
            });
        });

        setRolesString(rolesList);

        setRoles(newValue);
    }

    return(
        <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <form onSubmit={handleAdd}>
                <Card sx={style}>
                    <CardHeader
                        title = {titulo}
                        sx={{ backgroundColor: "#008AE8", color: "white", textAlign: "center" }}
                    />
                    <CardContent>
                        <Grid container spacing={2} sx={{ marginTop: '5px' }}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField
                                    type="email"
                                    value={email}
                                    onChange={ e => {setEmail(e.target.value);} }
                                    label={t("modaluser_textfield_email")}
                                    fullWidth
                                    required
                                    onInvalid={e => e.target.setCustomValidity(t("modaluser_textfield_email_required"))}
                                    onBlur={e => e.target.setCustomValidity("")}
                                />
                            </Grid>
                            
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Autocomplete
                                    multiple
                                    options={rolesOptions}
                                    getOptionLabel={(option) => option.label}
                                    value={roles}
                                    onChange={handleSelectRol}
                                    isOptionEqualToValue={isOptionEqualToValue}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined" label={t("modaluser_textfield_rol")} />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">{t("modaluser_textfield_status")}</FormLabel>
                                    <FormGroup>
                                        <FormControlLabel
                                        control={<Switch checked={estado} onChange={e => {setEstado(e.target.checked);} } />}
                                        label={ estado ? t("chipestado_true") : t("chipestado_false") }
                                        />
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                        
                        <Grid container spacing={2} sx={{ marginTop: '5px' }} justifyContent="flex-end">
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <Button 
                                        type="submit"
                                        variant="contained"
                                        fullWidth
                                        sx={{ backgroundColor: "#52c41a", '&:hover': { bgcolor: '#237804' } }}
                                    >
                                        {btnName}
                                    </Button>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <Button 
                                        variant="contained"
                                        fullWidth
                                        sx={{ backgroundColor: "#f5222d", '&:hover': { bgcolor: '#a8071a' } }}
                                        onClick={ handleClose }
                                    >
                                        {t("modaluser_cancel_btn")}
                                    </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </form>
        </Modal>
    );
}

export default ModalAddUsuario;