export const gridTable = {
    margin: "5px",
    maxHeight: 'calc(100vh - 310px)',
    overflowY: "auto",
    overflowX: "auto",
    "&::-webkit-scrollbar": {
        width: "6px",
        height: "6px",
    },
    "&::-webkit-scrollbar-track": {
        background: "#f1f1f1",
    },
    "&::-webkit-scrollbar-thumb": {
        background: "#888",
        borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
        background: "#555",
    },
}

export const gridFilter = {
    marginTop: "10px",
    maxHeight: '148px',
    overflowY: "auto",
    overflowX: "auto",
    "&::-webkit-scrollbar": {
        width: "6px",
        height: "6px",
    },
    "&::-webkit-scrollbar-track": {
        background: "#f1f1f1",
    },
    "&::-webkit-scrollbar-thumb": {
        background: "#888",
        borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
        background: "#555",
    },
}