// react
import React from "react";

// material ui
import { Box, Grid, IconButton, Modal, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

// my proyect
import InputEdit from "../input/InputEdit";
import Section from "./Section";

// other
import { useTranslation } from "react-i18next";

// css
import  { styleModal } from '../../style/StyleEditModel';

const modalEdit = {
    type: true,
    reset: true,
    filter: false,
    vfilter: false,
    select: false,
    totalLista: 0,
    lista: [],
    listaSelect: [],
    data: [],
    dataFilter: []
};

const ModalEdit = ({ open, mainEvent, closeEvent, data }) => {
    const { t } = useTranslation();
    const [dataCustomer, setDataCustomer] = React.useState(modalEdit);
    const [dataDiffusion, setDataDiffusion] = React.useState(modalEdit);
    const [dataModal, setDataModal] = React.useState({});

    React.useEffect(() => {
        if (Object.keys(dataModal).length === 0) {
            setDataCustomer({ ...modalEdit, data });
            setDataDiffusion({ ...modalEdit, data, type: false, select: true });
        }
        setDataModal({
            dataCustomer,
            dataDiffusion
        });
    }, [dataCustomer, dataDiffusion]);

    const handleKeyDown = (event) => {
        if (event.key === "Escape") {
            closeEvent();
        }
    };

    return (
        <Modal open={open}>
            <Box style={styleModal} onKeyDown={handleKeyDown} tabIndex="-1">
                <Grid container rowSpacing={1} columnSpacing={0.75}>
                    <Grid item xs={12}>
                        <Box display="flex"
                            style={{
                                padding: "3px",
                                background: "#008AE8",
                                color: "white",
                                position: "sticky",
                                top: 0,
                                zIndex: 0
                            }}
                        >
                            <Box flexGrow={1} />
                            <Typography variant="h5" style={{ fontWeight: "bold" }}>
                                {t("edit_customer_list_title",{name:data.name})}
                            </Typography>
                            <Box flexGrow={1} />
                            <IconButton onClick={closeEvent} style={{ color: "white" }}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Grid>
                    <InputEdit t={t} dataModal={dataModal} data={data} event={mainEvent} />
                    <Section
                        t={t}
                        data={dataCustomer}
                        setData={setDataCustomer}
                    />
                    <Section
                        t={t}
                        data={dataDiffusion}
                        setData={setDataDiffusion}
                    />
                </Grid>
            </Box>
        </Modal>
    );
};

export default ModalEdit;