// url base
const urlBase = window._env_.SERVER_URL;

// get - brand list full
export async function getBrandFullList( token, page = 1, size = 10 ) {
    const response = await fetch(`${urlBase}/brand/list/full?page=${page}&size=${size}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    })
    const responseJson = await response.json()
    return responseJson
}

// get - brand list
export async function getBrandList( token, page = 1, size = 10 ) {
    const response = await fetch(`${urlBase}/brand/list?page=${page}&size=${size}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    })
    const responseJson = await response.json()
    return responseJson
}

// post - brand add
export async function getNewBrand( token, newBrand ) {
    const response = await fetch(`${urlBase}/brand`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(newBrand)
    })
    const responseJson = await response.json()
    return responseJson
}

// post - brand edit
export async function getUpdatedBrand( token, updateBrand ) {
    const response = await fetch(`${urlBase}/brand/${updateBrand.id}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(updateBrand)
    })
    const responseJson = await response.json()
    return responseJson
}