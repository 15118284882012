//
import React from "react";
// material ui
import { Grid, Button, InputAdornment, TextField, FormControl, InputLabel, Select, MenuItem } from "@mui/material";

// icons
import SearchIcon from '@mui/icons-material/Search';

const SelectType = ({t, setOpen, setTypeForm, listCopie, setList}) => {
    // control
    const [typeCampaignSelect, setTypeCampaignSelect] = React.useState(true);

    // select
    const [ tipoCampana, setTipoCampana] = React.useState(0);

    // serch
    const [serch, setSerch] = React.useState("");

    const handleChangeTipoCampana = (event) => {
        setTypeCampaignSelect(false);
        setTipoCampana(event.target.value);
    };

    // solo busca en la lsta cargada en la tabla modificar a busqueda en db
    const serchList = (serch) => {
        setSerch(serch);
        if(serch.length === 0) {
            setList(listCopie);
        } else {
            const updatedFilas = listCopie.content.filter(fila => fila.name.includes(serch));
            setList(filas => {
                return {
                    ...filas,
                    content: updatedFilas,
                    totalElements: updatedFilas.length
                }
            });
        }
    }

    // boton event
    const handleOpen = () => {
        setTypeForm(tipoCampana);
        setTipoCampana(0);
        setOpen(true);
        setTypeCampaignSelect(true);
    }

    return(
        <>
            <Grid item xs={3} sm={3} md={3} lg={3} >
                            
                <FormControl fullWidth >
                    <InputLabel size="small" htmlFor="simple-select-label">{t("partcupon_select_tipo_campana")}</InputLabel>
                    <Select
                        size="small"
                        id="simple-select"
                        label={t("partcupon_select_tipo_campana")}
                        value={tipoCampana ? tipoCampana : ""}
                        onChange={handleChangeTipoCampana}
                        required
                    >
                            <MenuItem key={0} value={0} disabled>
                                <em>{t("partcupon_tipo_campana_menuitem")}</em>
                            </MenuItem>
                            <MenuItem key={1} value={1}>{t("selecttype_1")}</MenuItem>
                            <MenuItem key={2} value={2}>{t("selecttype_2")}</MenuItem>
                            {/*<MenuItem key={3} value={3}>{t("selecttype_3")}</MenuItem>*/}
                            <MenuItem key={4} value={4}>{t("selecttype_4")}</MenuItem>
                            <MenuItem key={5} value={5}>{t("selecttype_5")}</MenuItem>
                    </Select>
                </FormControl>

            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} align={"right"} >
                <Button
                    variant="contained"
                    onClick={handleOpen}
                    sx={{ backgroundColor: "#008AE8", '&:hover': { bgcolor: '#316FB5' }, mr:'5px', textTransform: "none" }}
                    disabled={typeCampaignSelect}
                >
                    {t("difusion_add_btn")}
                </Button>
                <TextField
                    value={serch}
                    size="small"
                    onChange={ e => {serchList(e.target.value);} }
                    label={t("difusion_main_serch")}
                    InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                        ),
                    }}
                />
            </Grid>
        </>
    );
}

export default SelectType;