// React
import React from "react";

// material ui
import { Grid } from "@mui/material";

// my proyect
import Filter from "../../../../../components/filter/Filter";
import TCustomer from "../../table/TCustomer";

// service
import { customerFieldsData } from "../../DataCustomerList";

// css
import  { gridSection } from '../../style/StyleEditModel';

const Section = ({t, data, setData}) => {
    const [dataFilter, setDataFilter] = React.useState(null);

    const cancelEvent = () => {
        setData(prevData => ({
            ...prevData,
            reset: true,
            select:false
        }));
    }

    React.useEffect(() => {
        customerFieldsData(setDataFilter);
    }, []);

    React.useEffect(() => {
        if (dataFilter) {
            setData((prevData) => ({
                ...prevData,
                dataFilter: dataFilter?.object,
            }));
        }
    }, [dataFilter, setData]);

    return (
        <>
            <Grid item xs={6} sx={gridSection}>
                <Filter
                    t={t}
                    data={data}
                    setData={setData}
                    cancelEvent={cancelEvent}
                />
            </Grid>
            <Grid item xs={6} sx={gridSection}>
                <TCustomer
                    t={t}
                    data={data}
                    setData={setData}
                />
            </Grid>
        </>
    );
}

export default Section;