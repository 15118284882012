export const diffusionData = {
    name: "",
    typeDiffusion: "coupon",
    typeCampaign: "",
    listCustomerId: "",
    cuponProgramId: "",
    svsZoneId: "",
    dateSend: "",
    typeCoupon: "",
    valueCoupon: "",
    dateOf: "",
    dateAt: "",
    passHeader: "",
    general: "",
    additional: "",
    dayReminder1: "",
    dateSendReminder1: "",
    titleReminder1: "",
    contentReminder1: "",
    dayReminder2: "",
    dateSendReminder2: "",
    titleReminder2: "",
    contentReminder2: "",
    emailSent: 0,
    addedPass: 0,
    updatedPass: 0,
    couponsGenerated: 0,
    couponsRedeemed: 0,
    couponsAmountCreated: 0,
    couponsAmountRedeemed: 0,
    isEnable: true,
    brandId: localStorage.getItem('brand'),
    designId: ""
};