// my service
import { getAllLocation } from '../../../services/campanas/locacion/LocacionService';

// getAll
export const listLocation = async ( brandId, setFilas, setOriginalFilas ) => {
    try {
        const response = await getAllLocation(localStorage.getItem('token'), brandId);
        setFilas(response.object.ApiDocument.Response.Locations);
        setOriginalFilas(response.object.ApiDocument.Response.Locations);
    } catch (error) {
        console.error(error);
    }
}