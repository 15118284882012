import React, { useCallback, useEffect, useState } from "react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import SpinnerMessageBasic from "../spinner/SpinnerMessageBasic";

const SpinnerRow = ({ isActive, columnsLength, message }) => (
    <TableRow>
        <TableCell
            style={{
            verticalAlign: "middle",
            textAlign: "center",
            height: "200px",
            }}
            colSpan={columnsLength}
        >
            <SpinnerMessageBasic isActive={isActive} typeSpinner={true} message={message} />
        </TableCell>
    </TableRow>
);

const TableRows = ({ rows, columns, alignRow }) =>
    rows.map((row) => (
    <TableRow hover role="checkbox" tabIndex={-1} key={row.key}>
        {columns.map((column) => {
        const value = row[column.id];
        return (
            <TableCell key={column.id} align={alignRow}>
            {column.format && typeof value === "number" ? column.format(value) : value}
            </TableCell>
        );
        })}
    </TableRow>
));

const TableHeader = ({ columns }) => (
    <TableHead>
        <TableRow>
        {columns.map((column) => (
            <TableCell
            key={column.id}
            align={column.align}
            style={{ minWidth: column.minWidth }}
            sx={{ textAlign: "center", backgroundColor: "#008AE8", color: "white" }}
            >
            {column.label}
            </TableCell>
        ))}
        </TableRow>
    </TableHead>
);

const TEvent = ({
    traductor,
    columns,
    createRow,
    eventTable,
    state,
    setState
}) => {    
    const [active, isActive] = useState(true);
    const { reset, spinner, numberData } = state;
    
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(numberData.rango);
    const [highestPage, setHighestPage] = useState(0);

    const handleChangePage = useCallback((event, newPage) => {
        if (newPage > highestPage) {
            setState(preState => ({
                ...preState,
                spinner: true
            }));
            isActive(true);
            eventTable(newPage + 1, rowsPerPage);
            setHighestPage(newPage);
        }
        setPage(newPage);
    }, [highestPage, rowsPerPage, eventTable]);

    const handleChangeRowsPerPage = useCallback((event) => {
        setState(preState => ({
            ...preState,
            spinner: true
        }));
        isActive(true);
        eventTable(1, event.target.value);
        setRowsPerPage(+event.target.value);
        setPage(0);
        setHighestPage(0);
    }, [eventTable]);

    useEffect(() => {
        if (numberData.elements === 0) {
            setState(preState => ({
                ...preState,
                spinner: true
            }));
            isActive(false);
        } else {
            isActive(true);
        }

        if (reset) {
            setPage(0);
            setHighestPage(0);
            setState(preState => ({
                ...preState,
                reset: false
            }));
        }
        setRowsPerPage(numberData.rango);
    }, [numberData.elements, reset]);

    const rowsToShow = createRow.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer /* Rest of the styles as above */>
            <Table stickyHeader size="small">
                <TableHeader columns={columns} />
                <TableBody>
                {spinner ? (
                    <SpinnerRow isActive={active} columnsLength={columns.length} message={traductor("marca_table_typography")} />
                ) : (
                    <TableRows rows={rowsToShow} columns={columns} alignRow={"center"} />
                )}
                </TableBody>
            </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                component="div"
                count={numberData.elements}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={traductor("tablabasica_rowsperpage")}
            />
        </Paper>
    );
};

export default TEvent;