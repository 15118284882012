// material ui
import { Grid } from "@mui/material";

// my proyect
import DesignColor from "./DesignColor";
import DesignShow from "./DesignShow";

const DesignPasse = ({ t,colorLabel,setColorLabel,colorForeground,setColorForeground,colorBackground,setColorBackground,logo,image }) => {

    return(
        <Grid item xs={12} sm={6} md={6} lg={6}>
            <DesignColor 
                t={t}
                colorLabel={colorLabel}
                setColorLabel={setColorLabel}
                colorForeground={colorForeground}
                setColorForeground={setColorForeground}
                colorBackground={colorBackground}
                setColorBackground={setColorBackground}
            />
            <DesignShow
                t={t}
                colorLabel={colorLabel}
                colorForeground={colorForeground}
                colorBackground={colorBackground}
                logo={logo}
                image={image}
            />
        </Grid>
    );

}

export default DesignPasse;