// React
import React from 'react';

// material-ui
import { Button, Card, CardContent, CardHeader, Grid, Modal, TextField } from "@mui/material";

// otros
import { useTranslation } from "react-i18next";

// styles
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 230,
};

const ModalAddEvent = ({ open, titulo, btnName, mainEvent, closeEvent, data }) => {

    const { t } = useTranslation();

    const idBrand = data.brand.id;
    const idHistory = data.packageHistory.id;
    const idPermanent = data.permanentEvents.id;
    const idPackag = data.packag.id;

    const [addEve, setAddEve] = React.useState(0);
    
    const handleClose = (e) => {
        closeEvent(open);
        setAddEve(0);
    }
    
    const handleAdd = (e) => {
        // const add = parseInt(addEve) + parseInt(data.add);

        mainEvent(
            {
                marca:data.brand.name,
                chq:data.brand.chqServer,
                cshq:data.brand.cshqServer,
                api:data.brand.chqApi,
                crm:data.brand.crmApi,
                vsn:data.brand.chqVersion,
                pak:idPackag,
                estado:data.brand.isEnable,
                idBrand,
                idHistory,
                idPermanent,
                idPackag,
                addPermanentEvents:parseInt(addEve)
            });

        setAddEve(0);
    }

    return(
        <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <form onSubmit={handleAdd}>
                <Card sx={style}>
                    <CardHeader
                        title = {titulo}
                        sx={{ backgroundColor: "#008AE8", color: "white", textAlign: "center" }}
                    />
                    <CardContent>
                        <Grid container spacing={2} sx={{ marginTop: '5px' }}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField
                                    type="number"
                                    size="small"
                                    value={addEve}
                                    onChange={e => {
                                      const value = parseInt(e.target.value); // Convertir el valor a entero
                                      if (!isNaN(value)) {
                                        setAddEve(value.toString()); // Establecer el valor convertido como una cadena de texto
                                      }
                                    }}
                                    label={t("modalmarca_textfield_new_events_label")}
                                    fullWidth
                                    required
                                    onInvalid={e => e.target.setCustomValidity(t("modalmarca_textfield_name_required"))}
                                    onBlur={e => e.target.setCustomValidity("")}
                                />
                            </Grid>
                        </Grid>
                        
                        <Grid container spacing={5} sx={{ marginTop: '5px' }} >
                            <Grid item xs={12} sm={5} md={5} lg={5} >
                                    <Button 
                                        type="submit"
                                        variant="contained"
                                        sx={{ backgroundColor: "#52c41a", '&:hover': { bgcolor: '#237804' } }}
                                    >
                                        {btnName}
                                    </Button>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} >
                                    <Button 
                                        variant="contained"
                                        sx={{ backgroundColor: "#f5222d", '&:hover': { bgcolor: '#a8071a' } }}
                                        onClick={ handleClose }
                                    >
                                        {t("modalmarca_cancel_btn")}
                                    </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </form>

        </Modal>
    );
}

export default ModalAddEvent;