import React from 'react';
import { FormControlLabel, Switch } from '@mui/material';

const CheckInList = React.memo(({ id, lista, evento }) => {
  const handleChange = (event) => {
    const isChecked = event.target.checked;
    evento(isChecked, id);
  };

  return (
    <FormControlLabel
      control={<Switch checked={lista.includes(id)} onChange={handleChange} />}
    />
  );
});

export default CheckInList;
