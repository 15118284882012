// React
import React, { useState, useEffect } from "react";

// material ui
import { Grid } from "@mui/material";

// my project
import SpinnerMessageBasic from "../../../../components/spinner/SpinnerMessageBasic";
import TFilter from "../../../../components/table/TFilter";

// service
import { customerListNoDiffusionData, customerListNoDiffusionFilterData } from "../DataCustomerList";

// utils
import { getColumnsCheck, createRowCheck } from "./TableUtils";

const TListCustomer = ({ t, data, setData }) => {
    const [state, setState] = useState({
        spinner: true,
        empty: true,
        filtro: false,
        lCustomer: [],
        numberData: {
            elements: 0,
            rango: 10
        }
    });

    const { spinner, empty, filtro, lCustomer, numberData } = state;

    const fetchData = (page, size, callback, filter = null) => {
        if (filter) {
            customerListNoDiffusionFilterData(page, size, data.data.id, filter, callback);
        } else {
            customerListNoDiffusionData(page, size, data.data.id, callback);
        }
    };

    const handleData = (newData) => {
        setState(prevState => ({
            ...prevState,
            numberData: {
                elements: newData.totalElements,
                rango: newData.size
            },
            lCustomer: newData,
            empty: !(newData?.content),
            spinner: newData?.totalElements <= 0
        }));
        setData(prevData => ({
            ...prevData,
            totalLista: newData.totalElements,
        }));
        allSelect(newData);
    };

    const handleAddCustomerData = (data) => {
        setState(prevState => ({
            ...prevState,
            lCustomer: {
                ...prevState.lCustomer,
                content: [...prevState.lCustomer.content, ...data.content],
            },
            empty: !(data?.content),
            spinner: data?.totalElements <= 0
        }));
        setData((prevData) => ({
            ...prevData,
            totalLista: data.totalElements,
        }));
        allSelect(data);
    };

    const editList = (check,id) => {
        if (check) {
            setData((prevData) => ({
                ...prevData,
                lista: [...prevData.lista, id],
            }));
        } else {
            setData((prevData) => ({
                ...prevData,
                lista: prevData.lista.filter((prevId) => prevId !== id)
            }));
        }
        allSelect(data);
    }

    const allSelect = (newFilas) => {
        if (data.select) {
            const ids = newFilas.content.map(fila => fila.id);
            setData(prevData => ({
            ...prevData,
            lista: [...prevData.lista, ...ids]
            }));
        }
    };

    useEffect(() => {
        if (data.reset && data.data.id) {
            fetchData(1, 10, handleData);
        }
        if (data.filter && data.data.id) {
            fetchData(1, 10, handleData, data.dataFilter);
        }
    }, [data.reset, data.filter, data.data.id, data]);

    if (empty) {
        return <SpinnerMessageBasic isActive={spinner} typeSpinner={true} message={t("marca_table_typography")} />;
    }

    // event check
    const checkboxEvent = (e) => {
    const stateCheck = e.target.checked;
        if (stateCheck) {
            const ids = lCustomer.content.map(fila => fila.id);
            setData(prevData => ({
                ...prevData,
                select: stateCheck,
                lista: ids
            }));
        } else {
            setData(prevData => ({
                ...prevData,
                select: stateCheck,
                lista: []
            }));
        }
    };

    const changeTable = (page, size, typeChange) => {
        if (typeChange) {
            fetchData(page, size, handleAddCustomerData, filtro ? data.dataFilter : undefined);
        } else {
            fetchData(page, size, handleData, filtro ? data.dataFilter : undefined);
        }
    };

    const setReset = (status) => {
    setData(prevData => ({
        ...prevData,
        reset: status,
        vfilter: false
    }));
    if (filtro) {
        setState(prevState => ({
        ...prevState,
        spinner: true,
        filtro: false
        }));
    }
    };

    const setTableFilter = (status) => {
    setData(prevState => ({
        ...prevState,
        filter: status,
        vfilter: true
    }));
    setState(prevState => ({
        ...prevState,
        filtro: true
    }));
    };

    const isSpinner = (status) => {
    setState(prevState => ({
        ...prevState,
        spinner: status
    }));
    };

    return (
    <Grid item sx={{ margin: "5px" }}>
        <TFilter
            traductor={t}
            columns={getColumnsCheck(t, checkboxEvent, data.select)}
            createRow={createRowCheck(lCustomer, data.lista, editList)}
            eventTable={changeTable}
            numberData={numberData}
            reset={data.reset}
            isReset={setReset}
            filter={data.filter}
            isFilter={setTableFilter}
            spinner={spinner}
            isSpinner={isSpinner}
        />
    </Grid>
    );
};

export default TListCustomer;
