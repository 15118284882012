
const urlBase = window._env_.SERVER_URL;

// post - diffusion - add
export async function getNewDiffusion( difusion ) {
    const response = await fetch(`${urlBase}/diffusion`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: difusion
    })
    const responseJson = await response.json()
    return responseJson
}

// post - diffusion - add
export async function getNewSimpleDiffusion( difusion ) {
    const response = await fetch(`${urlBase}/diffusion/add`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(difusion)
    })
    const responseJson = await response.json()
    return responseJson
}

// post - diffusion - edit
export async function getUpdatedDiffusion( updateDiffusion ) {
    
    const response = await fetch(`${urlBase}/diffusion/${updateDiffusion.id}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(updateDiffusion)
    })
    const responseJson = await response.json()
    return responseJson
}

// get - diffusion - list
export async function getAllDiffusion( page, size ) {
    const response = await fetch(`${urlBase}/diffusion/${localStorage.getItem('brand')}/list?page=${page}&size=${size}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    })
    const responseJson = await response.json()
    return responseJson
}

// get - coupons - data
export async function getDataCoupons() {
    const response = await fetch(`${urlBase}/coupon/listprogram/${localStorage.getItem('brand')}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    })
    const responseJson = await response.json()
    return responseJson
}