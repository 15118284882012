// react
import React, { useEffect, useState } from "react";

// material-ui
import { Grid, Typography, Card } from '@mui/material';

// my proyect
import SpinnerMessageBasic from "../../components/spinner/SpinnerMessageBasic";
import EventGraph from './modules/event/EventGraph';
import { CouponCard } from './modules/coupon/CouponCard';
import { EventsCard } from './modules/events/EventsCard';
import CustomerGraph from './modules/customer/CustomerGraph';

// otros
import { useTranslation } from "react-i18next";

// css
import { styleTittle } from "./style/StyleDash";

// data
import { reportGraph1Data, reportGraphData } from "./DataDashboard";
import DiffusionCard from "./modules/diffusion/DiffusionCard";
import axios from "axios";
import moment from "moment";
const urlBase = window._env_.SERVER_URL;


const Dashboard = () => {
    const { t } = useTranslation();

    // const [report, setReport] = useState(null);
    const [graph1, setGraph1] = useState(null);
    const [error, setError] = useState(null);
    const [empty, setEmpty] = useState(true);

    const [events, setEvents] = useState(null);
    const [coupons, setCoupons] = useState(null);


    const fetchData = async () => {
        const dateInit = new moment().startOf('month').format("YYYY-MM-DD");
        const dateEnd = new moment().endOf("month").format("YYYY-MM-DD");
        try {
            const responseEvents = await axios.get(`${urlBase}/dashboard/graph/${localStorage.getItem('brand')}/${dateInit}/${dateEnd}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });
            setEvents(responseEvents.data.object.eventos);
            setCoupons(responseEvents.data.object.cupones)
            setGraph1(responseEvents.data.object.dataGraph);
            setEmpty(false);
        } catch (error) {
            setError(error);
        }
    };

    useEffect(() => {
        fetchData().then(r => { });
    }, []);

    if (empty) {
        return <SpinnerMessageBasic />;
    }
    return (
        <>
            <Card sx={styleTittle}>
                <Typography
                    variant="h4"
                    sx={{ fontWeight: 'bold', marginTop: '20px', marginBottom: '5px' }}
                >
                    {t("dashboard_main_title")}
                </Typography>
            </Card>
            <Grid container >
                <EventsCard t={t} eventos={events} />
                <CouponCard t={t} cupones={coupons} />
                <EventGraph t={t} graph={graph1} />
                {/*<CustomerGraph t={t} graph={graph1} />*/}
                <DiffusionCard t={t} />
            </Grid>

        </>
    );
}

export default Dashboard;