// my service
import { getBrandFullList, getBrandList, getNewBrand, getUpdatedBrand } from '../../services/marcas/MarcasService';
import { getPackageList } from '../../services/packag/PackagService';

// getAllFull
export const brandFullList = async ( page, size, setFilas, setOriginalFilas ) => {
    try {
        const response = await getBrandFullList(localStorage.getItem('token'), page, size);
        setFilas(response.content);
        setOriginalFilas(response.content);
    } catch (error) {
        console.error(error);
    }
}

// getAll
export const brandList = async ( page, size, setFilas, setOriginalFilas ) => {
    try {
        const response = await getBrandList(localStorage.getItem('token'), page, size);
        setFilas(response.content);
        setOriginalFilas(response.content);
    } catch (error) {
        console.error(error);
    }
}

// add
export const addBrand = async ( brand, setFilas, filas, MySwal, t ) => {
    try {
        const response = await getNewBrand(localStorage.getItem('token'), brand);
        setFilas([...filas, response.object]);
        MySwal.fire({
            title: '<span style="font-weight: normal; font-family: Lato, sans-serif;">'+t("datamarca_addbran_success_title")+'</span>',
            html: '<span style="font-style: normal; font-family: Lato, sans-serif;">'+t("datamarca_addbran_success_message")+'</span>',
            icon: 'success',
            confirmButtonColor: '#008ae8'
        });
    } catch(error) {
        console.error(error);
        MySwal.fire({
            title: '<span style="font-weight: normal; font-family: Lato, sans-serif;">'+t("datamarca_addbran_error_title")+'</span>',
            html: '<span style="font-style: normal; font-family: Lato, sans-serif;">'+t("datamarca_addbran_error_message")+'</span>',
            icon: 'error',
            confirmButtonColor: '#008ae8'
        });
    }
}

// update
export const updateBrand = async ( updateBrand, setFilas, filas, MySwal, t ) => {
    try {
        const response = await getUpdatedBrand(localStorage.getItem('token'), updateBrand);
        MySwal.fire({
            title: '<span style="font-weight: normal; font-family: Lato, sans-serif;">'+t("datamarca_updatebran_success_title")+'</span>',
            html: '<span style="font-style: normal; font-family: Lato, sans-serif;">'+t("datamarca_updatebran_success_message")+'</span>',
            icon: 'success',
            confirmButtonColor: '#008ae8'
        });
        const updatedFilas = filas.map((fila) =>
          fila.brandDtoE.id === response.object.brandDtoE.id ? { ...fila, ...response.object } : fila
        );
        setFilas(updatedFilas);
    } catch(error) {
        console.error(error);
        MySwal.fire({
            title: '<span style="font-weight: normal; font-family: Lato, sans-serif;">'+t("datamarca_updatebran_error_title")+'</span>',
            html: '<span style="font-style: normal; font-family: Lato, sans-serif;">'+t("datamarca_updatebran_error_message")+'</span>',
            icon: 'error',
            confirmButtonColor: '#008ae8'
        });
    }
}

// getAll
export const packageList = async ( setMyPackageList ) => {
    try {
        const response = await getPackageList(localStorage.getItem('token'));
        let packagList = [];

        if(response.totalElements > 0) {
            for(let packag of response.content) {
                packagList.push(packag);
            }
        }

        setMyPackageList(packagList);
    } catch (error) {
        console.error(error);
    }
}