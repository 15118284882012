// react
import React from 'react';

// materila ui
import { Grid } from "@mui/material";

// my proyect
import SpinnerMessageBasic from '../../../../../../../components/spinner/SpinnerMessageBasic';
import General from './General';
import CouponInfo from '../shared/CouponInfo';
import Reminder from "../shared/Reminder";
import ButtonSubmit from '../shared/ButtonSubmit';

// service
import { getAllCoupons } from '../../../../DataDifusion';

// data
import {diffusionData} from '../shared/FormData';

const FormRecord = ({t, setOpen}) => {
    const [spinner, setSpinner] = React.useState(true);
    const [difusion, setDifusion] = React.useState(diffusionData);
    const [list, setList] = React.useState();

    React.useEffect(() => {
        getAllCoupons(setList, setSpinner);
    }, []);

    if (!list?.object) {
        return <SpinnerMessageBasic isActive={spinner} message={t('marca_table_typography')}/>;
    }

    return(
        <form onSubmit={e => e.preventDefault()}>
            <Grid container rowSpacing={2.5} columnSpacing={0.75}>
                <General
                    t={t}
                    list={list}
                    difusion={difusion}
                    setDifusion={setDifusion}
                />
                <Reminder
                    t={t}
                    typeReminder={1}
                    difusion={difusion}
                    setDifusion={setDifusion}
                />
                <Reminder
                    t={t}
                    typeReminder={2}
                    difusion={difusion}
                    setDifusion={setDifusion}
                />
                <ButtonSubmit
                    t={t}
                    setOpen={setOpen}
                />
            </Grid>
        </form>
    )

}

export default FormRecord;