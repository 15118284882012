import React from "react";
import { Button, Card, CardContent, Grid } from "@mui/material";
import ModalCreate from "./modal/create/ModalCreate";

const SectionTable = ({t}) => {
    const [open,setOpen] = React.useState(false);

    const addEvent = (e,data) => {
        e.preventDefault();
        console.log(data);
        setOpen(false);
    };

    const modalCreate = { t, open, setOpen, addEvent };

    return(
        <Card>
            <CardContent>
                <Grid container rowSpacing={1} columnSpacing={0.75}>
                    <Grid item xs={12} sm={12} md={12} lg={12} align={"right"}>
                        <Button
                            variant="contained"
                            onClick={ e=> {setOpen(true)}}
                            sx={{ backgroundColor: "#008AE8", '&:hover': { bgcolor: '#316FB5' }, mr:'5px' }}
                        >
                            {t("email_add_btn")}
                        </Button>
                        {open && <ModalCreate {...modalCreate} />}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default SectionTable;